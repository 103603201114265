<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-data-table           :options.sync="options"
          :server-items-length="totalRecords"
          :loading="loading"   :headers="headers" :items="companies" class="elevation-1">
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>Firmy</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
            <v-btn class="primary" @click="
                (dialogCreate = true)
            ">Dodaj Firmę</v-btn>
                            </v-toolbar>

                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-btn class="primary" @click="getCompanyRents(item.id)">
                            <v-icon small class="mr-2"> mdi-eye </v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-dialog v-model="dialog" persistent fullscreen hide-overlay>
            <v-card>
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Polecenia</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-data-table   :options.sync="optionsHistory"
          :server-items-length="totalRecordsHistory"
          :loading="loadingHistory"  :headers="headersProducts" :items="productsInWarehouse" class="elevation-1"
                    mobile-breakpoint="0">
                </v-data-table>
            </v-card>
        </v-dialog>
<v-dialog v-model="dialogCreate" persistent max-width="600px">
    <v-card>
        <v-toolbar dark color="primary">
            <v-btn icon dark @click="dialogCreate = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Dodawanie Firmy</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-container>
            <v-form>
                <!-- <v-sheet  color="grey lighten-3">    
                        <v-sheet
            :elevation="2"
            rounded>               -->
                <v-row class="pa-5">
                    <v-col cols="12">
                        <v-text-field v-model="companyName" label="Nazwa Firmy" :rules="[(v) => !!v || 'Uzupełnij Pole']">
                        </v-text-field>
                    </v-col>
                                <v-col cols="12 text-right">
                                    <v-btn class="primary" @click="createCompany()">Dodaj</v-btn>
                                </v-col>
                    </v-row>
                    </v-form>
                    </v-container>
                    </v-card>
                    </v-dialog>
    </v-container>
</template>
<script>
const axios = require("axios");
import Swal from "sweetalert2";
import * as dayjs from 'dayjs'
export default {
    name: "Company",    
    data: () => ({
        optionsHistory: {},   
        totalRecordsHistory: 0,
        loadingHistory: false,  
        options: {},
        totalRecords: 0,
        loading: false,            
        currentCompanyId: null,
        companyName:'',
        dialogCreate:false,
        dialog: false,
        headers: [
            {
                text: "Nazwa Firmy",
                align: "start",
                sortable: false,
                value: "name",
            },
            { text: "", value: "actions", sortable: false },            
        ],
        companies: [],
        headersProducts: [{
            text: "Numer Umowy",
            align: "start",
            sortable: false,
            value: "dealNo",
        }, {
                text: "Produkt",
                align: "start",
                sortable: false,
                value: "productName",
            }, {
                text: "Status",
                align: "start",
                sortable: false,
                value: "status",
            }, {
                text: "Data Dodania",
                align: "start",
                sortable: false,
                value: "created_at",
            }],
        productsInWarehouse: [],
    }),
    watch: {
        options: {
            async handler() {
                await this.initialize()
            },
            deep: true,
        },
        optionsHistory: {
            async handler() {
                if (this.currentCompanyId){
                    await this.getCompanyRents(this.currentCompanyId)
                }
                
            },
            deep: true,
        },        
    },
    async created() {        
    },

    methods: {
        async toggleLoading(type) {
            const toggle = type;
            localStorage.setItem("loading", toggle);
            window.dispatchEvent(
                new CustomEvent("toggleLoading", {
                    detail: {
                        loading: localStorage.getItem("loading"),
                        overlay: localStorage.getItem("loading"),
                    },
                })
            );
        },
        async getCompanyRents(id) {
            this.currentCompanyId = id
            console.log(id)
            this.dialog = true
            this.historyLoading = true 
            let { sortBy, sortDesc, page, itemsPerPage } = this.optionsHistory           
            try {
                let history = await axios({
                    method: "get",
                    url: process.env.VUE_APP_ROOT_API + "/rents/get-for-company/" + id + `?sort=${sortBy.length ? sortBy : 'dealNo'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}`,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                history.data.data.items.forEach((item) => {
                    if (!item.isDelivered) {
                        item.status = 'Niedostarczone'
                    }
                    else if (item.isDelivered) {
                        item.status = 'Aktywne'
                    }
                    if (item.isServiceCreated) {
                        item.status = 'Utworzono Serwis'
                    }
                    if (item.isTransportCreated) {
                        item.status = 'Utworzono Transport'
                    }
                    if (item.isReturnCreated && !item.isReturned) {
                        item.status = 'Utworzony Odbiór'
                    }
                    else if (item.isReturned) {
                        item.status = 'Odebrany'
                    }                                                      
                    const offset = new Date().getTimezoneOffset();
                    item.productName = item.products.name
                    item.created_at = dayjs(item.created_at).add(Math.abs(offset / 60), 'hours').format('YYYY-MM-DD HH:mm')
                })                
                this.productsInWarehouse = history.data.data.items
                this.itemsPerPage = history.data.data.meta.itemsPerPage
                this.page = history.data.data.meta.currentPage
                this.totalRecordsHistory = history.data.data.meta.totalItems                  
            } catch (e) {
                console.log(e)
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            this.historyLoading = false
        },
        async initialize() {
            this.loading = true
            let { sortBy, sortDesc, page, itemsPerPage } = this.options
            try {
                let warehouse = await axios({
                    method: "get",
                    url: process.env.VUE_APP_ROOT_API + `/warehouse/get-company?sort=${sortBy.length ? sortBy : 'name'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}`,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });                
                this.companies = warehouse.data.data.items;
                this.itemsPerPage = warehouse.data.data.meta.itemsPerPage
                this.page = warehouse.data.data.meta.currentPage
                this.totalRecords = warehouse.data.data.meta.totalItems                          
            } catch (e) {
                console.log(e)
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            this.loading = false
        },
        async createCompany() {
            await this.toggleLoading(true);
            try {
                await axios({
                    method: "post",
                    url: process.env.VUE_APP_ROOT_API + "/warehouse/create-company",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    data: {
                        name: this.companyName,
                    },
                });
                this.companyName = ''            
                this.dialogCreate = false;
                await this.initialize();
            } catch (e) {
                console.log(e)
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            await this.toggleLoading(false);
        },
    },
};
</script>