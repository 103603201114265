<template>
    <v-dialog transition="dialog-bottom-transition" fullscreen persistent hide-overlay v-model="fullDetailsDialog">
    <v-toolbar dark color="primary">
        <v-btn icon dark @click="fullDetailsDialog = false">
            <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Szczegóły</v-toolbar-title>
        <v-spacer></v-spacer>
    </v-toolbar>
<v-card>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-sheet color="brown darken-4" rounded="rounded" class="mx-auto">
                    Numer Umowy: {{ dealNoInfo }}<br />
                    Adres Sprzętu: <a :href="'http://maps.google.com/?q='+deliveryProductAddrInfo" target="_blank">{{ deliveryProductAddrInfo }}</a><br />
                    Telefon Do Kontaktu: <a :href="'tel: '+clientPhoneInfo">{{ clientPhoneInfo }}</a><br />
                    Telefon Dzwoniącego: <a :href="'tel: ' + callerPhoneInfo">{{ callerPhoneInfo }}</a><br />                    
                    Uwagi: {{ noteInfo }} <br />
                    Uwagi Kierowcy: {{ noteInfoDriver }}<br />
                    Produkty: {{productsStr}}
                </v-sheet>
            </v-col>
        </v-row>
    </v-container>
    <v-stepper v-model="e1">
        <v-stepper-header>
            <v-stepper-step editable step="1">
                Dane Klienta
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step editable step="2">
                Produkty
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3" editable>
                Płatność
            </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
<v-stepper-content step="1">
    <v-card class="mb-12" color="lighten-1">
        <v-container>
        <v-form>
            
            <v-row>
                <v-col cols="12">
                    <v-text-field label="Numer Umowy" :counter="10" :rules="dealNoRules" v-model="dealNoRent" @input="dealNoRent = dealNoRent.toUpperCase()"
                        autocomplete="new-password">
                    </v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Imię Wypożyczającego" :rules="[(v) => !!v || 'Uzupełnij Pole']" @input="rentFirstName = rentFirstName.toUpperCase()"
                        v-model="rentFirstName" autocomplete="new-password">
                    </v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field @input="rentLastName = rentLastName.toUpperCase()" label="Nazwisko Wypożyczającego" :rules="[(v) => !!v || 'Uzupełnij Pole']"
                        v-model="rentLastName" autocomplete="new-password">
                    </v-text-field>
                </v-col>
                <v-col cols="8">
                <v-text-field disabled label="Adres Klienta" v-model="clientAddress"></v-text-field>

                </v-col>
                <v-col cols="4">
                    <v-btn class="primary" style="width:100%;" @click="showClientAddres =!showClientAddres" :disabled="(!productAddress && !rentProductAddress?true:false )">Zmień</v-btn>
                </v-col>
                <v-col cols="12" v-if="showClientAddres">
                    <vuetify-google-autocomplete id="map1" required autocomplete="new-password" append-icon="mdi-search"
                                            classname="form-control" placeholder="Adres Zameldowania" 
                                            ref="addr" v-model="rentClientAddress" v-on:placechanged="getAddressData" country="pl">
                                        </vuetify-google-autocomplete>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Numer Mieszkania - Zameldowanie" v-model="rentClientAddressApt"></v-text-field>
                </v-col>
                <v-col cols="8">
                    <v-text-field disabled label="Adres Sprzętu" v-model="productAddress"></v-text-field>
                </v-col>
                                <v-col cols="4" v-if="!showproductAddress">
                                    <v-btn class="primary" style="width:100%;" @click="showproductAddress=!showproductAddress"> <v-icon>mdi-chevron-down</v-icon></v-btn>
                                </v-col>
                                    <v-col cols="4" v-if="showproductAddress">
                                        <v-btn class="primary" style="width:100%;" @click="showproductAddress = !showproductAddress"><v-icon>mdi-chevron-up</v-icon></v-btn>
                                    </v-col>                                
                                <v-col cols="12" v-if="showproductAddress">
                                        <vuetify-google-autocomplete id="map2" required append-icon="mdi-search" autocomplete="new-password"
                                            classname="form-control" placeholder="Nowy Adres Sprzętu" ref="addr"
                                            v-model="rentProductAddress" v-on:placechanged="getAddressData1" country="pl">
                                        </vuetify-google-autocomplete>
                                </v-col>
                <v-col cols="12">
                    <v-text-field autocomplete="new-password" label="Numer Mieszkania - Sprzęt"
                        v-model="rentProductAddressApt"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-radio-group v-model="radios">
                        <template v-slot:label>
                            <div><strong>Wybierz Telefon Do Umowy:</strong></div>
                        </template>
    <v-radio value="caller">
        <template v-slot:label>
                                <v-text-field autocomplete="new-password" label="Telefon Dzwoniącego" :rules="[(v) => !!v || 'Uzupełnij Pole']"
                                    v-model="rentCallerPhone">
                                </v-text-field>
        </template>
    </v-radio>
        <v-radio value="contact">
            <template v-slot:label>
                                        <v-text-field autocomplete="new-password" label="Telefon Do Kontaktu" 
                                            v-model="rentClientPhone">
                                        </v-text-field>
            </template>
        </v-radio>
        <v-radio value="other">
            <template v-slot:label>
                <v-text-field autocomplete="new-password" label="Inny Telefon" 
                    v-model="otherPhone">
                </v-text-field>
            </template>
        </v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="E-mail" autocomplete="new-password" v-model="rentMail">
                    </v-text-field>
                </v-col>
                <v-col cols="12" class="text-center">
                    <v-textarea autocomplete="new-password" label="Notatka Pracownika" v-model="deliveryRentNote">
                    </v-textarea>
                    <v-btn color="warning"  @click="updateNote()">Aktualizuj Notatkę</v-btn>
                </v-col>
            </v-row>
            
        </v-form>
        </v-container>
    </v-card>
    <div class="text-right">
        <v-btn class="primary" @click="e1='2'">Dalej</v-btn>
    </div>

</v-stepper-content>

<v-stepper-content step="2">
    <v-card class="mb-12" color="lighten-1">
        <v-list subheader two-line>
            <v-list-item v-for="product in products" :key="product.id">
                <v-row>
                    <v-col cols="8">
                        <v-list-item-content>
                            <v-list-item-title v-text="product.name"></v-list-item-title>
                            <v-list-item-title :class="product.color" v-text="product.subtitle"></v-list-item-title>
                        </v-list-item-content>
                    </v-col>
                    <v-col cols="4">
                        <v-list-item-action class="text-center">
                            <v-list-item-title>
                                <v-text-field type="number" label="Ilość" placeholder="Ilość"
                                    :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="qtyInput[product.id]">
                                </v-text-field>
                            </v-list-item-title>
                        </v-list-item-action>
                    </v-col>
                </v-row>
            </v-list-item>
        </v-list>
        <div class="text-center">
            <v-btn class="success" v-if="showButton" @click="addProductToRent()">Dodaj Produkty</v-btn>
        </div>
        <v-list subheader two-line>
            <v-list-item v-for="product in productsDetails" :key="product.id">
                <v-list-item-content>
                    <v-list-item-title v-text="product.products.name"></v-list-item-title>
                    <br />
                    <v-row>
                        <v-col cols="6">
                            <v-text-field v-model="rentUntil[product.id]" label="Wypożyczono Do"
                                :rules="[(v) => !!v || 'Uzupełnij Pole']" type="date" @input="countDays(product.id)">
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="rentMonth[product.id]" label="Ilość miesięcy" disabled>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" v-if="product.products.name == 'Łóżko'">
                            <v-checkbox type="checkbox" label="Brak Pianki" v-model="isPianka[product.id]"></v-checkbox>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="rentPrice[product.id]" autocomplete="new-password" label="Kwota"
                                :rules="[(v) => !!v || 'Uzupełnij Pole']" type="number " @input="countTotalRent()">
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-file-input v-model="photoRent[product.id]" @change="uploadFile(product.id,product.products.id)" label="Zdjęcie" accept="image/*"
                                :rules="[(v) => !!v || 'Uzupełnij Pole']" type="file"></v-file-input>
                        </v-col>
                    </v-row>
                </v-list-item-content>
                <v-list-item-action>
                    <v-btn v-if="showButton" @click="removeFromRent(product.id)" icon class="error">
                        <v-icon class="color:black;">mdi-delete</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
        </v-list>
    </v-card>
    <div>
        <v-btn class="primary text-left" @click="e1='1'">Poprzednia</v-btn>
        <v-btn class="primary float-right" @click="e1='3'">Dalej</v-btn>
    </div>
</v-stepper-content>

<v-stepper-content step="3">
    <v-card class="mb-12" color="lighten-1">
        <v-form>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-text-field label="Kilometrówka" v-model="totalTransportDelivery" @input="totalRent = parseFloat(totalTransportDelivery) + parseFloat(totalExternalCost) + parseFloat(totalPaidRent)" type="number" step="0.1"
                        autocomplete="new-password" :rules="[(v) => v>=0 || 'Uzupełnij Pole']" :disabled="showDeliveryCost"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Do Zapłaty za Wynajem" v-model="totalPaidRent" @input="totalRent = parseFloat(totalTransportDelivery) + parseFloat(totalExternalCost) + parseFloat(totalPaidRent)" type="number" step="0.1" autocomplete="new-password"
                        :rules="[(v) => v>=0 || 'Uzupełnij Pole']"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Dodatkowa Sprzedaż" v-model="totalExternalCost"
                        @input="totalRent = parseFloat(totalTransportDelivery) + parseFloat(totalPaidRent) + parseFloat(totalExternalCost)" type="number" step="0.1"
                        autocomplete="new-password" :rules="[(v) => v>=0 || 'Uzupełnij Pole']"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field step="0.1" disabled label="Łącznie" v-model="totalRent" type="number" autocomplete="new-password"
                        :rules="[(v) => v>=0 || 'Uzupełnij Pole']"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-checkbox label="Faktura?" v-model="isInvoice"></v-checkbox>
                    <v-textarea label="Dane Do Faktury" v-model="invoiceData" v-if="isInvoice"></v-textarea>
                </v-col>
                    <v-col cols="12">
                        <v-checkbox label="Dostawa Weekendowa?" v-model="isWeekendDelivery"></v-checkbox>                        
                    </v-col>                
                <v-col cols="12">
                    <v-select item-text="name" item-value="id" v-model="paymentType" :items="paymentTypes" label="Rodzaj Płatności"></v-select>
                </v-col>
            </v-row>
            </v-container>
        </v-form>
    </v-card>
    <div>
        <v-btn class="primary float-left" @click="e1='2'">Poprzednia</v-btn>
        <v-btn v-if="isAdmin" color="warning" class="float-center" @click="saveClientDetails(true)">
            Edytuj
        </v-btn>
        <v-btn v-if="showButton" color="green" class="float-right" @click="saveClientDetails()">
            Wykonano
        </v-btn>        
    </div>
</v-stepper-content>
            </v-stepper-items>
        </v-stepper>
        </v-card>
    </v-dialog>
</template>
<script
  type="text/javascript"
  src="http://maps.google.com/maps/api/js?key=AIzaSyDGlgz880rqzKKuiWTugR3JsWRjCtjdH_g&sensor=false&libraries=places&language=pl-PL"
></script>
<script>
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";
import Swal from "sweetalert2";
const axios = require("axios");
import * as dayjs from 'dayjs'
var isToday = require('dayjs/plugin/isToday')
dayjs.extend(isToday)
export default {
    props: ['records','warehouse','getEvents'],
    components: {
        VuetifyGoogleAutocomplete, // make the <FullCalendar> tag available
    },      
    data() {
        return {
            dealNoRules: [
                v => !!v || 'Wpisz Numer Umowy',
                v => (v && v.length == 10) || 'Numer Umowy Musi Posiadać 10 znaków',
            ],  
            isWeekendDelivery:false,
            photoRentId:[],          
            isInvoice:false,
            invoiceData:'',
            showDeliveryCost:false,
            isAdmin:false,
            otherPhone:'',
            showButton:false,
            e1:1,
            clientAddress:'',
            productAddress:'',
            showClientAddres: false,
            showproductAddress: false,
            fullDetailsDialog:false,
            dealNoInfo:'',
            deliveryProductAddrInfo: '',
            clientPhoneInfo: '',
            callerPhoneInfo: '',
            noteInfo: '',
            noteInfoDriver: '',
            productsStr: '',       
            dealNoRent:'',
            rentFirstName:'',
            rentLastName:'',
            rentClientAddress:'',
            rentClientAddressApt:'',
            rentProductAddress:'',
            rentProductAddressApt:'',
            rentCallerPhone:'',
            rentClientPhone:'',
            rentMail:'',
            deliveryRentNote:'',
            products:[],
            qtyInput:[],
            oldPrice:[],
            productsDetails:[],
            rentUntil:[],
            rentMonth:[],
            isPianka:[],
            rentPrice:[],
            photoRent:[],
            rentsId:[],
            radios:null,
            currentWh:null,
            totalRent:0,
            kmCountRent:0,
            pricePerKmRent:2,
            totalTransportDelivery:0,
            totalPaidRent:0,
            totalExternalCost:0,
            rentReceipt:false,
            rentCheckBoxTransport:false,
            paymentType:null,   
            rentClientAddressHelper:'',
            rentProductAddressHelper:'',
            paymentTypes:[
                {
                    id: 'cash',
                    name: 'Gotówka'
                },                
                {
                id:'card',
                name:'Karta'
                },
                {
                    id:'gate',
                    name:'Sklep Online'
                },
                    {
                    id:'wire',
                    name:'Przelew'
                },                                                   
            ]
        }
    },
    created(){
        const role = localStorage.getItem("role")
        if (role == 'admin') {
            this.isAdmin = true
        }else{
            this.isAdmin = false
        }
    },
    methods:{
        async uploadFile(id,pid){
            if(!id){
                Swal.fire("Błąd", 'Brak Id Zdjęcia', "error");
                return false   
            }
            if(!this.dealNoRent.trim().length){
                Swal.fire("Błąd", 'Uzupełnij Numer Umowy', "error");
                return false
            } 
            if(this.dealNoRent.trim().length != 10){
                Swal.fire("Błąd", 'Numer Umowy Musi Zawierać 10 znaków', "error");
                return false
            }                  
            const formData = new FormData();
            formData.append("file", this.photoRent[id], this.photoRent[id].name); 
            await this.toggleLoading(true);                  
            try {

                formData.append('productId', pid);
                formData.append('dealNo', this.dealNoRent);
                const fileData = await axios({
                    method: "post",
                    url:
                        process.env.VUE_APP_ROOT_API +
                        "/events/upload-file/",                        
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        "Content-Type": "multipart/form-data"
                    },
                    data: formData
                });
                Swal.fire("Sukces", "Zapisano Zdjęcie", "success");
                this.photoRentId[id] = fileData.data.data.url
            } catch (e) {
                console.log(e);
                Swal.fire("Błąd", 'Nie udało się zapisac zdjęcia-AWS', "error");
            }    
            await this.toggleLoading(false);         
        },
        initData(){
            this.isInvoice = false
            this.invoiceData = ''
            this.currentWh = null
            this.otherPhone=''
            this.showButton=false
            this.e1=1
            this.clientAddress=''
            this.productAddress=''
            this.showClientAddres= false
            this.showproductAddress= false
            this.fullDetailsDialog=false
            this.deliveryProductAddrInfo= ''
            this.dealNoInfo=''
            this.clientPhoneInfo= ''
            this.callerPhoneInfo= ''
            this.noteInfo= ''
            this.noteInfoDriver= ''
            this.productsStr= ''       
            this.dealNoRent=''
            this.rentFirstName=''
            this.rentLastName=''
            this.rentClientAddress=''
            this.rentClientAddressApt=''
            this.rentProductAddress=''
            this.rentProductAddressApt=''
            this.rentCallerPhone=''
            this.rentClientPhone=''
            this.rentMail=''
            this.deliveryRentNote=''
            this.products=[]
            this.qtyInput=[]
            this.productsDetails=[]
            this.rentUntil=[]
            this.rentMonth=[]
            this.isPianka=[]
            this.rentPrice=[]
            this.photoRent=[]
            this.photoRentId=[]
            this.rentsId=[]
            this.radios=null
            this.totalRent=0
            this.kmCountRent=0
            this.pricePerKmRent=2
            this.totalTransportDelivery=0
            this.totalPaidRent=0
            this.rentReceipt=false
            this.rentCheckBoxTransport=false
            this.paymentType=null   
            this.rentClientAddressHelper=''
            this.rentProductAddressHelper=''
            this.paymentTypes=[
                {
                    id: 'cash',
                    name: 'Gotówka'
                },                
                {
                id:'card',
                name:'Karta'
                },
                {
                    id:'gate',
                    name:'Sklep Online'
                },
                    {
                    id:'wire',
                    name:'Przelew'
                },                                                   
            ]
        },
        async updateNote() {
            await this.toggleLoading(true);
            try {
                await axios({
                    method: "patch",
                    url:
                        process.env.VUE_APP_ROOT_API +
                        "/events/update-note/" +
                        this.id,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    data: {
                        deliveryNote: this.deliveryRentNote,
                    },
                });
                Swal.fire("Sukces", "Zapisano", "success");
            } catch (e) {
                console.log(e);
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            await this.toggleLoading(false);
        },         
        countDays(id) {
        const date1 = dayjs().startOf("day");
        this.rentMonth[id] = dayjs(this.rentUntil[id])
            .startOf("day")
            .diff(date1, "month", true)
            .toFixed(2);
        },    
        countTotalRent(){      
            this.totalPaidRent = 0
            this.productsDetails.forEach((item)=>{                
                this.totalPaidRent = parseFloat(this.totalPaidRent) + parseFloat(this.rentPrice[item.id])
            })
        },                                
        getAddressData: async function (addressData) {
            await this.toggleLoading(true);
            try {
                this.rentClientAddressHelper= addressData;
               
            } catch (e) {
                console.log(e);
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            await this.toggleLoading(false);
        },
        getAddressData1: async function (addressData) {
            await this.toggleLoading(true);
            try {
                this.rentProductAddressHelper= addressData;                
            } catch (e) {
                console.log(e);
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            await this.toggleLoading(false);
        }, 
        async getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
        },        
        async saveClientDetails(isUpdate = false){            
            if(!this.productAddress){
                if(!this.rentProductAddress){
                    if(this.rentProductAddressApt){
                        Swal.fire("Uwaga!", 'Wpisano Dane w Pole Numer Mieszkania Zamiast Adresu Sprzętu', "warning");
                        return false                        
                    }else if(!this.rentClientAddress && this.rentClientAddressApt){
                        Swal.fire("Uwaga!", 'Wpisano Adres Zameldowania w Pole Numeru Mieszkania Zameldowania, Adres sprzętu nie może być pusty!', "warning");
                        return false                           
                    }else if(this.rentClientAddressApt){
                        Swal.fire("Uwaga!","Wpisano Adres Zameldowania Klienta Zamiast Adresu Sprzętu", "warning") 
                        return false
                    }else{
                        Swal.fire("Uwaga!","Uzupełnij Pole Adres Sprzętu!", "warning")
                        return false
                    }                 
                }
            }
            if(!this.dealNoRent.trim().length){
                Swal.fire("Błąd", 'Uzupełnij Numer Umowy', "error");
                return false
            } 
            if(this.dealNoRent.trim().length != 10){
                Swal.fire("Błąd", 'Numer Umowy Musi Zawierać 10 znaków', "error");
                return false
            }             
            if(!this.rentFirstName.trim().length){
                Swal.fire("Błąd", 'Uzupełnij Imię Wypożyczającego', "error");
                return false
            }
            if(!this.rentLastName.trim().length){
                Swal.fire("Błąd", 'Uzupełnij Nazwisko Wypożyczającego', "error");
                return false
            }
            if(!this.rentCallerPhone.trim().length){
                Swal.fire("Błąd", 'Uzupełnij Telefon Dzwoniącego', "error");
                return false
            }    
            if(!this.radios){                
                Swal.fire("Błąd", 'Wybierz Telefon Do Kontaktu', "error");
                return false                     
            }
            if(this.radios =='caller'){
                if(!this.rentCallerPhone.trim().length){
                    Swal.fire("Błąd", 'Uzupełnij Telefon Dzwoniącego', "error");
                    return false
                } 
            } 
            if(this.radios =='contact'){
                if(!this.rentClientPhone.trim().length){
                    Swal.fire("Błąd", 'Uzupełnij Telefon Do Klienta', "error");
                    return false
                } 
            }                                
            if(this.radios =='other'){
                if(!this.otherPhone.trim().length){
                    Swal.fire("Błąd", 'Uzupełnij Pole Inny Telefon', "error");
                    return false
                } 
            }
            if(this.totalPaidRent<0){
                Swal.fire("Błąd", 'Uzupełnij Kwotę Do Zapłaty', "error");
                return false
            } 
            if(this.totalTransportDelivery<0){
                Swal.fire("Błąd", 'Uzupełnij Kilometrówkę', "error");
                return false
            } 
            if(!this.paymentType){
                Swal.fire("Błąd", 'Wybierz Rodzaj Płatności', "error");
                return false
            }             
            try{
        let rentData = [];
        for (let a = 0; a < this.rentsId.length; a++) {
          if(!this.rentUntil[this.rentsId[a]].length){
            Swal.fire("Błąd", 'Uzupełnij Opłacono Do', "error");
            return false
          }
          if(!this.rentPrice[this.rentsId[a]]){
            Swal.fire("Błąd", 'Uzupełnij Cenę Wypożyczenia', "error");
            return false
          }     
          const date1 = dayjs().format('YYYY-MM-DD')
          const diff = dayjs(this.rentUntil[this.rentsId[a]])
            .startOf("day")
            .diff(date1, "month", true)
            .toFixed(2)
          if(diff != 1){
            if(!isUpdate){
                if(parseFloat(this.rentPrice[this.rentsId[a]]) == parseFloat(this.oldPrice[this.rentsId[a]])){
                    Swal.fire("Błąd", 'Zmień cenę Produktu!', "error");
                    return false                
                }
            }
          }  
          if(parseFloat(this.rentPrice[this.rentsId[a]]) != parseFloat(this.oldPrice[this.rentsId[a]])){
            if(diff==1){
                if(!isUpdate){
                    Swal.fire("Błąd", 'Zmień datę ważności wypożyczenia!', "error");
                    return false 
                }
            }
          }    
          rentData.push({
            id: this.rentsId[a],
            paidUntil: this.rentUntil[this.rentsId[a]],
            isMateracPianka: this.isPianka[this.rentsId[a]],
            rentPrice: parseFloat(this.rentPrice[this.rentsId[a]]),
            img: this.photoRentId[this.rentsId[a]] || null,
          });
        }           
        await this.toggleLoading(true);
        await axios({
          method: "patch",
          url:
            process.env.VUE_APP_ROOT_API +
            "/events/update-rent/" +
            this.id,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: {
            extraCost:this.totalExternalCost,
            selectedPhone:this.radios,
            dealNo: this.dealNoRent,
            firstName: this.rentFirstName,
            lastName: this.rentLastName,
            callerPhone: this.rentCallerPhone,
            clientPhone: this.rentClientPhone,
            isInvoice: this.isInvoice,
            invoiceData: this.isInvoice? this.invoiceData: '',
            otherPhone: this.otherPhone,
            mail: this.rentMail,
            deliveryNote: this.deliveryRentNote,
            showproductAddress:this.showproductAddress,
            paymentType: this.paymentType,
            showClientAddres:this.showClientAddres,
            clientAddress: this.rentClientAddress,
            clientAddressApt: this.rentClientAddressApt,
            clientAddressGeoLat: this.rentClientAddressHelper.latitude,
            clientAddressGeoLng: this.rentClientAddressHelper.longitude,
            productAddress: this.rentProductAddress,
            productAddressApt: this.rentProductAddressApt,
            productAddressGeoLat: this.rentProductAddressHelper.latitude,
            productAddressGeoLng: this.rentProductAddressHelper.longitude,
            totalPaidRent: parseFloat(this.totalPaidRent),
            totalDelivery: parseFloat(this.totalTransportDelivery),
            rentData: rentData,
            isOnlyUpdate:isUpdate,            
          },
        });
        await this.toggleLoading(false);
        this.initData()
        this.fullDetailsDialog = false
        await this.getEvents()
            }catch(e){
                console.log(e)
                Swal.fire("Błąd", e.response.statusText, "error");
            }
        },                                       
        async showRentForm(evt){              
            this.id = evt.id  
            this.currentWh = evt.warehouse.id
            this.fullDetailsDialog = true            
            if(evt.isDone){
                const role = localStorage.getItem("role")
                if(role!='admin'){
                    if (dayjs(evt.startDate).isToday()){
                        this.isAdmin = true
                    }
                }
            }  
             this.totalTransportDelivery = evt.totalDelivery/100
             this.totalExternalCost = evt.extraCost/100
            if(evt.showDeliveryCost){
                this.showDeliveryCost = true
                 this.totalTransportDelivery = 0
            }
            this.noteInfo = evt.modNote
            this.noteInfoDriver = evt.deliveryNote                
            this.totalPaidRent = evt.totalSell/100
            const rent = await axios({
                method: "get",
                url:
                process.env.VUE_APP_ROOT_API +
                "/rents/get-for-event/" +
                this.id,
                headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }); 
            this.deliveryReturnNoteWorker = evt.deliveryNote            
           
            let productsStr = ''
            this.productsStr = ''

            if (rent.data.data.length) {
                rent.data.data.forEach((item) => {
                    if (item.products.name == 'Łóżko') {
                        if (item.isMateracPianka) {
                            productsStr = productsStr + item.products.name + '*, '
                        } else {
                            productsStr = productsStr + item.products.name + ', '
                        }
                    } else {
                        productsStr = productsStr + item.products.name + ', '
                    }
                })
            }
            this.productsStr = productsStr
            this.dealNoInfo = rent.data.data[0].dealNo
            this.isInvoice = rent.data.data[0].invoiceData?true:false
            this.invoiceData = rent.data.data[0].invoiceData?rent.data.data[0].invoiceData:''
            this.deliveryProductAddrInfo = rent.data.data[0].productAddress + ' Mieszkanie: ' + rent.data.data[0].productAddressApt
            this.callerPhoneInfo = rent.data.data[0].callerPhone
            // this.radios = rent.data.data[0].selectedPhone
            switch (rent.data.data[0].selectedPhone) {
                case 'contact':
                    this.clientPhoneInfo = rent.data.data[0].clientPhone ? rent.data.data[0].clientPhone : 'Brak'
                    break;
                case 'caller':
                    this.clientPhoneInfo = rent.data.data[0].callerPhone ? rent.data.data[0].callerPhone : 'Brak'
                    break;
                case 'other':
                    this.clientPhoneInfo = rent.data.data[0].otherPhone ? rent.data.data[0].otherPhone : 'Brak'
                    break;
            }

            this.rentFirstName = rent.data.data[0].firstName
            this.rentLastName = rent.data.data[0].lastName            
            this.dealNoRent = rent.data.data[0].dealNo        
            this.clientAddress = rent.data.data[0].clientAddress            
            this.productAddress = rent.data.data[0].productAddress
            this.rentProductAddressApt = rent.data.data[0].productAddressApt
            this.rentClientAddressApt = rent.data.data[0].clientAddressApt
            this.rentCallerPhone = rent.data.data[0].callerPhone
            this.rentClientPhone = rent.data.data[0].clientPhone
            this.rentMail = rent.data.data[0].mail            
            this.productsDetails=[]
            this.rentsId=[]
            this.isPianka =[]
            this.rentUntil=[]
            this.rentPrice=[]
            this.productsDetails = rent.data.data
            this.showButton = !this.records.isDone
            rent.data.data.forEach((item) => {                
              this.rentsId.push(item.id);  
              this.isPianka[item.id] = item.isMateracPianka  
              if(item.paidToDate){
                this.rentUntil[item.id] = dayjs(item.paidToDate).toISOString().substr(0, 10);
              }else{
                this.rentUntil[item.id] = dayjs().add(1,'month').toISOString().substr(0, 10);
              }     
              this.photoRentId[item.id]= item.img || null              
              this.countDays(item.id)
              this.rentPrice[item.id] =
                item.rentPrice > 0 ? item.rentPrice : item.products.rentPrice;
              this.oldPrice[item.id] =
                item.rentPrice > 0 ? item.rentPrice : item.products.rentPrice;                
            });            
            this.kmCountRent = rent.data.data[0].kmLength;
            this.pricePerKmRent = rent.data.data[0].kmPrice; 
            this.countTotalRent()           
            await this.getProducts();
            this.paymentType = evt.paymentType
            this.deliveryRentNote = evt.deliveryNote
            this.totalRent = parseFloat(this.totalPaidRent) + parseFloat(this.totalTransportDelivery)            
        }, 
    async addProductToRent() {
      await this.toggleLoading(true);
      try {
        let productsId = [];
         let products =''         
        if((this.warehouse == 'null')||(this.warehouse == null)){            
        products = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "/products/" + this.currentWh+`/?sort=${'id'}&direction=${false}&page=${1}&limit=${100}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });            
        }else{
        products = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "/products/" + this.warehouse+`/?sort=${'id'}&direction=${false}&page=${1}&limit=${100}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        }
        products.data.data.items.forEach((item) => {
          if (this.qtyInput[item.id] > 0) {
            for (let a = 0; a < this.qtyInput[item.id]; a++) {
              productsId.push(item.id);
            }
          }
        });
        await axios({
          method: "patch",
          url:
            process.env.VUE_APP_ROOT_API +
            "/events/append-products/" +
            this.id,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: {
            rentedProductId: productsId,
          },
        });
        productsId = [];
        products.data.data.items.forEach((item) => {
          this.qtyInput[item.id] = "";
        });

          const rents = await axios({
            method: "get",
            url:
              process.env.VUE_APP_ROOT_API +
              "/rents/get-for-event/" +
              this.id,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          });
           this.productsDetails=[]
           this.rentsId = []
          await this.getProducts();
          if (rents.data.data.length) {
            this.productsDetails = rents.data.data;
            rents.data.data.forEach((item) => {
              this.rentsId.push(item.id);  
              this.isPianka[item.id] =  item.isMateracPianka  
              if(item.paidToDate){
                this.rentUntil[item.id] = dayjs(item.paidToDate).toISOString().substr(0, 10);
              }else{
                this.rentUntil[item.id] = dayjs().add(1,'month').toISOString().substr(0, 10);
              }                 
              this.photoRentId[item.id]= item.img || null                                             
              this.countDays(item.id)
              this.rentPrice[item.id] =
                item.rentPrice > 0 ? item.rentPrice : item.products.rentPrice;
              this.oldPrice[item.id] =
                item.rentPrice > 0 ? item.rentPrice : item.products.rentPrice;                
              this.kmCountRent = rents.data.data[0].kmLength;
              this.pricePerKmRent = rents.data.data[0].kmPrice;
            });
          }        
          this.countTotalRent()
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      await this.toggleLoading(false);
    },         
async removeFromRent(id) {
      try {
        Swal.fire({
          title: "Usunąć Produkt?",
          text: "",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Tak",
          cancelButtonText: "Anuluj",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await axios({
              method: "delete",
              url: process.env.VUE_APP_ROOT_API + "/rents/" + id,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
              data: {
                status: false,
              },
            });
          const rents = await axios({
            method: "get",
            url:
              process.env.VUE_APP_ROOT_API +
              "/rents/get-for-event/" +
              this.id,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          });
          this.productsDetails=[]
          this.rentsId = []
          await this.getProducts();
          if (rents.data.data.length) {
            this.productsDetails = rents.data.data;
            rents.data.data.forEach((item) => {
              this.rentsId.push(item.id);  
              this.isPianka[item.id] = item.isMateracPianka  
              if(item.paidToDate){
                this.rentUntil[item.id] = dayjs(item.paidToDate).toISOString().substr(0, 10);
              }else{
                this.rentUntil[item.id] = dayjs().add(1,'month').toISOString().substr(0, 10);
              } 
              this.photoRentId[item.id]= item.img || null                     
              this.countDays(item.id)
              this.rentPrice[item.id] =
                item.rentPrice > 0 ? item.rentPrice : item.products.rentPrice;
              this.oldPrice[item.id] =
                item.rentPrice > 0 ? item.rentPrice : item.products.rentPrice;                
              this.kmCountRent = rents.data.data[0].kmLength;
              this.pricePerKmRent = rents.data.data[0].kmPrice;
            });
          }            
          }   
          this.countTotalRent()
        });
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.statusText, "error");
      }
    },              
        async toggleLoading(type) {
            const toggle = type;
            localStorage.setItem("loading", toggle);
            window.dispatchEvent(
                new CustomEvent("toggleLoading", {
                detail: {
                    loading: localStorage.getItem("loading"),
                    overlay: localStorage.getItem("loading"),
                },
                })
            );
        },          
        async getProducts() {            
            await this.toggleLoading(true);
            this.products = [];
            try {
         let products =''
        if(this.warehouse == null){
        products = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "/products/" + this.currentWh+`/?sort=${'id'}&direction=${false}&page=${1}&limit=${100}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });            
        }else{
        products = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "/products/" + this.warehouse+`/?sort=${'id'}&direction=${false}&page=${1}&limit=${100}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        }
                const ids = products.data.data.items.map(item => item.id)
                let bed = await axios({
                method: "get",
                url: process.env.VUE_APP_ROOT_API + "/rents/rented-beds/" + ids.toString() + '/'+ dayjs().format('YYYY-MM-DD HH:mm'),
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                });                  
              if(this.warehouse == 2 || this.warehouse == 8){
                  const products1 = await axios({
                      method: "get",
                      url: process.env.VUE_APP_ROOT_API + "/products/" + (this.warehouse == 2 ? 8:2) + `/?sort=${'id'}&direction=${false}&page=${1}&limit=${100}`,
                      headers: {
                          Authorization: "Bearer " + localStorage.getItem("token"),
                      },
                  });
                  const ids1 = products1.data.data.items.map(item => item.id)
                  let bed1 = await axios({
                      method: "get",
                      url: process.env.VUE_APP_ROOT_API + "/rents/rented-beds/" + ids1.toString()+ '/'+ dayjs().format('YYYY-MM-DD HH:mm'),
                      headers: {
                          Authorization: "Bearer " + localStorage.getItem("token"),
                      },
                  }); 
                  for (let a = 0; a < products.data.data.items.length;a++){
                      let rented = 0
                      let rentIndex = bed.data.data.rents.find(it => it.id == products.data.data.items[a].id)
                      let rentIndex1 =  bed1.data.data.rents.find(it => it.id == products1.data.data.items[a].id);                    
                      rented = (rentIndex ? parseInt(rentIndex.sumRents) : 0) + (rentIndex1 ? parseInt(rentIndex1.sumRents) : 0)                    
                      let returns = 0
                      let returnIndex = bed.data.data.returns.find(it => it.id == products.data.data.items[a].id) 
                      let returnIndex1 = bed1.data.data.returns.find(it => it.id == products1.data.data.items[a].id);                                  
                      returns = (returnIndex ? parseInt(returnIndex.sumReturns) : 0) + (returnIndex1 ? parseInt(returnIndex1.sumReturns) : 0)                                            
                      let returns_till = 0
                      let returnTillIndex = bed.data.data.returns_till_date.find(it => it.id == products.data.data.items[a].id) 
                      let returnTillIndex1 = bed1.data.data.returns_till_date.find(it => it.id == products1.data.data.items[a].id);                                  
                      returns_till = (returnTillIndex ? parseInt(returnTillIndex.sumReturns) : 0) + (returnTillIndex1 ? parseInt(returnTillIndex1.sumReturns) : 0)                                                
                      products.data.data.items[a].subtitle = ` ${products.data.data.items[a].qty - rented + returns_till} | ${products.data.data.items[a].qty - rented + returns} | ${products.data.data.items[a].qty} | ${returns} | ${rented}`
                      if ((products.data.data.items[a].qty - rented + returns) > 0) {
                          products.data.data.items[a].color = "green";
                      } else {
                          products.data.data.items[a].color = "red";
                      }  
                  }                  
              }else{
                  products.data.data.items.forEach((item) => {
                      let rented = 0
                      let rentIndex = bed.data.data.rents.find(it => it.id == item.id);
                      if (rentIndex) {
                          rented = parseInt(rentIndex.sumRents)
                      }
                      let returns = 0
                      let returnIndex = bed.data.data.returns.find(it => it.id == item.id);
                      if (returnIndex) {
                          returns = parseInt(returnIndex.sumReturns)
                      }
                      let returns_till = 0
                      let returnTillIndex = bed.data.data.returns_till_date.find(it => it.id == item.id);
                      if (returnTillIndex) {
                          returns_till = parseInt(returnTillIndex.sumReturns)
                      }                      
                      item.subtitle = ` ${item.qty-rented+returns_till} | ${item.qty - rented + returns} | ${item.qty} | ${returns} | ${rented}`
                      if ((item.qty - rented + returns) > 0) {
                          item.color = "green";
                      } else {
                          item.color = "red";
                      }
                  });
              }   
                this.products = products.data.data.items;
            } catch (e) {
                console.log(e);
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            await this.toggleLoading(false);
        },          
    }
}
</script>
