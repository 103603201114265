<template>
    <v-menu v-model="quickDetailsDialog" :close-on-content-click="false" style="width: 100%">
        <v-card color="dark" flat>
            <v-toolbar color="green" dark>
                <v-btn icon @click="showFullForm()">
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
                                <v-btn icon @click="getRentDetails()">
                                    <v-icon>mdi-cellphone-cog</v-icon>
                                </v-btn>
                <v-toolbar-title>Szczegóły Wydarzenia</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-container>
                <v-sheet color="brown darken-4" rounded="rounded" class="mx-auto" v-if="!isOther">                    
                    Numer Umowy: {{ dealNoInfo }}<br />
                    Adres Sprzętu: <a :href="'http://maps.google.com/?q=' + deliveryProductAddrInfo" target="_blank">{{ deliveryProductAddrInfo }}</a><br />
                    {{ transportProductAddrInfo }}
                    Telefon Do Kontaktu: <a :href="'tel: '+clientPhoneInfo">{{ clientPhoneInfo }}</a><br />
                    Telefon Dzwoniącego: <a :href="'tel: ' + callerPhoneInfo">{{ callerPhoneInfo }}</a><br />
                    Uwagi: {{ noteInfo }}<br />
                    Uwagi Kierowcy: {{ noteInfoDriver }}<br />
                    Data Aktualizacji Rekordu: {{ updateDate }}<br />
                    Data Dodania Rekordu: {{ createdDate }}<br />
                    Produkty: {{ productsStr }}
                </v-sheet>
                <v-sheet color="brown darken-4" rounded="rounded" class="mx-auto" v-if="isOther">
                    Telefon: <a :href="'tel: '+clientPhoneInfo">{{ clientPhoneInfo }}</a><br />                    
                    Uwagi: {{ noteInfo }}<br />
                    Uwagi Kierowcy: {{ noteInfoDriver }}<br />
                    Data Aktualizacji Rekordu: {{ createdDate }}<br />
                    Data Dodania Rekordu: {{ updateDate }}<br />
                    Produkty: {{ productsStr }}
                </v-sheet>
                <v-row>
                    <v-col cols="12">
                        <v-text-field type="date" background-color="secondary" v-model="quickEditDataStart" label="Data rozpoczęcia" required
                            :rules="[(v) => !!v || 'Uzupełnij Pole']"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field type="time" background-color="secondary" v-model="quickEditTimeStart" label="Godzina rozpoczęcia" required
                            :rules="[(v) => !!v || 'Uzupełnij Pole']"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field type="time" background-color="secondary" v-model="quickEditTimeEnd" label="Godzina zakończenia" required
                            :rules="[(v) => !!v || 'Uzupełnij Pole']"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-select label="Wybierz Magazyn" @change="getUsersSelectQuick()" v-model="warehouseQuick" :items="warehousesQuick" item-text="name" item-value="id"
                            :rules="[(v) => !!v || 'Uzupełnij Pole']"></v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-select label="Zmień Użytkownika" v-model="userQuick" :items="usersQuick" item-text="fullName"
                            item-value="id" :rules="[(v) => !!v || 'Uzupełnij Pole']"></v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea label="Uwagi" v-model="quickNote"></v-textarea>
                    </v-col>
                </v-row>
            </v-container>
            <v-card-actions>
                <v-btn text color="secondary" @click="quickDetailsDialog = false">
                    Anuluj
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="deleteEvent()">
                    Usuń
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="warning" text @click="duplicateEvent()">
                    Duplikuj
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="updateEventFast()">
                    Zapisz
                </v-btn>
            </v-card-actions>
                <v-dialog v-model="detailsPhoneDialog">
                    <v-card>
                        <v-toolbar dark color="primary">
                            <v-btn icon dark @click="detailsPhoneDialog = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-toolbar-title>Edycja Danych</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-row>
                            <v-col cols="12">
                                <v-radio-group v-model="radiosFast">
                                    <template v-slot:label>
                                        <div><strong>Wybierz Telefon Do Kontaktu:</strong></div>
                                    </template>
                                    <v-radio value="caller">
                                        <template v-slot:label>
                                            <v-text-field autocomplete="new-password" label="Telefon Dzwoniącego"
                                                :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="rentCallerPhoneFast">
                                            </v-text-field>
                                        </template>
                                    </v-radio>
                                    <v-radio value="contact">
                                        <template v-slot:label>
                                            <v-text-field autocomplete="new-password" label="Telefon Do Kontaktu"
                                                :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="rentClientPhoneFast">
                                            </v-text-field>
                                        </template>
                                    </v-radio>
                                    <v-radio value="other">
                                        <template v-slot:label>
                                            <v-text-field autocomplete="new-password" label="Inny Telefon"
                                                :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="otherPhoneFast">
                                            </v-text-field>
                                        </template>
                                    </v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-card-actions>
                            <v-btn text @click="detailsPhoneDialog = false, id=null">Anuluj</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn text @click="updateClientPhone()"> Zapisz </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
        </v-card>
    </v-menu>

</template>
<script>
import Swal from "sweetalert2";
const axios = require("axios");
const dayjs = require("dayjs");
export default {
    props: ['id','getEvents'],
    data() {
        return {  
            radiosFast: '',
            rentCallerPhoneFast: '',
            rentClientPhoneFast: '',
            otherPhoneFast: '',
            detailsPhoneDialog: false, 
            isOther:false,
        quickDetailsDialog:false,     
        dealNoInfo:'',
        deliveryProductAddrInfo: '',
        transportProductAddrInfo:'',
        clientPhoneInfo: '',
        callerPhoneInfo: '',        
        noteInfo: '',
        noteInfoDriver: '',
        updateDate: '',
        createdDate:'',
        productsStr: '',
        quickEditDataStart:'',
        quickEditTimeStart:'',
        quickEditTimeEnd:'',
        userQuick:null,
        usersQuick:[],
        quickNote:'',  
        showTransportAddInfo:'',
        warehousesQuick:[],
        warehouseQuick:null
       }
    },
    async created(){
    },
    methods:{
        async initData(){
            this.showTransportAddInfo= '',
            this.isOther = false;
            this.quickDetailsDialog=false     
            this.dealNoInfo=''
            this.deliveryProductAddrInfo= ''
            this.transportProductAddrInfo=''
            this.clientPhoneInfo= ''
            this.callerPhoneInfo= ''        
            this.noteInfo= ''
            this.noteInfoDriver= ''
            this.updateDate= ''
            this.createdDate = ''
            this.productsStr= ''
            this.quickEditDataStart=''
            this.quickEditTimeStart=''
            this.quickEditTimeEnd=''
            this.userQuick=null
            this.usersQuick=[]
            this.quickNote='',
            this.warehousesQuick=[]
            this.warehouseQuick=null
        },
        async initDataPhone(){
            this.radiosFast = null
            this.rentCallerPhoneFast = ''
            this.rentClientPhoneFast = ''
            this.otherPhoneFast = ''
        },
        async toggleLoading(type) {
            const toggle = type;
            localStorage.setItem("loading", toggle);
            window.dispatchEvent(
                new CustomEvent("toggleLoading", {
                    detail: {
                        loading: localStorage.getItem("loading"),
                        overlay: localStorage.getItem("loading"),
                    },
                })
            );
        },         
        async showForm(){
            this.initData()
            this.quickDetailsDialog = true
            await this.getWarehouse();
            await this.getCurrentEvent();
        },
        async getWarehouse() {
            await this.toggleLoading(true);
            try {
                let warehouse = await axios({
                    method: "get",
                    url: process.env.VUE_APP_ROOT_API + `/warehouse?sort=${'id'}&direction=${false}&page=${1}&limit=${100}`,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                if (warehouse.data.data.items.length > 5) {
                    warehouse.data.data.items.unshift(warehouse.data.data.items.splice(4, 1)[0]);
                    warehouse.data.data.items.push(warehouse.data.data.items.splice(4, 1)[0]);
                }
                this.warehousesQuick = warehouse.data.data.items;
                this.warehouseQuick = warehouse.data.data.items[0].id                
                await this.getUsersSelectQuick()
            } catch (e) {
                console.log(e);
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            await this.toggleLoading(false);
        },        
        async updateClientPhone() {
            await this.toggleLoading(true);
            try {
                await axios({
                    method: "patch",
                    url:
                        process.env.VUE_APP_ROOT_API +
                        "/rents/update-phones-event/" +
                        this.id,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    data: {
                        selectedPhone: this.radiosFast,
                        callerPhone: this.rentCallerPhoneFast,
                        clientPhone: this.rentClientPhoneFast,
                        otherPhone: this.otherPhoneFast,
                    }
                })
                this.detailsPhoneDialog = false
                this.initDataPhone()
                await this.getCurrentEvent();
            } catch (e) {
                console.log(e)
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            await this.toggleLoading(false);
        },        
        async getRentDetails(){
            await this.toggleLoading(true);
            const rent = await axios({
                method: "get",
                url:
                    process.env.VUE_APP_ROOT_API +
                    "/rents/get-for-event/" +
                    this.id,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });  
            this.detailsPhoneDialog = true 
            this.rentClientPhoneFast = rent.data.data[0].clientPhone     
            this.otherPhoneFast = rent.data.data[0].otherPhone     
            this.rentCallerPhoneFast = rent.data.data[0].callerPhone     
            this.radiosFast = rent.data.data[0].selectedPhone
            await this.toggleLoading(false);           
        },
        async getCurrentEvent(){
            const event = await axios({
                method: "get",
                url: process.env.VUE_APP_ROOT_API + "/events/" + this.id,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            const rent = await axios({
                method: "get",
                url:
                    process.env.VUE_APP_ROOT_API +
                    "/rents/get-for-event/" +
                    this.id,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });    
            if(event.data.data.eventType!='other'){
                this.isOther = false
                this.noteInfo = event.data.data.modNote
                this.quickNote = event.data.data.modNote
                this.noteInfoDriver = event.data.data.deliveryNote
                this.warehouseQuick= event.data.data.warehouse.id
                await this.getUsersSelectQuick()
                this.userQuick = event.data.data.user.id;
                
                this.quickEditTimeStart = dayjs(event.data.data.startDate).format(
                    "HH:mm"
                );
                this.quickEditDataStart = new Date(event.data.data.startDate)
                    .toISOString()
                    .substr(0, 10);
                this.updateDate = dayjs(event.data.data.updated_at).format('YYYY-MM-DD HH:mm')
                const offset = new Date().getTimezoneOffset();
                this.createdDate = dayjs(event.data.data.created_at).add(Math.abs(offset / 60), 'hours').format('YYYY-MM-DD HH:mm')
                this.quickEditTimeEnd = dayjs(event.data.data.endDate).format("HH:mm");
                this.dealNoInfo = rent.data.data[0].dealNo ? rent.data.data[0].dealNo : 'Brak';
                this.clientPhoneInfo = rent.data.data[0].clientPhone ? rent.data.data[0].clientPhone : 'Brak'
                switch(rent.data.data[0].selectedPhone){
                    case 'contact':
                        this.clientPhoneInfo = rent.data.data[0].clientPhone ? rent.data.data[0].clientPhone : 'Brak'
                    break;
                    case 'caller':
                        this.clientPhoneInfo = rent.data.data[0].callerPhone ? rent.data.data[0].callerPhone : 'Brak'
                        break;   
                    case 'other':
                        this.clientPhoneInfo = rent.data.data[0].otherPhone ? rent.data.data[0].otherPhone : 'Brak'
                        break;                                                
                }
                this.callerPhoneInfo = rent.data.data[0].callerPhone ? rent.data.data[0].callerPhone : 'Brak'                
                this.deliveryProductAddrInfo = rent.data.data[0].productAddress + ' Mieszkanie: ' + rent.data.data[0].productAddressApt
                this.showTransportAddInfo = rent.data.data[0].transportAddress ? 'Adres Transportu: ':''
                this.transportProductAddrInfo = rent.data.data[0].transportAddress ? (this.showTransportAddInfo + rent.data.data[0].transportAddress + ' Mieszkanie: ' + rent.data.data[0].transportAddressApt) : ''
                let productsStr = ''
                this.productsStr = ''

                if (rent.data.data.length) {
                    rent.data.data.forEach((item) => {
                        if (item.products.name == 'Łóżko') {
                            if (item.isMateracPianka) {
                                productsStr = productsStr + item.products.name + '*, '
                            } else {
                                productsStr = productsStr + item.products.name + ', '
                            }
                        } else {
                            productsStr = productsStr + item.products.name + ', '
                        }
                    })
                }
                this.productsStr = productsStr   
            }else{
                this.clientPhoneInfo = event.data.data.phone
                this.isOther = true
                this.noteInfo = event.data.data.modNote
                this.quickNote = event.data.data.modNote
                this.noteInfoDriver = event.data.data.deliveryNote
                this.warehouseQuick = event.data.data.warehouse.id
                await this.getUsersSelectQuick()
                this.userQuick = event.data.data.user.id;
                this.quickEditTimeStart = dayjs(event.data.data.startDate).format(
                    "HH:mm"
                );
                this.quickEditDataStart = new Date(event.data.data.startDate)
                    .toISOString()
                    .substr(0, 10);
                this.updateDate = dayjs(event.data.data.updated_at).format('YYYY-MM-DD HH:mm')
                                const offset = new Date().getTimezoneOffset();
                this.createdDate = dayjs(event.data.data.created_at).add(Math.abs(offset / 60), 'hours').format('YYYY-MM-DD HH:mm')
                this.quickEditTimeEnd = dayjs(event.data.data.endDate).format("HH:mm");                
            }      
        },
        async showFullForm(){
            this.$emit('showFullDetails',this.id)
        },
        async getUsersSelectQuick(){
            await this.toggleLoading(true);
            this.userQuick = null;
            try {
                let users = await axios({
                    method: "get",
                    url:
                        process.env.VUE_APP_ROOT_API +
                        "/warehouse/user-list/" +
                        this.warehouseQuick,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                const uids = [1, 2, 5, 6, 8, 17]                
                users.data.data.forEach((item) => {
                    item.fullName = item.firstName + " " + item.lastName;
                });
                users.data.data = users.data.data.filter(function (item) {
                    return !uids.includes(item.id)
                })
                this.usersQuick = users.data.data;
            } catch (e) {
                console.log(e);
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            await this.toggleLoading(false);          
        },
        async deleteEvent(){
            Swal.fire({
                title: "Napewno Usunąć?",
                text: "",
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Tak",
                cancelButtonText: "Anuluj",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await this.toggleLoading(true);
                        await axios({
                            method: "delete",
                            url:
                                process.env.VUE_APP_ROOT_API +
                                "/events/" +
                                this.id,
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("token"),
                            },
                            data: {
                                startDate:
                                    dayjs(this.quickEditDataStart).format("YYYY-MM-DD") +
                                    " " +
                                    this.quickEditTimeStart,
                                endDate:
                                    dayjs(this.quickEditDataStart).format("YYYY-MM-DD") +
                                    " " +
                                    this.quickEditTimeEnd,
                                user: this.userQuick,
                            },
                        });
                        await this.getEvents();
                        await this.toggleLoading(false);
                    } catch (e) {
                        console.log(e);
                        await this.toggleLoading(false);
                        Swal.fire("Błąd", e.response.statusText || "", "error");
                    }
                }
            })
        },
        async updateEventFast(){
            await this.toggleLoading(true);
            try {
                await axios({
                    method: "patch",
                    url:
                        process.env.VUE_APP_ROOT_API +
                        "/events/update-quick/" +
                        this.id,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    data: {
                        startDate:
                            dayjs(this.quickEditDataStart).format("YYYY-MM-DD") +
                            " " +
                            this.quickEditTimeStart,
                        endDate:
                            dayjs(this.quickEditDataStart).format("YYYY-MM-DD") +
                            " " +
                            this.quickEditTimeEnd,
                        user: this.userQuick,
                        warehouse:this.warehouseQuick,
                        modNote: this.quickNote,
                    },
                });
                this.quickDetailsDialog = false;
                await this.getEvents();
            } catch (e) {
                console.log(e);
                Swal.fire("Błąd", e.response.statusText || "", "error");
            }
            await this.toggleLoading(false);
        },
        async duplicateEvent(){
            const event = await axios({
                method: "get",
                url: process.env.VUE_APP_ROOT_API + "/events/" + this.id,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            this.$emit('duplicateEvent',event)
        }
    }
}
</script>
