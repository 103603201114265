<template>
  <v-container>
  <v-data-table
        v-model="selected" 
        :headers="headers" 
        :items="rents" 
        class="elevation-1" 
         :search="search"
        :single-select="singleSelect"
          show-select :options.sync="options" 
          :server-items-length="totalRecords" 
          :loading="loading" 
          item-key="id"
  >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Aktywni Klienci</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-btn class="primary" @click="createMultipleReturn()">Utwórz Odbiór Z Zaznaczonych</v-btn>
            </v-toolbar>
            <div>
                      <v-text-field v-model="search" label="Szukaj" class="mx-4"></v-text-field>
            </div>
          </template>
          <template v-slot:item.delAction="{ item }">
            
            <v-btn v-if="item.showDeleteButton" class="error" @click="cancelReturn(item.id)">
              Cofnij Odbiór
            </v-btn>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn class="success" @click="getDeliveryCost(item.id)">
              <v-icon small class="pt-1">mdi-truck-delivery</v-icon>
            </v-btn>
            <v-btn class="primary" @click="showDialog(item.id)">
              <v-icon small class="pt-1">mdi-plus</v-icon>
            </v-btn>
            <v-btn class="seconadary" @click="getRentDetails(item.id)">
              <v-icon small class="pt-1">mdi-pencil</v-icon>
            </v-btn>
            <v-btn class="warning" @click="getRentDetails(item.id,true)">
              <v-icon small class="pt-1">mdi-cellphone-cog</v-icon>
            </v-btn>
            <v-btn class="warning" @click="showAppendDialog(item.id)">
              <v-icon small class="pt-1">mdi-cart-plus</v-icon>
            </v-btn>
            <v-btn class="error" @click="deleteRent(item.id)">
              <v-icon small class="pt-1">mdi-delete</v-icon>
            </v-btn>
          </template>
</v-data-table>
<v-dialog v-model="detailsDialog" fullscreen hide-overlay>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="detailsDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Edycja Danych</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      
        <v-form>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field label="Numer Umowy" :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="dealNoRent"
                @input="dealNoRent = dealNoRent.toUpperCase()" autocomplete="new-password">
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field label="Imię Wypożyczającego" :rules="[(v) => !!v || 'Uzupełnij Pole']"
                @input="rentFirstName = rentFirstName.toUpperCase()" v-model="rentFirstName" autocomplete="new-password">
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field @input="rentLastName = rentLastName.toUpperCase()" label="Nazwisko Wypożyczającego"
                :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="rentLastName" autocomplete="new-password">
              </v-text-field>
            </v-col>
            <v-col cols="8">
              <v-text-field disabled label="Adres Klienta" v-model="clientAddress"></v-text-field>
        
            </v-col>
            <v-col cols="4">
              <v-btn class="primary" style="width:100%;" @click="showClientAddres =!showClientAddres">Zmień</v-btn>
            </v-col>
            <v-col cols="12" v-if="showClientAddres">
              <vuetify-google-autocomplete id="map1" required autocomplete="new-password" append-icon="mdi-search"
                classname="form-control" placeholder="Adres Zameldowania" ref="addr" v-model="rentClientAddress"
                v-on:placechanged="getAddressData" country="pl">
              </vuetify-google-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-text-field label="Numer Mieszkania - Zameldowanie" v-model="rentClientAddressApt"></v-text-field>
            </v-col>
            <v-col cols="8">
              <v-text-field disabled label="Adres Sprzętu" v-model="productAddress"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-btn class="primary" style="width:100%;" @click="showproductAddress=!showproductAddress">Zmień</v-btn>
            </v-col>
            <v-col cols="12" v-if="showproductAddress">
              <vuetify-google-autocomplete id="map2" required append-icon="mdi-search" autocomplete="new-password"
                classname="form-control" placeholder="Nowy Adres Sprzętu" ref="addr" v-model="rentProductAddress"
                v-on:placechanged="getAddressData1" country="pl">
              </vuetify-google-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-text-field autocomplete="new-password" label="Numer Mieszkania - Sprzęt" v-model="rentProductAddressApt">
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-radio-group v-model="radios">
                <template v-slot:label>
                  <div><strong>Wybierz Telefon Do Kontaktu:</strong></div>
                </template>
                <v-radio value="caller">
                  <template v-slot:label>
                    <v-text-field autocomplete="new-password" label="Telefon Dzwoniącego"
                      :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="rentCallerPhone">
                    </v-text-field>
                  </template>
                </v-radio>
                <v-radio value="contact">
                  <template v-slot:label>
                    <v-text-field autocomplete="new-password" label="Telefon Do Kontaktu"
                      :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="rentClientPhone">
                    </v-text-field>
                  </template>
                </v-radio>
                <v-radio value="other">
                  <template v-slot:label>
                    <v-text-field autocomplete="new-password" label="Inny Telefon" :rules="[(v) => !!v || 'Uzupełnij Pole']"
                      v-model="otherPhone">
                    </v-text-field>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12">
              <v-text-field label="E-mail" autocomplete="new-password" v-model="rentMail">
              </v-text-field>
            </v-col>
              <v-col cols="12">
                <v-textarea label="Uwagi Księgowe" autocomplete="new-password" v-model="accData">
                </v-textarea>
              </v-col>
            <v-col cols="12">
              <v-select label="Produkt" v-model="currentProduct" :items="currentProducts" item-text="name"
                            item-value="id"></v-select>
            </v-col>
            <v-col cols="12" v-if="showMateracPiankaCheckbox">
              <v-checkbox v-model="isMateracPiankaEdit" label="Brak Pianki"></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
        </v-form>
        
        <v-card-actions>
          <v-btn text @click="detailsDialog = false">Anuluj</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="updateClientFast()"> Zapisz </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="detailsPhoneDialog">
      <v-card>
                <v-toolbar dark color="primary">
                  <v-btn icon dark @click="detailsPhoneDialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-toolbar-title>Edycja Danych</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-row>
                              <v-col cols="12">
                                <v-radio-group v-model="radiosFast">
                                  <template v-slot:label>
                                    <div><strong>Wybierz Telefon Do Kontaktu:</strong></div>
                                  </template>
                                  <v-radio value="caller">
                                    <template v-slot:label>
                                      <v-text-field autocomplete="new-password" label="Telefon Dzwoniącego" :rules="[(v) => !!v || 'Uzupełnij Pole']"
                                        v-model="rentCallerPhoneFast">
                                      </v-text-field>
                                    </template>
                                  </v-radio>
                                  <v-radio value="contact">
                                    <template v-slot:label>
                                      <v-text-field autocomplete="new-password" label="Telefon Do Kontaktu" :rules="[(v) => !!v || 'Uzupełnij Pole']"
                                        v-model="rentClientPhoneFast">
                                      </v-text-field>
                                    </template>
                                  </v-radio>
                                  <v-radio value="other">
                                    <template v-slot:label>
                                      <v-text-field autocomplete="new-password" label="Inny Telefon" :rules="[(v) => !!v || 'Uzupełnij Pole']"
                                        v-model="otherPhoneFast">
                                      </v-text-field>
                                    </template>
                                  </v-radio>
                                </v-radio-group>
                              </v-col>
                </v-row>
              <v-card-actions>
                <v-btn text @click="detailsPhoneDialog = false">Anuluj</v-btn>
                <v-spacer></v-spacer>
                <v-btn text @click="updateClientPhone()"> Zapisz </v-btn>
              </v-card-actions>
              </v-card>
    </v-dialog>
    <v-dialog v-model = "basketDialog">
            <v-card>
              <v-toolbar dark color="primary">
                <v-btn icon dark @click="basketDialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Dodawanie Produktu</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
        <v-list subheader two-line>
          <v-list-item v-for="product in products" :key="product.id">
            <v-row>
              <v-col cols="8">
                <v-list-item-content>
                  <v-list-item-title v-text="product.name"></v-list-item-title>
                  <v-list-item-title :class="product.color" v-text="product.subtitle"></v-list-item-title>
                </v-list-item-content>
              </v-col>
              <v-col cols="4">
                <v-list-item-action class="text-center">
                  <v-list-item-title>
                    <v-text-field type="number" label="Ilość" placeholder="Ilość" :rules="[(v) => !!v || 'Uzupełnij Pole']"
                      v-model="qtyInput[product.id]">
                    </v-text-field>
                  </v-list-item-title>
                </v-list-item-action>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
        <div class="text-center">
          <v-btn class="success" @click="appendSelectedProducts()">Dodaj Produkty</v-btn>
        </div>
              </v-card>
    </v-dialog>
<v-dialog fullscreen hide-overlay v-model="dialog">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Tworzenie Wydarzenia</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container>
          <Calendar v-on:closeDialogCreate="closeDialogCreate"  :rentsIds="selected" ></Calendar>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import Swal from "sweetalert2";
import Calendar from "./Calendar.vue";
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";
const axios = require("axios");

const dayjs = require("dayjs");
export default {
  components: { Calendar, VuetifyGoogleAutocomplete },
  name: "Clients",
  data: () => ({
    showMateracPiankaCheckbox: false,
    isMateracPiankaEdit:false,
    radiosFast:'',
    rentCallerPhoneFast:'',
    rentClientPhoneFast:'',
    otherPhoneFast:'',
    detailsPhoneDialog:false,
    accData:'',
    currentProduct:null,
    currentProducts:[],
    currentRent:null,
    dealNoRent:'',
    rentFirstName: '',
    rentLastName: '',
    clientAddress: '',
    showClientAddres: false,    
    rentClientAddress: '',
    rentClientAddressHelper: '',
    rentClientAddressApt: '',
    productAddress: '',
    showproductAddress: false,
    rentProductAddress: '',
    rentProductAddressHelper: '',
    rentProductAddressApt: '',
    radios: '',
    rentCallerPhone: '',
    rentClientPhone: '',
    otherPhone: '',
    rentMail: '',
    // 
    search:'',
    totalRecords: 0,
    loading: false,    
    detailsDialog:false,
    options:{},
    dialog: false,
    singleSelect: false,
    selected: [],
    recordsAll:[],
    basketDialog:false,
    products:[],
    warehouse:null,
    qtyInput:[],
    headers: [
      {
        text: "Numer Umowy",
        align: "start",
        sortable: true,
        value: "dealNo",
      },
      {
        text: "Telefon",
        align: "start",
        sortable: false,
        value: "phone",
      },
      {
        text: "Adres",
        align: "start",
        sortable: true,
        value: "productAddress",
      }, 
      {
        text: "Nazwisko",
        align: "start",
        sortable: true,
        value: "lastName",
      },            
      {
        text: "Przedmiot",
        align: "start",
        sortable: false,
        value: "product",
      },
      {
        text: "Ważne do",
        align: "start",
        sortable: true,
        value: "paidToDate",
      }, 
      {
        text: "Utworzono Odbiór?",
        align: "start",
        sortable: false,
        value: "isReturnCreated",
      },           
      {
        text: "Uwagi księgowe",
        align: "start",
        sortable: true,
        value: "accData",
      },      
      { text: "", value: "actions", sortable: false },
      { text: "", value: "delAction", sortable: false },      
    ],
    rents: [],
  }),

  async created() {    
  },
  watch: {
    options: {
      async handler() {
        await this.initialize()
      },
      deep: true,
    },
    async search() {
      this.options.page = 1 
      await this.initialize()
    }
  },
  methods: {
    initFormData(){
      this.radiosFast = null
      this.rentCallerPhoneFast = ''
      this.rentClientPhoneFast = ''
      this.otherPhoneFast = ''
      this.currentRent=null
      this.dealNoRent=''
      this.rentFirstName= ''
      this.rentLastName= ''
      this.clientAddress= ''
      this.showClientAddres= false    
      this.rentClientAddress= ''
      this.rentClientAddressApt= ''
      this.productAddress= ''
      this.showproductAddress= false
      this.rentProductAddress= ''
      this.rentProductAddressApt= ''
      this.radios= ''
      this.rentCallerPhone= ''
      this.rentClientPhone= ''
      this.otherPhone= ''
      this.rentMail= ''
      this.accData = ''
      this.warehouse = null
      this.products = []
      this.basketDialog = false
      this.qtyInput = []
    },
    row_classes(item) {
      if (dayjs(item.expirationDate).diff(dayjs().format("YYYY-MM-DD")) < 0) {
        return "red";
      }
    },
    async closeDialogCreate(){
      await this.initialize()
    },
    async getDeliveryCost(id){      
      const currentRent = await axios({
        method: "get",
        url:
          process.env.VUE_APP_ROOT_API +
          "/rents/find-by-id/" +
          id,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });            
      alert('Koszt Dostawy: ' + (currentRent.data.data.events ? (currentRent.data.data.events.totalDelivery/100):'N/D'))
    },
    async showAppendDialog(id){
      this.basketDialog = true
      const currentRent = await axios({
        method: "get",
        url:
          process.env.VUE_APP_ROOT_API +
          "/rents/find-by-id/" +
          id,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      const prods = await axios({
        method: "get",
        url:
          process.env.VUE_APP_ROOT_API +
          "/products/get-in-warehouse/" +
          currentRent.data.data.products.id,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });  
      this.currentRent = currentRent.data.data.id  
      this.warehouse = prods.data.data[0].warehouse.id                   
      await this.getProducts(prods.data.data[0].warehouse.id)
    },
    async appendSelectedProducts(){
      await this.toggleLoading(true);
      try {      
      let productsId = [];      
      const products = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "/products/" + this.warehouse + `/?sort=${'id'}&direction=${false}&page=${1}&limit=${100}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });    
        products.data.data.items.forEach((item) => {
        if (this.qtyInput[item.id] > 0) {
          for (let a = 0; a < this.qtyInput[item.id]; a++) {
            productsId.push(item.id);
          }
        }        
        });
        await axios({
          method: "post",
          url:
            process.env.VUE_APP_ROOT_API +
            "/rents/copy",            
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: {
            rentedProductId: productsId,
            rentId: this.currentRent,
          },
        });        
        this.basketDialog = false
        this.initFormData()
        await this.initialize()
      }catch(e){
        console.log(e);
        Swal.fire("Błąd", e.response.statusText, "error");        
      }
      await this.toggleLoading(false);
    },
    async deleteRent(id) {
      try {
        Swal.fire({
          title: "Przenieść do archiwum?",
          text: "",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Tak",
          cancelButtonText: "Anuluj",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await axios({
              method: "patch",
              url: process.env.VUE_APP_ROOT_API + "/rents/mark-archived/" + id,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
              data: {
                status: false,
              },
            });
            await this.initialize();
          }
        })
      } catch (e) {
        Swal.fire("Błąd", e.response.statusText, "error");
      }
    },    
    async getProducts(id) {
      await this.toggleLoading(true);
      this.products = [];
      try {
        let products = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "/products/" + id + `/?sort=${'id'}&direction=${false}&page=${1}&limit=${100}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        const ids = products.data.data.items.map(item => item.id)
        let bed = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "/rents/rented-beds/" + ids.toString() + '/' + dayjs().format('YYYY-MM-DD HH:mm'),
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        if (id == 2 || id == 8) {
          const products1 = await axios({
            method: "get",
            url: process.env.VUE_APP_ROOT_API + "/products/" + (id == 2 ? 8 : 2) + `/?sort=${'id'}&direction=${false}&page=${1}&limit=${100}`,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          });
          const ids1 = products1.data.data.items.map(item => item.id)
          let bed1 = await axios({
            method: "get",
            url: process.env.VUE_APP_ROOT_API + "/rents/rented-beds/" + ids1.toString() + '/' + dayjs().format('YYYY-MM-DD HH:mm'),
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          });
          for (let a = 0; a < products.data.data.items.length; a++) {
            let rented = 0
            let rentIndex = bed.data.data.rents.find(it => it.id == products.data.data.items[a].id)
            let rentIndex1 = bed1.data.data.rents.find(it => it.id == products1.data.data.items[a].id);
            rented = (rentIndex ? parseInt(rentIndex.sumRents) : 0) + (rentIndex1 ? parseInt(rentIndex1.sumRents) : 0)
            let returns = 0
            let returnIndex = bed.data.data.returns.find(it => it.id == products.data.data.items[a].id)
            let returnIndex1 = bed1.data.data.returns.find(it => it.id == products1.data.data.items[a].id);
            returns = (returnIndex ? parseInt(returnIndex.sumReturns) : 0) + (returnIndex1 ? parseInt(returnIndex1.sumReturns) : 0)
            let returns_till = 0
            let returnTillIndex = bed.data.data.returns_till_date.find(it => it.id == products.data.data.items[a].id)
            let returnTillIndex1 = bed1.data.data.returns_till_date.find(it => it.id == products1.data.data.items[a].id);
            returns_till = (returnTillIndex ? parseInt(returnTillIndex.sumReturns) : 0) + (returnTillIndex1 ? parseInt(returnTillIndex1.sumReturns) : 0)
            products.data.data.items[a].subtitle = ` ${products.data.data.items[a].qty - rented + returns_till} | ${products.data.data.items[a].qty - rented + returns} | ${products.data.data.items[a].qty} | ${returns} | ${rented}`
            if ((products.data.data.items[a].qty - rented + returns) > 0) {
              products.data.data.items[a].color = "green";
            } else {
              products.data.data.items[a].color = "red";
            } 
          }
        } else {
          products.data.data.items.forEach((item) => {
            let rented = 0
            let rentIndex = bed.data.data.rents.find(it => it.id == item.id);
            if (rentIndex) {
              rented = parseInt(rentIndex.sumRents)
            }
            let returns = 0
            let returnIndex = bed.data.data.returns.find(it => it.id == item.id);
            if (returnIndex) {
              returns = parseInt(returnIndex.sumReturns)
            }
            let returns_till = 0
            let returnTillIndex = bed.data.data.returns_till_date.find(it => it.id == item.id);
            if (returnTillIndex) {
              returns_till = parseInt(returnTillIndex.sumReturns)
            }
            item.subtitle = ` ${item.qty - rented + returns_till} | ${item.qty - rented + returns} | ${item.qty} | ${returns} | ${rented}`
            if ((item.qty - rented + returns) > 0) {
              item.color = "green";
            } else {
              item.color = "red";
            }
          });
        }
        this.products = products.data.data.items;
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      await this.toggleLoading(false);
    },     
    async toggleLoading(type) {
      const toggle = type;
      localStorage.setItem("loading", toggle);
      window.dispatchEvent(
        new CustomEvent("toggleLoading", {
          detail: {
            loading: localStorage.getItem("loading"),
            overlay: localStorage.getItem("loading"),
          },
        })
      );
    },
    async getProductsForWarehouseRent(id){
      const prods = await axios({
        method: "get",
        url:
          process.env.VUE_APP_ROOT_API +
          "/products/get-in-warehouse/" +
          id,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }); 
      this.currentProducts = prods.data.data
    },
    async getRentDetails(id,isNew) {
      await this.toggleLoading(true);
      const currentRent = await axios({
        method: "get",
        url:
          process.env.VUE_APP_ROOT_API +
          "/rents/find-by-id/" +
          id,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });  
      await this.getProductsForWarehouseRent(currentRent.data.data.products.id)
      this.currentProduct = currentRent.data.data.products.id
      if(currentRent.data.data.products.name == 'Łóżko'){
        this.showMateracPiankaCheckbox = true
        this.isMateracPiankaEdit = currentRent.data.data.isMateracPianka
      }else{
        this.showMateracPiankaCheckbox = false
      }      
      this.currentRent = id  
      this.accData = currentRent.data.data.accData  
      this.dealNoRent = currentRent.data.data.dealNo
      this.rentFirstName = currentRent.data.data.firstName
      this.rentLastName = currentRent.data.data.lastName
      this.clientAddress = currentRent.data.data.clientAddress
      this.productAddress = currentRent.data.data.productAddress
      this.rentClientAddressApt = currentRent.data.data.clientAddressApt
      this.rentProductAddressApt = currentRent.data.data.productAddressApt
      this.radios = currentRent.data.data.selectedPhone
      this.rentCallerPhone = currentRent.data.data.callerPhone
      this.rentClientPhone = currentRent.data.data.clientPhone
      this.otherPhone = currentRent.data.data.otherPhone
      this.rentMail = currentRent.data.data.mail
      if(isNew){
        this.detailsPhoneDialog = true
        this.radiosFast = currentRent.data.data.selectedPhone
        this.rentCallerPhoneFast = currentRent.data.data.callerPhone
        this.rentClientPhoneFast = currentRent.data.data.clientPhone
        this.otherPhoneFast = currentRent.data.data.otherPhone  
      }else{
        this.detailsDialog = true 
      }  
      await this.toggleLoading(false);
    },    
    getAddressData: async function (addressData) {
      await this.toggleLoading(true);
      try {        
        this.rentClientAddressHelper = addressData;
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      await this.toggleLoading(false);
    },
    getAddressData1: async function (addressData) {
      await this.toggleLoading(true);
      try {        
        this.rentProductAddressHelper = addressData;
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      await this.toggleLoading(false);
    },          
    createMultipleReturn() {      
      this.dialog = true;         
      setTimeout(() => {
        // document.querySelector(".fc-timeGridFourDay-button").click();        
      }, 500);
    },
    showDialog(id) {
      this.selected = [{ id: id }];
      this.dialog = true;
      setTimeout(() => {
        // document.querySelector(".fc-timeGridFourDay-button").click();
        console.log("click");
      }, 500);      
    },
    async updateClientPhone(){
      await this.toggleLoading(true);
      try { 
        await axios({
          method: "patch",
          url:
            process.env.VUE_APP_ROOT_API +
            "/rents/update-phones/" +
            this.currentRent,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data:{
            selectedPhone: this.radiosFast,
            callerPhone: this.rentCallerPhoneFast,
            clientPhone: this.rentClientPhoneFast,
            otherPhone: this.otherPhoneFast,
          }
        })
        this.detailsPhoneDialog = false
        this.initFormData()
        await this.initialize();
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      await this.toggleLoading(false);
    },
    async updateClientFast() {
      await this.toggleLoading(true);
      try {
        await axios({
          method: "patch",
          url:
            process.env.VUE_APP_ROOT_API +
            "/rents/update-deal/" +
            this.currentRent,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: {
            dealNo:this.dealNoRent ,
            accData: this.accData,
            firstName: this.rentFirstName,
            lastName: this.rentLastName,
            clientAddress: this.rentClientAddress,
            cllientAddressGeoLat: this.rentClientAddressHelper.latitude,
            cllientAddressGeoLng: this.rentClientAddressHelper.longitude,
            showClientAddres: this.showClientAddres,
            showproductAddress: this.showproductAddress,
            productAddress: this.rentProductAddress,
            productAddressGeoLat: this.rentProductAddressHelper.latitude,
            productAddressGeoLng: this.rentProductAddressHelper.longitude, 
            clientAddressApt: this.rentClientAddressApt,
            productAddressApt: this.rentProductAddressApt,            
            selectedPhone: this.radios,
            callerPhone: this.rentCallerPhone,
            clientPhone: this.rentClientPhone,
            otherPhone: this.otherPhone,
            mail: this.rentMail,
            product:this.currentProduct,
            isMateracPianka: this.isMateracPiankaEdit
          },
        });
        this.initFormData()
        this.detailsDialog = false
        await this.initialize();
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      await this.toggleLoading(false);
    },  
    async cancelReturn(id) {
      await this.toggleLoading(true);
      try {
        Swal.fire({
          title: "Napewno Cofnąć Odbiór?",
          text: "",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Tak",
          cancelButtonText: "Anuluj",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await axios({
              method: "delete",
              url: process.env.VUE_APP_ROOT_API + "/rents/cancel-calendar-event" + id,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            });
            await this.initialize();
          }
        });
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      await this.toggleLoading(false);
    },       
    //
    async initialize() {
      this.loading = true  
      this.rents = []       
      let { sortBy, sortDesc, page, itemsPerPage } = this.options
      try {
        let rents = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/rents/get-table?sort=${sortBy.length?sortBy:'dealNo'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}&search=${this.search}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        rents.data.data.items.forEach((item) => { 
          item.showDeleteButton = item.isReturnCreated
          if(item.isReturnCreated){
            item.isReturnCreated = 'Utworzony Odbiór: ' + item.returns ? (dayjs(item.returns.startDate).format('DD-MM-YYYY HH:mm') + ' ' + item.returns.user.firstName + ' ' + item.returns.user.lastName) : ''
          } else{
            item.isReturnCreated = 'Nie'
          }               
          if(item.selectedPhone){
            switch (item.selectedPhone){
              case 'caller':
                item.phone = item.callerPhone
                break;
              case 'other':
                item.phone = item.otherPhone
                break; 
              case 'contact':
                item.phone = item.clientPhone
                break;                                
            }
          }else{
            item.phone = item.clientPhone
          }
          item.paidToDate = dayjs(item.paidToDate).format("YYYY-MM-DD")
          item.product = item.products.name + (item.isMateracPianka ? '*' : '')        });
        this.rents = rents.data.data.items
        this.itemsPerPage = rents.data.data.meta.itemsPerPage
        this.page = rents.data.data.meta.currentPage
        this.totalRecords = rents.data.data.meta.totalItems         
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      this.loading = false
    },
  },
};
</script>
<style>
.fc {
  height: 650px !important;
}
.fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 2px 4px;
}
.fc-toolbar-chunk {
  text-align: center;
}
.fc .fc-toolbar {
  display: block;
}
</style>