<template>
    <v-dialog transition="dialog-bottom-transition" fullscreen persistent hide-overlay v-model="fullDetailsDialog">
        <v-toolbar dark color="primary">
            <v-btn icon dark @click="fullDetailsDialog = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Szczegóły</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-card>
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <v-sheet color="brown darken-4" rounded="rounded" class="mx-auto">
                            Numer Umowy: {{ dealNoInfo }}<br />
                            Adres Sprzętu: <a :href="'http://maps.google.com/?q='+deliveryProductAddrInfo" target="_blank">{{
                                deliveryProductAddrInfo }}</a><br />
                            Telefon Dzwoniącego: <a :href="('tel: ' + callerPhoneInfo)">{{ callerPhoneInfo }}</a><br />
                            Telefon Do Kontaktu: <a :href="('tel: ' + clientPhoneInfo)">{{ clientPhoneInfo }}</a><br />
                            Uwagi: {{ noteInfo }} <br />
                            Uwagi Kierowcy: {{ noteInfoDriver }}<br />
                            Produkt: {{ productsStr }}
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
            <v-stepper v-model="e1">
                <v-stepper-step step="6" editable>
                    Formularz Serwisu
                </v-stepper-step>
                <v-stepper-content step="6">
                    <v-form>
                        <v-row>
                            <v-col cols="12">
                                <v-select label="Typ Serwisu" v-model="serviceType" :items="serviceTypes" item-text="name"
                                    :rules="[(v) => !!v || 'Uzupełnij Pole']" item-value="id"></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field label="Kilometrówka" type="number" autocomplete="new-password"
                                    v-model="totalDeliveryService" :rules="[(v) => v>=0 || 'Uzupełnij Pole']"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field label="Koszt Serwisu" autocomplete="new-password" type="number" v-model="totalServiceCost"
                                    :rules="[(v) => v>=0 || 'Uzupełnij Pole']"></v-text-field>
                            </v-col>
                        <v-col cols="12">
                            <v-select item-text="name" item-value="id" v-model="paymentType" :items="paymentTypes" label="Rodzaj Płatności">
                            </v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-checkbox v-model="replacementCheckbox" label="Podmieniono sprzęt i stary nie nadaje się do użytku?"></v-checkbox>
                        </v-col>
                            <v-col cols="12"  class="text-center">
                                <v-textarea label="Notatka Pracownika" autocomplete="new-password" v-model="serviceNote">
                                </v-textarea>
                                <v-btn color="warning" @click="updateNote()">Aktualizuj Notatkę</v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                    <div>
                        <v-btn v-if="isAdmin" color="warning" class="float-center" @click="saveService(true)">
                            Edytuj
                        </v-btn>
                        <v-btn v-if="showButton" color="green" class="float-right" @click="saveService()">
                            Wykonano
                        </v-btn>                        
                    </div>
                </v-stepper-content>
            </v-stepper>
        </v-card>
    </v-dialog>
</template>
<script>
import Swal from "sweetalert2";
const axios = require("axios");
import * as dayjs from 'dayjs'
var isToday = require('dayjs/plugin/isToday')
dayjs.extend(isToday)
export default {
    props: ['records', 'id', 'warehouse', 'getEvents'],
    data() {
        return {
            replacementCheckbox:false,
            clientPhoneInfo: '',
            isAdmin: false,
            fullDetailsDialog:false,
            deliveryProductAddrInfo: '',
            dealNoInfo:'',
            callerPhoneInfo: '',
            noteInfo: '',
            noteInfoDriver: '',
            productsStr:'',            
            showButton:false,
            e1: 6,
            serviceType:null,
            serviceTypes: [
                {
                    id: "nd",
                    name: "Nieuzasadniony",
                },
                {
                    id: "own",
                    name: "Wewnętrzny",
                },
                {
                    id: "client",
                    name: "Wina Klienta",
                },
            ],
            totalDeliveryService:0,
            totalServiceCost:0,
            serviceNote:'', 
            paymentType: null,
            paymentTypes: [
                {
                    id: 'cash',
                    name: 'Gotówka'
                },                
                {
                    id: 'card',
                    name: 'Karta'
                },
                {
                    id: 'gate',
                    name: 'Sklep Online'
                },
                {
                    id: 'wire',
                    name: 'Przelew'
                },
            ]               
        }
    },
    created() {
        const role = localStorage.getItem("role")
        if (role == 'admin') {
            this.isAdmin = true
        } else {
            this.isAdmin = false
        }
    },    
    methods:{
        initData(){            
            this.fullDetailsDialog=false
            this.deliveryProductAddrInfo= ''
            this.dealNoInfo=''
            this.callerPhoneInfo= ''
            this.noteInfo= ''
            this.noteInfoDriver= ''
            this.productsStr=''            
            this.showButton=false
            this.e1= 6
            this.serviceType=null
            this.serviceTypes= [
                {
                    id: "nd",
                    name: "Nieuzasadniony",
                },
                {
                    id: "own",
                    name: "Wewnętrzny",
                },
                {
                    id: "client",
                    name: "Wina Klienta",
                }
            ]
            this.totalDeliveryService=0
            this.totalServiceCost=0
            this.serviceNote='' 
            this.paymentType= null
            this.replacementCheckbox = false,
            this.paymentTypes= [
                {
                    id: 'cash',
                    name: 'Gotówka'
                },                
                {
                    id: 'card',
                    name: 'Karta'
                },
                {
                    id: 'gate',
                    name: 'Sklep Online'
                },
                {
                    id: 'wire',
                    name: 'Przelew'
                },
            ]  
        },
        async toggleLoading(type) {
            const toggle = type;
            localStorage.setItem("loading", toggle);
            window.dispatchEvent(
                new CustomEvent("toggleLoading", {
                    detail: {
                        loading: localStorage.getItem("loading"),
                        overlay: localStorage.getItem("loading"),
                    },
                })
            );
        },   
        async updateNote() {
            await this.toggleLoading(true);
            try {
                await axios({
                    method: "patch",
                    url:
                        process.env.VUE_APP_ROOT_API +
                        "/events/update-note/" +
                        this.id,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    data: {
                        deliveryNote: this.serviceNote,
                    },
                });
                Swal.fire("Sukces", "Zapisano", "success");
            } catch (e) {
                console.log(e);
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            await this.toggleLoading(false);
        },                  
        async showServiceForm(evt){
            this.fullDetailsDialog = true;
            this.serviceNote = evt.deliveryNote
            this.paymentType = evt.paymentType
            this.serviceType = evt.serviceType
            this.noteInfoDriver = evt.deliveryNote
            this.noteInfo = evt.modNote
            this.totalServiceCost = evt.totalSell/100
            this.totalDeliveryService = evt.totalDelivery/100
            this.showButton = !evt.isDone
            if (evt.isDone) {
                const role = localStorage.getItem("role")
                if (role != 'admin') {
                    if (dayjs(evt.startDate).isToday()) {
                        this.isAdmin = true
                    }
                }
            }              
            const rent = await axios({
                method: "get",
                url:
                    process.env.VUE_APP_ROOT_API +
                    "/rents/get-for-event/" +
                    this.id,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }); 
            let productsStr = ''
            this.productsStr = ''

            if (rent.data.data.length) {
                rent.data.data.forEach((item) => {
                    if (item.products.name == 'Łóżko') {
                        if (item.isMateracPianka) {
                            productsStr = productsStr + item.products.name + '*, '
                        } else {
                            productsStr = productsStr + item.products.name + ', '
                        }
                    } else {
                        productsStr = productsStr + item.products.name + ', '
                    }
                })
            }
            this.productsStr = productsStr
            this.dealNoInfo = rent.data.data[0].dealNo
            this.deliveryProductAddrInfo = rent.data.data[0].productAddress + ' Mieszkanie: ' + rent.data.data[0].productAddressApt             
            this.callerPhoneInfo = rent.data.data[0].callerPhone
            switch (rent.data.data[0].selectedPhone) {
                case 'contact':
                    this.clientPhoneInfo = rent.data.data[0].clientPhone ? rent.data.data[0].clientPhone : 'Brak'
                    break;
                case 'caller':
                    this.clientPhoneInfo = rent.data.data[0].callerPhone ? rent.data.data[0].callerPhone : 'Brak'
                    break;
                case 'other':
                    this.clientPhoneInfo = rent.data.data[0].otherPhone ? rent.data.data[0].otherPhone : 'Brak'
                    break;
            }                                              
        },
    async saveService(isUpdate = false) {            
            if (!this.serviceType) {
                Swal.fire("Błąd", 'Wybierz Typ Serwisu', "error");
                return false
            }
            if (this.totalDeliveryService < 0) {
                Swal.fire("Błąd", 'Uzupełnij Kilometrówkę', "error");
                return false
            }
            if (this.totalServiceCost < 0) {
                Swal.fire("Błąd", 'Uzupełnij koszt serwisu', "error");
                return false
            }
            await this.toggleLoading(true);
            try {
                await axios({
                    method: "patch",
                    url:
                        process.env.VUE_APP_ROOT_API +
                        "/events/update-service/" +
                        this.id,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    data: {
                        deliveryNote: this.serviceNote,
                        totalSell: parseFloat(this.totalServiceCost),
                        totalDelivery: parseFloat(this.totalDeliveryService),
                        serviceType: this.serviceType,
                        isOnlyUpdate: isUpdate,
                        paymentType: this.paymentType,
                        isReplacement: this.replacementCheckbox                    
                    },
                });
                this.initData()
                await this.getEvents();
                this.fullDetailsDialog = false;
            } catch (e) {
                console.log(e);
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            await this.toggleLoading(false);
        },
    }
    }
</script>