<template>
    <v-container>
        <v-row>
                    <v-col cols="12" >
                        <v-select v-model="selectedproductsService" :items="itemsToService" @change="getClient()" item-text="rentName"
                            item-value="id" chips attach label="Przedmiot którego dotyczy serwis">
                            <template #item="{ item }">
                                <span v-if="item.color == 'red'" style="color:white; background:red">{{ item.rentName }}</span>
                                <span v-if="item.color == 'green'" style="color:white; background:green">{{ item.rentName }}</span>
                            </template>
                            <template v-slot:prepend-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-text-field v-model="searchTerm" placeholder="Szukaj" @input="searchItems">
                                        </v-text-field>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="12" >
                        <v-text-field label="Adres Sprzętu" disabled v-model="serviceAddHelper"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field label="Telefon Dzwoniącego" v-model="serviceCallerPhone" required autocomplete="new-password"
                            :rules="[(v) => !!v || 'Uzupełnij Pole']"></v-text-field>
                    </v-col>
    </v-row>
    </v-container>
</template>
<script>
import Swal from "sweetalert2";
const axios = require("axios");
const dayjs = require("dayjs");
export default {       
    props: ['user', 'warehouse', 'getEvents', 'dialogCreate', 'eventNote', 'startDate', 'startTime', 'endTime', 'rentsIds'],
    data() {
        return {
            selectedproductsService:[],
            itemsToService:[],
            searchTerm:'',
            serviceAddHelper:'',
            serviceCallerPhone:'',     
        }
    },
    async created() {
        if (this.rentsIds) {
            let ids = [];
            let arrs = [];
            for (let a = 0; a < this.rentsIds.length; a++) {
                let products = await axios({
                    method: "get",
                    url:
                        process.env.VUE_APP_ROOT_API +
                        "/rents/find-by-id/" +
                        this.rentsIds[a].id,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                arrs.push({
                    rentName:
                        products.data.data.dealNo +
                        "-" +
                        (products.data.data.products.name) + (products.data.data.isMateracPianka ? '*' : ''),
                    id: products.data.data.id,
                });
                ids.push(products.data.data.id);
            }
            this.itemsToService = arrs[0];
            this.selectedproductsService = ids[0];
            await this.getClient()
        }
    },    
    methods:{
        async toggleLoading(type) {
            const toggle = type;
            localStorage.setItem("loading", toggle);
            window.dispatchEvent(
                new CustomEvent("toggleLoading", {
                detail: {
                    loading: localStorage.getItem("loading"),
                    overlay: localStorage.getItem("loading"),
                },
                })
            );
        },             
        async searchItems() {
        await this.toggleLoading(true);
        try {
            if (this.searchTerm.length) {
            let products = await axios({
                method: "get",
                url:
                process.env.VUE_APP_ROOT_API +
                "/rents/find-rents/" +
                this.searchTerm,
                headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            if(products.data.data){
                products.data.data.forEach((item) => {            
                    if (dayjs(item.paidToDate).diff(dayjs().format("YYYY-MM-DD")) < 0){
                    item.color = 'red'
                    }else{
                    item.color = 'green'
                    }
                    item.rentName =
                    item.dealNo +
                    "-" +
                        (item.products.name) + (item.isMateracPianka ? '*' : '') + ' Ważne: ' + dayjs(item.paidToDate).diff(dayjs().format("YYYY-MM-DD"), 'days') + (item.accData.length ? ('U: ' + item.accData) : '');
                });
                this.itemsToService = products.data.data;
            }
            }
        } catch (e) {
            console.log(e);
            Swal.fire("Błąd", e.response.statusText, "error");
        }
        await this.toggleLoading(false);
        },  
        async createService(){
            if (!this.serviceCallerPhone.trim().length) {
                Swal.fire("Błąd", 'Uzupełnij Telefon Dzwoniącego', "error");
                return false
            }
            if (!this.selectedproductsService) {
                Swal.fire("Błąd", 'Wybierz Produkt do Serwisu', "error");
                return false
            }
            await this.toggleLoading(true);
            try {
                const data = {
                    startDate:
                        dayjs(this.startDate).format("YYYY-MM-DD") +
                        " " +
                        this.startTime,
                    endDate:
                        dayjs(this.startDate).format("YYYY-MM-DD") + " " + this.endTime,
                    modNote: this.eventNote,
                    user: this.user[0],
                    eventType: "service",
                    warehouse: this.warehouse,                    
                    rentedProductId: this.selectedproductsService,
                    callerPhone: this.serviceCallerPhone,
                };
                await axios({
                    method: "post",
                    url: process.env.VUE_APP_ROOT_API + "/events/service",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    data: data,
                });
                this.$emit('closeDialogCreate')
                await this.getEvents();
            } catch (e) {
                console.log(e)
                Swal.fire("Błąd", e.response.statusText, "error");
            }
        },
        async getClient(){
                let products = await axios({
                    method: "get",
                    url:
                    process.env.VUE_APP_ROOT_API +
                    "/rents/find-by-id/" +
                    this.selectedproductsService,
                    headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }); 
            this.serviceAddHelper = products.data.data.productAddress + ' ' + products.data.data.productAddressApt                            
            this.serviceCallerPhone = products.data.data.callerPhone                         
        }               
    }    
}
</script>