<template>
    <v-container>
    <v-row>
                    <v-col cols="12">
                        <v-text-field label="Tytuł" v-model="otherTitle" autocomplete="new-password" required
                            :rules="[(v) => !!v || 'Uzupełnij Pole']"></v-text-field>
                    </v-col>
                            <v-col cols="12">
                                <v-text-field v-model="phone" label="Telefon" autocomplete="new-password"></v-text-field>
                            </v-col>
                    <v-col cols="12">
                        <v-text-field label="Wydatki" v-model="otherBuy" autocomplete="new-password" required>
                        </v-text-field>
                    </v-col>                    
                    <v-col cols="12">
                        <v-text-field label="Przychód" v-model="otherSell" autocomplete="new-password" required>
                        </v-text-field>
                    </v-col>
    </v-row>
    </v-container>
</template>
<script>
import Swal from "sweetalert2";
const axios = require("axios");
const dayjs = require("dayjs");
export default {
    props: ['user', 'warehouse', 'getEvents', 'dialogCreate', 'eventNote', 'startDate', 'startTime', 'endTime'],
    name:"CreateOtherForm",
    data() {
        return {
            otherTitle:'',
            otherBuy:0,
            phone:'',
            otherSell:0,
        }
    },
    methods:{
        async toggleLoading(type) {
            const toggle = type;
            localStorage.setItem("loading", toggle);
            window.dispatchEvent(
                new CustomEvent("toggleLoading", {
                    detail: {
                        loading: localStorage.getItem("loading"),
                        overlay: localStorage.getItem("loading"),
                    },
                })
            );
        },          
        async createOther(){
            if (!this.otherTitle.trim().length) {
                Swal.fire("Błąd", 'Uzupełnij Tytuł wydarzenia', "error");
                return false
            }
            await this.toggleLoading(true);
            const data = {
                startDate:
                    dayjs(this.startDate).format("YYYY-MM-DD") + " " + this.startTime,
                endDate:
                    dayjs(this.startDate).format("YYYY-MM-DD") + " " + this.endTime,
                title: this.otherTitle,
                totalBuy: this.otherBuy || 0,
                totalSell: this.otherSell || 0,
                warehouse: this.warehouse,
                phone: this.phone||'',
                user: this.user[0],
                eventType: "other",
                modNote: this.eventNote,
            };    
            try {
                await axios({
                    method: "post",
                    url: process.env.VUE_APP_ROOT_API + "/events/other",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    data: data,
                });
                this.$emit('closeDialogCreate')
                await this.getEvents();
            } catch (e) {
                console.log(e);
                Swal.fire("Błąd", e.response.statusText, "error");
            }                    
        }
    }
}
</script>