<template>
    <v-container>
        <v-row>
                    <v-col cols="12" >
                        <v-select v-model="rentType" :items="rentTypes" item-text="name" item-value="id" label="Rodzaj Wypozyczenia"
                            required :rules="[(v) => !!v || 'Uzupełnij Pole']"></v-select>
                    </v-col>
                    <v-col cols="12" >
                        <v-select item-text="name" item-value="id" v-model="companySelected" :items="companySelecteds" label="Polecenie">
                        </v-select>
                    </v-col>
                    <v-col cols="12" >
                        <v-text-field label="Telefon Dzwoniącego" autocomplete="new-password" v-model="callerPhone" required
                            :rules="[(v) => !!v || 'Uzupełnij Pole']"></v-text-field>
                    </v-col>
                    <v-col cols="12" >
                        <v-text-field label="Telefon Do Kontaktu" autocomplete="new-password" v-model="rentPhone">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" >
                        <vuetify-google-autocomplete id="map" required append-icon="mdi-search" classname="form-control"
                            placeholder="Adres Dostawy"  autocomplete="new-password" ref="addr" v-model="deliveryAddr"
                            v-on:placechanged="getAddressData" country="pl">
                        </vuetify-google-autocomplete>
                    </v-col>
                    <v-col cols="12" >
                        <v-text-field label="Numer Mieszkania" v-model="deliveryAddrAptCreate"></v-text-field>
                    </v-col>
                    <v-col  cols="12">
                        <v-text-field label="Ilość Kilometrów" autocomplete="new-password" v-model="kmRentLength" type="number"
                            :rules="[(v) => !!v || 'Uzupełnij Pole']"></v-text-field>
                    </v-col>
                    <v-col  cols="12">
                        <v-text-field label="Stawka za KM" value="2" type="number" v-model="rentPricePerKm" autocomplete="new-password"
                            :rules="[(v) => !!v || 'Uzupełnij Pole']"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox v-model="showTransport" label="Darmowa Dostawa?"> </v-checkbox>
                    </v-col>
                    <v-col cols="12" >
                        <h3>Produkty</h3>
                        <v-list subheader two-line>
                            <v-list-item v-for="product in products" :key="product.id">
                                <v-row>
                                    <v-col cols="6">
                                        <v-list-item-content>
                                            <v-list-item-title v-text="product.name"></v-list-item-title>
                                            <v-list-item-title :class="product.color" v-text="product.subtitle">
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-list-item-action class="text-center">
                                            <v-list-item-title>
                                                <v-text-field type="number" label="Ilość" placeholder="Ilość" autocomplete="new-password"
                                                    v-model="qtyInput[product.id]">
                                                </v-text-field>
                                            </v-list-item-title>
                                        </v-list-item-action>
                                    </v-col>
                                </v-row>
                            </v-list-item>
                        </v-list>
                    </v-col>
    </v-row>
    </v-container>
</template>
<script
  type="text/javascript"
  src="http://maps.google.com/maps/api/js?key=AIzaSyDGlgz880rqzKKuiWTugR3JsWRjCtjdH_g&sensor=false&libraries=places&language=pl-PL"
></script>
<script>
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";
import Swal from "sweetalert2";
const axios = require("axios");
const dayjs = require("dayjs");
export default {
    props:['createFromShop','shopPhone','shopMail','shopDeliveryCost','shopDeliveryAddress','shopStr','user','warehouse','getEvents','dialogCreate','eventNote','startDate', 'startTime', 'endTime','rentsId'],
    name:'CreateRentForm',
    components: {
        VuetifyGoogleAutocomplete, // make the <FullCalendar> tag available
    },    
    async created(){          
        if(this.createFromShop){                        
            this.deliveryAddr = this.shopDeliveryAddress
            this.callerPhone = this.shopPhone
            this.rentPhone = this.shopPhone
        }             
        await this.getCompany()
        await this.getProducts()
    },
    data() {
        return {
            rentType:'',
            rentTypes:[
            {
                id: "delivery",
                name: "Dostawa",
            },
            {
                id: "rent",
                name: "Wypożyczenie Osobiste",
            }],
            showTransport:false,
            companySelected:null,
            companySelecteds:[],
            callerPhone:'',
            rentPhone:'',
            deliveryAddr:'',
            deliveryAddrAptCreate:'',
            rentAddressHelper:'',
            kmRentLength:0,
            rentPricePerKm:2,
            products:[],
            qtyInput:[],        
        }
    },
    methods:{
        initData(){
            this.rentType=''
            this.rentTypes=[
            {
                id: "delivery",
                name: "Dostawa",
            },
            {
                id: "rent",
                name: "Wypożyczenie Osobiste",
            }]
            this.companySelected=null
            this.companySelecteds=[]
            this.rentPhone=''
            this.callerPhone=''
            this.deliveryAddr=''
            this.deliveryAddrAptCreate=''
            this.rentAddressHelper=''
            this.kmRentLength=0
            this.rentPricePerKm=2
            this.showTransport = false
            this.qtyInput=[]            
            this.products=[]
        },
        async duplicateData(data){
        await this.initData()
        await this.getCompany()
        await this.getProducts()            
            const rent = await axios({
                method: "get",
                url:
                process.env.VUE_APP_ROOT_API +
                "/rents/get-for-event/" +
                data.id,
                headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }); 
            if(data.eventType=='rent'){
                this.rentType = data.deliveryType
                this.companySelected = rent.data.data[0].company? rent.data.data[0].company.id:null          
                this.rentPhone = rent.data.data[0].rentPhone
                this.callerPhone = rent.data.data[0].callerPhone
                if(rent.data.data.selectedPhone){
                    switch (rent.data.data[0].selectedPhone) {
                        case 'contact':
                            this.rentPhone = rent.data.data[0].clientPhone ? rent.data.data[0].clientPhone : 'Brak'
                            break;
                        case 'caller':
                            this.rentPhone = rent.data.data[0].callerPhone ? rent.data.data[0].callerPhone : 'Brak'
                            break;
                        case 'other':
                            this.rentPhone = rent.data.data[0].otherPhone ? rent.data.data[0].otherPhone : 'Brak'
                            break;
                    }   
                }else{
                    this.rentPhone  = rent.data.data[0].clientPhone
                }                             
                this.deliveryAddr = rent.data.data[0].productAddress,     
                this.deliveryAddrAptCreate = rent.data.data[0].productAddressApt
            }
        },
        async createRent(){
          if(this.rentType != 'rent'){
            if(!this.deliveryAddr){
              console.log('Adres Dostawy nie jest wymagany')
              Swal.fire("Błąd", 'Uzupełnij Adres Dostawy', "error");
              return false
            }
            if(this.kmRentLength<0){
              Swal.fire("Błąd", 'Uzupełnij Ilość KM', "error");
              return false
            }
            if(this.rentPricePerKm<0){
              Swal.fire("Błąd", 'Uzupełnij Stawkę za KM', "error");
              return false
            }                          
          }
          if(!this.rentType){
            Swal.fire("Błąd", 'Uzupełnij Rodzaj Wypożyczenia', "error");
            return false
          }
          if(!this.callerPhone.trim().length){
            Swal.fire("Błąd", 'Uzupełnij Telefon Dzwoniącego', "error");
            return false
          }                                        
          let productsId = [];
          try {
            let products = await axios({
              method: "get",
              url: process.env.VUE_APP_ROOT_API + "/products/" + this.warehouse +`/?sort=${'id'}&direction=${false}&page=${1}&limit=${100}`,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            });
            products.data.data.items.forEach((item) => {
              if (this.qtyInput[item.id] > 0) {
                for (let a = 0; a < this.qtyInput[item.id]; a++) {
                  productsId.push(item.id);
                }
              }
            });
            if(!productsId.length){
                Swal.fire("Błąd", 'Ilość produktu', "error");
                return false
            }            
            await this.toggleLoading(true);
            const data = {
              startDate:
                dayjs(this.startDate).format("YYYY-MM-DD") +
                " " +
                this.startTime,
              endDate:
                dayjs(this.startDate).format("YYYY-MM-DD") + " " + this.endTime,
              modNote: this.eventNote,
              kmCount: this.kmRentLength||0,
              pricePerKmRent: this.rentPricePerKm||2,
              deliveryType: this.rentType,
              showDeliveryCost:this.showTransport,
              eventType: "rent",
              user: this.user[0],
              callerPhone: this.callerPhone,
              rentPhone:this.rentPhone,              
              cllientAddress:this.deliveryAddr,
              cllientAddressApt:this.deliveryAddrAptCreate,
              cllientAddressGeoLat:this.rentAddressHelper.latitude,
              cllientAddressGeoLng:this.rentAddressHelper.longitude,
              productAddress:this.deliveryAddr,
              productAddressGeoLat:this.rentAddressHelper.latitude,
              productAddressGeoLng:this.rentAddressHelper.longitude, 
              productAddressApt:this.deliveryAddrAptCreate,             
              company:this.companySelected ,
              mail:this.shopMail?this.shopMail:'',
              rentedProductId: productsId,
              warehouse:this.warehouse,
            };            
            await axios({
              method: "post",
              url: process.env.VUE_APP_ROOT_API + "/events/rent",
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
              data: data,
            });   
            if(this.createFromShop){
                try{
                    await axios({
                    method: "patch",
                    url: process.env.VUE_APP_ROOT_API + "/external/mark-imported/"+this.createFromShop,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    data: data,
                    });
                    window.location.reload()
                }catch(e){
                    console.log(e);
                    Swal.fire("Błąd", e.response.statusText, "error");
                }                
            }                     
            this.$emit('closeDialogCreate')
            await this.getEvents();
          } catch (e) {
            console.log(e);
            Swal.fire("Błąd", e.response.statusText, "error");
          }
        },
        async toggleLoading(type) {
            const toggle = type;
            localStorage.setItem("loading", toggle);
            window.dispatchEvent(
                new CustomEvent("toggleLoading", {
                detail: {
                    loading: localStorage.getItem("loading"),
                    overlay: localStorage.getItem("loading"),
                },
                })
            );
        },  
        getDistance(start, end) {
        const origin = new google.maps.LatLng(start[0], start[1]);
        const final = new google.maps.LatLng(end[0], end[1]);
        const service = new google.maps.DistanceMatrixService();

        return new Promise((resolve, reject) => {
            service.getDistanceMatrix(
            {
                origins: [origin],
                destinations: [final],
                travelMode: "DRIVING",
            },
            (response, status) => {
                if (status === "OK") {
                resolve({
                    distance: (
                    response.rows[0].elements[0].distance.value / 1000
                    ).toFixed(2),
                });
                } else {
                reject(new Error("Not OK"));
                }
            }
            );
        });
        },        
        getAddressData: async function (addressData) {
            await this.toggleLoading(true);
            try {
                let warehouse = await axios({
                method: "get",
                url:
                    process.env.VUE_APP_ROOT_API +
                    "/warehouse/get-by-id/" +
                    this.warehouse,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                });
                let dist = await this.getDistance(
                [warehouse.data.data[0].getLat, warehouse.data.data[0].geoLng],
                [addressData.latitude, addressData.longitude]
                );
                if(this.rentType=='delivery'){
                    this.kmRentLength = dist.distance;
                }else{
                    this.kmRentLength=0
                }                   
                this.rentAddressHelper = addressData;
            } catch (e) {
                console.log(e);
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            await this.toggleLoading(false);
        },              
        async getProducts() {
            await this.toggleLoading(true);
            this.products = [];
            try {
                let products = await axios({
                method: "get",
                url: process.env.VUE_APP_ROOT_API + "/products/" + this.warehouse+`/?sort=${'id'}&direction=${false}&page=${1}&limit=${100}`,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                });
                const ids = products.data.data.items.map(item => item.id)
                let bed = await axios({
                method: "get",
                url: process.env.VUE_APP_ROOT_API + "/rents/rented-beds/" + ids.toString()+'/'+dayjs(this.startDate).format("YYYY-MM-DD") +
                " " +
                this.startTime,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                });                                  
              if(this.warehouse == 2 || this.warehouse == 8){
                  const products1 = await axios({
                      method: "get",
                      url: process.env.VUE_APP_ROOT_API + "/products/" + (this.warehouse == 2 ? 8:2) + `/?sort=${'id'}&direction=${false}&page=${1}&limit=${100}`,
                      headers: {
                          Authorization: "Bearer " + localStorage.getItem("token"),
                      },
                  });
                  const ids1 = products1.data.data.items.map(item => item.id)
                  let bed1 = await axios({
                      method: "get",
                      url: process.env.VUE_APP_ROOT_API + "/rents/rented-beds/" + ids1.toString()+'/'+dayjs(this.startDate).format("YYYY-MM-DD") +
                " " +
                this.startTime,
                      headers: {
                          Authorization: "Bearer " + localStorage.getItem("token"),
                      },
                  }); 
                  for (let a = 0; a < products.data.data.items.length;a++){
                      let rented = 0
                      let rentIndex = bed.data.data.rents.find(it => it.id == products.data.data.items[a].id)
                      let rentIndex1 =  bed1.data.data.rents.find(it => it.id == products1.data.data.items[a].id);                    
                      rented = (rentIndex ? parseInt(rentIndex.sumRents) : 0) + (rentIndex1 ? parseInt(rentIndex1.sumRents) : 0)                    
                      let returns = 0
                      let returnIndex = bed.data.data.returns.find(it => it.id == products.data.data.items[a].id) 
                      let returnIndex1 = bed1.data.data.returns.find(it => it.id == products1.data.data.items[a].id);                                  
                      returns = (returnIndex ? parseInt(returnIndex.sumReturns) : 0) + (returnIndex1 ? parseInt(returnIndex1.sumReturns) : 0)
                      let returns_till = 0
                      let returnTillIndex = bed.data.data.returns_till_date.find(it => it.id == products.data.data.items[a].id) 
                      let returnTillIndex1 = bed1.data.data.returns_till_date.find(it => it.id == products1.data.data.items[a].id);                                  
                      returns_till = (returnTillIndex ? parseInt(returnTillIndex.sumReturns) : 0) + (returnTillIndex1 ? parseInt(returnTillIndex1.sumReturns) : 0)                                                
                      products.data.data.items[a].subtitle = ` ${products.data.data.items[a].qty - rented + returns_till} | ${products.data.data.items[a].qty - rented + returns} | ${products.data.data.items[a].qty} | ${returns} | ${rented}`
                      if ((products.data.data.items[a].qty - rented + returns) > 0) {
                          products.data.data.items[a].color = "green";
                      } else {
                          products.data.data.items[a].color = "red";
                      }                    
                  }                  
              }else{
                  products.data.data.items.forEach((item) => {
                      let rented = 0
                      let rentIndex = bed.data.data.rents.find(it => it.id == item.id);
                      if (rentIndex) {
                          rented = parseInt(rentIndex.sumRents)
                      }
                      let returns = 0
                      let returnIndex = bed.data.data.returns.find(it => it.id == item.id);
                      if (returnIndex) {
                          returns = parseInt(returnIndex.sumReturns)
                      }
                      let returns_till = 0
                      let returnTillIndex = bed.data.data.returns_till_date.find(it => it.id == item.id);
                      if (returnTillIndex) {
                          returns_till = parseInt(returnTillIndex.sumReturns)
                      }                      
                      item.subtitle = ` ${item.qty-rented+returns_till} | ${item.qty - rented + returns} | ${item.qty} | ${returns} | ${rented}`
                      if ((item.qty - rented + returns) > 0) {
                          item.color = "green";
                      } else {
                          item.color = "red";
                      }
                  });
              }   
                this.products = products.data.data.items;
            } catch (e) {
                console.log(e);
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            await this.toggleLoading(false);
        },        
        async getCompany(){
            try{
                const company = await axios({
                method: "get",
                url: process.env.VUE_APP_ROOT_API + `/warehouse/get-company?sort=${'name'}&direction=${false}&page=${1}&limit=${100}`,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },              
                });
                company.data.data.items.unshift({id:null,name:"Brak"})
                this.companySelecteds = company.data.data.items
            }catch(e){
                console.log(e);
                Swal.fire("Błąd", e.response.statusText, "error");
            }
        },        
    }
}
</script>