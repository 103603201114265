<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                                <v-data-table :headers="headers" :options.sync="options" :loading="loading"
                                    :items="invoices" class="elevation-1">
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>Wystawione Paragony</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <div>
                            <v-container>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field type="date" background-color="secondary" v-model="startDate" label="Data Rozp."></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field type="date" background-color="secondary" v-model="endDate" label="Data Zak."></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-select v-model="eventTypeModel" :items="eventTypes" label="Typ wydarzenia">
                                    </v-select>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn class="primary" style="width: 100%" @click="initialize()">Pokaż</v-btn>
                                </v-col>
                            </v-row>
                            </v-container>
                        </div>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-btn class="primary" @click="makeDone(item.id)">
                            <v-icon small class="pt-1">mdi-delete</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
const dayjs = require("dayjs");
const axios = require("axios");
import Swal from "sweetalert2";

export default {
    name: "ReceiptsHistory",
    data: () => ({        
        startDate: dayjs().toISOString().substr(0, 10),
        endDate: dayjs().toISOString().substr(0, 10),        
        options: {},
        totalRecords: 0,
        loading: false,    
        eventTypes: ['Kalendarz', 'Sklep Online'],
        eventTypeModel: 'Kalendarz',  
        headers: [
            {
                text: "Data Dodania Wydarzenia",
                sortable: false,
                value: "startDate",
            },
            {
                text: "Notatka Pracownika",
                align: "start",
                sortable: false,
                value: "deliveryNote",
            },
            {
                text: "Kwota Sprzedaży",
                align: "start",
                sortable: false,
                value: "totalSell",
            },
            {
                text: "Kwota Za Dojazd",
                align: "start",
                sortable: false,
                value: "totalDelivery",
            },
            {
                text: "Typ Wydarzenia",
                align: "start",
                sortable: false,
                value: "type",
            },
            {
                text: "Produkt",
                align: "start",
                sortable: false,
                value: "product",
            },
            { text: "", value: "actions", sortable: false },
        ],
        orders: [],
        invoices: [],
    }),

    async created() {
    },
    watch: {
        // options: {
        //     async handler() {
        //         await this.initialize()
        //     },
        //     deep: true,
        // },      
    },

    methods: {
        async toggleLoading(type) {
            const toggle = type;
            localStorage.setItem("loading", toggle);
            window.dispatchEvent(
                new CustomEvent("toggleLoading", {
                    detail: {
                        loading: localStorage.getItem("loading"),
                        overlay: localStorage.getItem("loading"),
                    },
                })
            );
        },
        async makeDone(id) {
            await this.toggleLoading(true);
            try {
                Swal.fire({
                    title: "Napewno Cofnąć?",
                    text: "",
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Tak",
                    cancelButtonText: "Anuluj",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        let type = this.eventTypeModel == "Kalendarz" ? 'normal' : 'extrernal'
                        await axios({
                            method: "patch",
                            url: process.env.VUE_APP_ROOT_API + "/events/unmark-receipt/" + id + '/' + type,
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("token"),
                            },
                        });
                        await this.initialize();
                    }
                });
            } catch (e) {
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            await this.toggleLoading(false);
        },
        async initialize() {
            let type = this.eventTypeModel == "Kalendarz" ? 'normal' : 'extrernal'
            this.loading = true
            let { sortBy, sortDesc } = this.options              
            try {
                let defaultSort = ''
                if (type == "normal") {
                    defaultSort = 'dealNo'
                } else {
                    defaultSort = 'woocommerceId'
                }                
                let invoices = await axios({
                    method: "get",
                    url: process.env.VUE_APP_ROOT_API + `/events/get-receipts-history/${this.startDate}/${this.endDate}?sort=${sortBy.length ? sortBy : defaultSort}&direction=${sortDesc}&type=${type}`,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                this.invoices = [];
                if (this.eventTypeModel == 'Kalendarz') {
                    this.headers = [
                        {
                            text: "Data Wydarzenia",
                            sortable: true,
                            value: "startDate",
                        },
                        {
                            text: "Typ Wydarzenia",
                            align: "start",
                            sortable: true,
                            value: "type",
                        },
                        {
                            text: "Nr. Umowy",
                            align: "start",
                            sortable: true,
                            value: "dealNo",
                        },
                        {
                            text: "Klient",
                            align: "start",
                            sortable: true,
                            value: "rname",
                        },                         
                        {
                            text: "Adres",
                            align: "start",
                            sortable: true,
                            value: "productAddress",
                        },
                        {
                            text: "Notatka Pracownika",
                            align: "start",
                            sortable: true,
                            value: "deliveryNote",
                        },
                        {
                            text: "Kwota Sprzedaży",
                            align: "start",
                            sortable: true,
                            value: "totalSell",
                        },
                        {
                            text: "Kwota Za Dojazd",
                            align: "start",
                            sortable: true,
                            value: "totalDelivery",
                        },
                        {
                            text: "Rodzaj Płatności",
                            align: "start",
                            sortable: true,
                            value: "paymentType",
                        },
                        {
                            text: "Produkt",
                            align: "start",
                            sortable: false,
                            value: "productName",
                        },
                        { text: "", value: "actions", sortable: false },
                    ]
                    invoices.data.data.forEach((item) => {
                        item.totalSell = item.totalSell / 100
                        item.totalDelivery = item.totalDelivery / 100                     
                        switch (item.eventType) {
                            case 'rent':
                                item.startDate = dayjs(item.startDate).format("YYYY-MM-DD HH:mm")
                                item.type = 'Wypożyczenie'
                                break;
                            case 'return':
                                item.startDate = dayjs(item.startDate).format("YYYY-MM-DD HH:mm")
                                item.type = 'Odbiór'
                                break;
                            case 'service':
                                item.startDate = dayjs(item.startDate).format("YYYY-MM-DD HH:mm")
                                item.type = 'Serwis'
                                break;
                            case 'transport':
                                item.startDate = dayjs(item.startDate).format("YYYY-MM-DD HH:mm")
                                item.type = 'Transport'
                                break;
                            case 'other':
                                item.startDate = dayjs(item.startDate).format("YYYY-MM-DD HH:mm")
                                item.type = 'Inne'
                                item.productName = 'N/D'
                                break;
                        }
                        switch (item.paymentType) {
                            case 'cash':
                                item.paymentType = 'Gotówka'
                                break;
                            case 'card':
                                item.paymentType = 'Karta'
                                break;
                            case 'gate':
                                item.paymentType = 'Sklep Online'
                                break;
                            case 'wire':
                                item.paymentType = 'Przelew'
                                break;
                        }
                    });
                } else {
                    this.headers = [
                        {
                            text: "Data Importu",
                            sortable: true,
                            value: "created_at",
                        },
                        {
                            text: "Adres",
                            align: "start",
                            sortable: true,
                            value: "address",
                        },
                        {
                            text: "Notatka Transport",
                            align: "start",
                            sortable: true,
                            value: "transportNote",
                        },
                        {
                            text: "Rodzaj Płatności",
                            align: "start",
                            sortable: true,
                            value: "paymentType",
                        },
                        {
                            text: "Produkty",
                            align: "start",
                            sortable: false,
                            value: "products",
                        },
                        { text: "", value: "actions", sortable: false },
                    ]
                    invoices.data.data.forEach((item) => {
                        item.created_at = dayjs(item.created_at).format("YYYY-MM-DD HH:mm")
                    })
                }
                this.invoices = invoices.data.data  
            } catch (e) {
                console.log(e);
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            this.loading = false    
        },
    },
};
</script>