<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="warehouses"
          :options.sync="optionsWarehouse"
          :server-items-length="totalRecordsWarehouse"
          :loading="loadingWarehouse"             
          class="elevation-1"          
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Magazyn</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-btn
              v-if="showOptions"
                class="primary"
                @click="
                  (dialog = true),
                    (action = 'Tworzenie'),
                    (warehouseName = ''),
                    (currentWarehouseId = null),
                    (modalButton = 'Dodaj')((adressLabel = 'Adres Magazynu'))
                "
                >Dodaj Magazyn</v-btn
              >
            </v-toolbar>
          </template>
          <template v-slot:item.address="{ item }">
            <p :style={color:item.color}>{{ item.address }}</p>
          </template>
          <template v-slot:item.actions="{ item }" v-if="showOptions">
            <v-btn :style={background:item.color} @click="getWarehouseData(item.id)">
              <v-icon small class="mr-2"> mdi-pencil </v-icon>
            </v-btn>
          </template>
          <template v-slot:item.details="{ item }">
            <v-btn :style={background:item.color} @click="infoWarehouse(item.id)">
              <v-icon small class="mr-2"> mdi-eye </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-if="showOptions" v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ action }} Magazynu</span>
        </v-card-title>
        <v-card-text >
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="warehouseName"
                  :rules="[(v) => !!v || 'Uzupełnij Pole']"
                  label="Nazwa Magazynu"
                  required
                  placeholder="Nazwa Magazynu"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <vuetify-google-autocomplete
                  id="map"
                  required
                  append-icon="mdi-search"
                  classname="form-control"
                  :placeholder="adressLabel"
                  :label="adressLabel"
                  ref="addr"
                  v-on:placechanged="getAddressData"
                  country="pl"
                >
                </vuetify-google-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Anuluj
          </v-btn>
          <v-btn color="blue darken-1" text @click="saveWarehouse()">
            {{ modalButton }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog :eager="true"  v-model="productsDialog" fullscreen hide-overlay>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="productsDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Produkty w Magazynie: {{ warehouseProductsName }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container v-if="showOptions">
          <v-form>
            <v-row class="pa-5">
              <v-col cols="3">
                <v-text-field
                  v-model="pName"
                  label="Nazwa Produktu"
                  :rules="[(v) => !!v || 'Uzupełnij Pole']"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="qty"
                  :rules="[(v) => !!v || 'Uzupełnij Pole']"
                  label="Ilość"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="price"
                  :rules="[(v) => !!v || 'Uzupełnij Pole']"
                  label="Cena"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-btn class="primary" @click="addProduct()">Dodaj</v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-spacer></v-spacer>
        </v-container>
        <v-data-table
          :headers="headersProducts"
          :items="productsInWarehouse"
          class="elevation-1"
          mobile-breakpoint="0"
          :options.sync="optionsProducts"          
          :server-items-length="totalRecordsProducts"
          :loading="loadingProducts"  
  :footer-props="{
    'items-per-page-options': [20, 50]
  }"
  :items-per-page="20"                  
        >
          <template v-slot:item.newQty="{ item }" v-if="showOptions">
                <v-text-field
                  :rules="[(v) => !!v || 'Uzupełnij Pole']"
                  v-model="qtyInput[item.id]"
                >
                </v-text-field>
          </template>
          <template v-slot:item.action="{ item }" v-if="showOptions">
            <v-select v-model="actionSelect[item.id]" item-text="name" item-value="id" :items="[{ id: null, name: 'Brak' }, { id: 'add', name: 'Dodaj' }, { id: 'rem', name: 'Odejmij' }]">
            </v-select>
          </template>
          <template v-slot:item.newPrice="{ item }" v-if="showOptions">
              <v-text-field :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="newPrice[item.id]">
              </v-text-field>
          </template>
          <template v-slot:item.actions="{ item }" v-if="showOptions">
                <v-btn class="primary" @click="updateProduct(item.id,item.newPrice,item.qty)">
                  <v-icon small class="pt-1"> mdi-content-save </v-icon>
                </v-btn>
          </template>
          <template v-slot:item.historyActions="{ item }">
            <v-btn class="info" @click="getHistory(item.id)">
              <v-icon small class="pt-1"> mdi-history </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
        <v-dialog :eager="true"  v-model="historyDialog" max-width="600px">
          <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="historyDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>                    
          </v-toolbar>
          
          <v-data-table :headers="historyHeaders"           class="elevation-1"
          mobile-breakpoint="0"          
          :options.sync="optionsProductsHistory"
          :server-items-length="totalRecordsProductsHistory"
          :loading="loadingProducts"  :items="historyItems">
          </v-data-table>
          </v-card>
        </v-dialog>
  </v-container>
</template>
<script>
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";
const axios = require("axios");
import Swal from "sweetalert2";
import * as dayjs from 'dayjs'
export default {
  name: "Warehouse",
  components: { VuetifyGoogleAutocomplete },
  data: () => ({
    showOptions:false,
    sortByName:'name',
    optionsWarehouse: {},
    totalRecordsWarehouse: 0,
    loadingWarehouse: false,
    optionsProducts: {},
    totalRecordsProducts: 0,
    loadingProducts: false,
    optionsProductsHistory: {},
    totalRecordsProductsHistory: 0,
    loadingProductsHistory: false,    

    calculate: true,
    whName: "",
    modalButton: "",
    warehouseProductsName:"",
    qtyInput: [],
    actionSelect: [],
    newPrice:[],
    price: "",
    qty: "",
    pName: "",
    currentWarehouseId: null,
    productsDialog: false,
    address: "",
    action: "",
    adressLabel: "Adres Magazynu",
    warehouseName: "",
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Adres Magazynu",
        align: "start",
        sortable: false,
        value: "address",
      },
    {
        text: "Nazwa Magazynu",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "", value: "actions", sortable: false },
      { text: "", value: "details", sortable: false },
    ],
    historyDialog:false,
    historyItems:[],
    historyHeaders:[
      {
        text: "Data",
        align: "start",
        sortable: false,
        value: "created_at",
      },
      {
        text: "Przed",
        align: "start",
        sortable: false,
        value: "changesBeforeString",
      }, 
      {
        text: "Po",
        align: "start",
        sortable: false,
        value: "changesAfterString",
      }, 
      {
        text: "Komerntarx",
        align: "start",
        sortable: false,
        value: "comments",
      },       
      {
        text: "Kto",
        align: "start",
        sortable: false,
        value: "userDetails",
      },                        
    ],
    headersProducts: [
      {
        text: "Nazwa Produktu",
        align: "start",
        sortable: false,
        value: "name",
      },
      {
        text: "Dostępna Aktualnie",
        align: "start",
        sortable: false,
        value: "currentAvaible",
      },        
      {
        text: "Dostępna ilość",
        align: "start",
        sortable: false,
        value: "avaible",
      },
      {
        text: "Fizyczna ilość",
        align: "start",
        sortable: false,
        value: "qty",
      },    
      {
        text: "Odbiory",
        align: "start",
        sortable: false,
        value: "returns",
      },  
      {
        text: "Dostawy",
        align: "start",
        sortable: false,
        value: "rented",
      },                  
      {
        text: "Aktualna Cena",
        align: "start",
        sortable: false,
        value: "rentPrice",
      },      
      {
        text: "Zmień Fizyczną Ilość",
        align: "start",
        sortable: false,
        value: "newQty",
      },
      {
        text: "Akcja",
        align: "start",
        sortable: false,
        value: "action",
      },      
      {
        text: "Nowa Cena",
        align: "start",
        sortable: false,
        value: "newPrice",
      },      
      { text: "", value: "actions", sortable: false },
      { text: "", value: "historyActions", sortable: false },
    ],
    warehouses: [],
    currentHistoryId:null,
    productsInWarehouse: [],
  }),
  watch: {
    optionsWarehouse: {
      async handler() {
        await this.initialize()
      },
      deep: true,
    },
    optionsProducts: {
      async handler() {
        if(this.currentWarehouseId){
          await this.infoWarehouse(this.currentWarehouseId)
        }        
      },
      deep: true,
    }, 
    optionsProductsHistory: {
      async handler() {
        if(this.currentHistoryId){
          await this.getHistory(this.currentHistoryId)
        }      
      },
      deep: true,
    },        
  },
  async created() {
    const isAdmin = localStorage.getItem('role')
    if(isAdmin == 'admin'){
      this.showOptions = true
    }else{
      this.showOptions = false
      this.headersProducts= [
        {
          text: "Nazwa Produktu",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Dostępna Aktualnie",
          align: "start",
          sortable: false,
          value: "currentAvaible",
        },
        {
          text: "Dostępna ilość",
          align: "start",
          sortable: false,
          value: "avaible",
        },
        {
          text: "Fizyczna ilość",
          align: "start",
          sortable: false,
          value: "qty",
        },
        {
          text: "Odbiory",
          align: "start",
          sortable: false,
          value: "returns",
        },
        {
          text: "Dostawy",
          align: "start",
          sortable: false,
          value: "rented",
        },        
        {
          text: "Aktualna Cena",
          align: "start",
          sortable: false,
          value: "rentPrice",
        },
        { text: "", value: "historyActions", sortable: false },
      ]      
    }
  },

  methods: {
    async toggleLoading(type) {
      const toggle = type;
      localStorage.setItem("loading", toggle);
      window.dispatchEvent(
        new CustomEvent("toggleLoading", {
          detail: {
            loading: localStorage.getItem("loading"),
            overlay: localStorage.getItem("loading"),
          },
        })
      );
    },
    getAddressData: function (addressData) {
      this.address = addressData;
    },
    async getHistory(id){
      this.currentHistoryId = id
      this.loadingProductsHistory = true
      let { page, itemsPerPage } = this.optionsProductsHistory      
      this.historyDialog = true      
      try {
        let history = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "/products/history/" + id + `?sort=created_at&direction=true&page=${page?page:1}&limit=${itemsPerPage?itemsPerPage:10}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        history.data.data.items.forEach((item)=>{        
          item.userDetails = item.user?(item.user.firstName + ' ' + item.user.lastName):'System'
          const offset = new Date().getTimezoneOffset();
          item.created_at = dayjs(item.created_at).add(Math.abs(offset / 60), 'hours').format('YYYY-MM-DD HH:mm')                       
        })
        this.historyItems = history.data.data.items
        this.itemsPerPage = history.data.data.meta.itemsPerPage
        this.page = history.data.data.meta.currentPage
        this.totalRecordsProductsHistory = history.data.data.meta.totalItems         
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText, "error");
      }     
      this.loadingProductsHistory = false
    },
    async addProduct() {
      await this.toggleLoading(true);
      try {
        await axios({
          method: "post",
          url: process.env.VUE_APP_ROOT_API + "/products",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: {
            name: this.pName,
            qty: this.qty,
            rentPrice: this.price,
            warehouse: this.currentWarehouseId,
          },
        });
        await this.infoWarehouse(this.currentWarehouseId);
        this.pName = "";
        this.price = "";
        this.qty = "";
      } catch (e) {
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      await this.toggleLoading(false);
    },
    async updateProduct(item,price,qty) {
      await this.toggleLoading(true);
      try {
        await axios({
          method: "patch",
          url: process.env.VUE_APP_ROOT_API + "/products/" + item,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: {
            qty: parseInt(this.qtyInput[item])||qty,
            rentPrice: parseFloat(this.newPrice[item])||price,
            action: this.actionSelect[item] || null,
          },
        });
        this.qtyInput[item] = "";
        this.newPrice[item] = "";
        await this.infoWarehouse(this.currentWarehouseId);
      } catch (e) {
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      await this.toggleLoading(false);
    },
    async getWarehouseData(item) {
      await this.toggleLoading(true);
      try {
        let warehouse = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "/warehouse/get-by-id/" + item,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.action = "Edycja";
        this.modalButton = "Aktualizuj";
        this.adressLabel = "Nowy Adres Magazynu";
        this.dialog = true;
        this.currentWarehouseId = item;
        this.warehouseName = warehouse.data.data[0].name;        
      } catch (e) {
        this.dialog = false;
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      await this.toggleLoading(false);
    },
    async infoWarehouse(item) {
      this.currentWarehouseId = item;
      this.loadingProducts = true
      let { sortBy, sortDesc, page, itemsPerPage } = this.optionsProducts         
      try {
        let warehouse = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "/warehouse/get-by-id/" + item,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },        
        });               
        let products = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "/products/" + item + `?sort=${sortBy.length ? sortBy : 'id'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });   
        const ids = products.data.data.items.map(item => item.id)
        const bed = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "/rents/rented-beds/" + ids.toString() + '/' + dayjs().format('YYYY-MM-DD HH:mm'),
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });  
        if (this.currentWarehouseId == 2 || this.currentWarehouseId == 8) {
          const products1 = await axios({
            method: "get",
            url: process.env.VUE_APP_ROOT_API + "/products/" + (this.currentWarehouseId == 2 ? 8 : 2) + `/?sort=${'id'}&direction=${false}&page=${1}&limit=${100}`,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          });
          const ids1 = products1.data.data.items.map(item => item.id)
          let bed1 = await axios({
            method: "get",
            url: process.env.VUE_APP_ROOT_API + "/rents/rented-beds/" + ids1.toString() + '/'+dayjs().format('YYYY-MM-DD HH:mm'),
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          });
          for (let a = 0; a < products.data.data.items.length; a++) {            
            let rentIndex = bed.data.data.rents.find(it => it.id == products.data.data.items[a].id)
            let rentIndex1 = bed1.data.data.rents.find(it => it.id == products1.data.data.items[a].id);
            products.data.data.items[a].rented = (rentIndex ? parseInt(rentIndex.sumRents) : 0) + (rentIndex1 ? parseInt(rentIndex1.sumRents) : 0)                        
            let returnIndex = bed.data.data.returns.find(it => it.id == products.data.data.items[a].id)
            let returnIndex1 = bed1.data.data.returns.find(it => it.id == products1.data.data.items[a].id);
            products.data.data.items[a].returns = (returnIndex ? parseInt(returnIndex.sumReturns) : 0) + (returnIndex1 ? parseInt(returnIndex1.sumReturns) : 0)
            let returnTillIndex = bed.data.data.returns_till_date.find(it => it.id == products.data.data.items[a].id)
            let returnTillIndex1 = bed1.data.data.returns_till_date.find(it => it.id == products1.data.data.items[a].id);
            products.data.data.items[a].returns_till = (returnTillIndex ? parseInt(returnTillIndex.sumReturns) : 0) + (returnTillIndex1 ? parseInt(returnTillIndex1.sumReturns) : 0)            
            products.data.data.items[a].avaible = (products.data.data.items[a].qty - products.data.data.items[a].rented  + products.data.data.items[a].returns)
            products.data.data.items[a].currentAvaible = (products.data.data.items[a].qty - products.data.data.items[a].rented + products.data.data.items[a].returns_till)
            this.actionSelect[products.data.data.items[a].id] = null
            this.newPrice[products.data.data.items[a].id] = products.data.data.items[a].rentPrice 
          }
        } else {
          products.data.data.items.forEach((item) => {
            item.rented = 0
            item.rented = 0
            item.returns_till = 0
            item.returns = 0
            let rentIndex = bed.data.data.rents.find(it => it.id == item.id);
            if (rentIndex) {
              item.rented = parseInt(rentIndex.sumRents)
            }            
            let returnIndex = bed.data.data.returns.find(it => it.id == item.id);
            if (returnIndex) {
              item.returns = parseInt(returnIndex.sumReturns)
            }
            let returnTillIndex = bed.data.data.returns_till_date.find(it => it.id == item.id);
            if (returnTillIndex) {
              item.returns_till = parseInt(returnTillIndex.sumReturns)
            }            
            item.avaible = (item.qty - item.rented + item.returns)
            item.currentAvaible = (item.qty - item.rented + item.returns_till)
            this.actionSelect[item.id] = null
            this.newPrice[item.id] = item.rentPrice            
          });
        }                        
        this.warehouseProductsName = warehouse.data.data[0].name        
        this.productsInWarehouse = products.data.data.items
        this.itemsPerPage = products.data.data.meta.itemsPerPage
        this.page = products.data.data.meta.currentPage
        this.totalRecordsProducts = products.data.data.meta.totalItems         
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      this.productsDialog = true;
      this.whName = "";
      this.loadingProducts = false
    },
    async initialize() {
      this.loading = true      
      let { sortBy, sortDesc, page, itemsPerPage } = this.optionsWarehouse  
      try {
        let warehouse = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/warehouse?sort=${sortBy.length ? sortBy : 'name'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });        
        this.warehouses = warehouse.data.data.items
        this.itemsPerPage = warehouse.data.data.meta.itemsPerPage
        this.page = warehouse.data.data.meta.currentPage
        this.totalRecordsWarehouse = warehouse.data.data.meta.totalItems        
      } catch (e) {
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      this.loading = false      
    },
    async deleteProduct(id){
      await this.toggleLoading(true);
      Swal.fire({
        title: "Napewno Zmienić Datę i Godzinę",
        text: "",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Tak",
        cancelButtonText: "Anuluj",
      }).then(async (result) => {
        if (result.isConfirmed) {      
      try {
        await axios({
          method: 'delete',
          url: process.env.VUE_APP_ROOT_API +
            "/products/" +
            id,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: {
            status: false,
          },
        });
        await this.initialize();
        this.productsDialog = false;
      } catch (e) {
        Swal.fire("Błąd", e.response.statusText, "error");
      }      
      }       
    }) 
      await this.toggleLoading(false);     
    },
    async saveWarehouse() {
      await this.toggleLoading(true);
      let method = "";
      let url = "";
      if (this.currentWarehouseId) {
        method = "patch";
        url =
          process.env.VUE_APP_ROOT_API +
          "/warehouse/" +
          this.currentWarehouseId;
      } else {
        method = "post";
        url = process.env.VUE_APP_ROOT_API + "/warehouse";
      }
      try {
        await axios({
          method: method,
          url: url,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: {
            name: this.warehouseName,
            getLat: this.address.latitude,
            geoLng: this.address.longitude,
            address:
              this.address.name +
              " " +
              this.address.postal_code +
              " " +
              this.address.locality,
          },
        });
        await this.initialize();
        this.dialog = false;
      } catch (e) {
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      await this.toggleLoading(false);
    },
  },
};
</script>