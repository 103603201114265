<template>
  <v-container>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12">
        <v-data-table
:headers="headers"
:items="payments"
class="elevation-1"
mobile-breakpoint="0"
:item-class="row_classes"
:options.sync="options"
:server-items-length="totalRecords"
:loading="loading"  
 :search="search"        
        >
<template v-slot:top>
  <v-toolbar flat>
    <v-toolbar-title>Płatności</v-toolbar-title>
    <v-divider class="mx-4" inset vertical></v-divider>
    <v-spacer></v-spacer>
    </v-toolbar>
  <div>
    <v-text-field v-model="search" label="Szukaj" class="mx-4"></v-text-field>
  </div>
 
</template>
<template v-slot:item.actions1="{ item }">
  <v-btn class="primary" @click="showDialog(item.id)"
    ><v-icon small class="pt-1">mdi-plus</v-icon></v-btn
  >
    <v-btn class="warning" @click="showInvoice(item.id)"><v-icon small class="pt-1">mdi-receipt-text</v-icon></v-btn>
  </template>
<template v-slot:item.actions2="{ item }">
  <v-btn class="info" @click="showHistory(item.id)"
    ><v-icon small class="pt-1">mdi-history</v-icon></v-btn
  >
  <v-btn class="error" @click="deleteRent(item.id)">
    <v-icon small class="pt-1">mdi-delete</v-icon>
  </v-btn>
  </template>
<template v-slot:item.actions3="{ item }">
<v-btn class="seconadary" @click="getRentDetails(item.id)">
    <v-icon small class="pt-1">mdi-pencil</v-icon>
  </v-btn>
</template>
        </v-data-table>
      </v-col>
    </v-row>
<v-dialog v-model="invoiceDialog" width="500">
      <v-card>
        <v-toolbar color="primary" dark>Dane do Faktury</v-toolbar>
        <v-card-actions class="justify-end"> </v-card-actions>
        <v-container>
          <v-form>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  label="Dane do Faktury"
                  :rules="[(v) => !!v || 'Uzupełnij Pole']"
                  v-model="invoiceData"
                >
                </v-textarea>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="invoiceType"
                  :items="invoiceTypes"
                  item-text="name"
                  item-value="id"
                  label="Rodzaj Faktury"                                    
                ></v-select>
              </v-col>              
            </v-row>
          </v-form>
        </v-container>
        <v-card-actions>
          <v-btn text @click="invoiceDialog = false">Anuluj</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="saveInvoice()"> Zapisz </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
<v-dialog v-model="detailsDialog" width="500">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="detailsDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Edycja Danych</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      
        <v-form>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field label="Numer Umowy" :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="dealNoRent"
                  @input="dealNoRent = dealNoRent.toUpperCase()" autocomplete="new-password">
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field label="Imię Wypożyczającego" :rules="[(v) => !!v || 'Uzupełnij Pole']"
                  @input="rentFirstName = rentFirstName.toUpperCase()" v-model="rentFirstName" autocomplete="new-password">
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field @input="rentLastName = rentLastName.toUpperCase()" label="Nazwisko Wypożyczającego"
                  :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="rentLastName" autocomplete="new-password">
                </v-text-field>
              </v-col>
              <v-col cols="8">
                <v-text-field disabled label="Adres Klienta" v-model="clientAddress"></v-text-field>
      
              </v-col>
              <v-col cols="4">
                <v-btn class="primary" style="width:100%;" @click="showClientAddres =!showClientAddres">Zmień</v-btn>
              </v-col>
              <v-col cols="12" v-if="showClientAddres">
                <vuetify-google-autocomplete id="map1" required autocomplete="new-password" append-icon="mdi-search"
                  classname="form-control" placeholder="Adres Zameldowania" ref="addr" v-model="rentClientAddress"
                  v-on:placechanged="getAddressData" country="pl">
                </vuetify-google-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-text-field label="Numer Mieszkania - Zameldowanie" v-model="rentClientAddressApt"></v-text-field>
              </v-col>
              <v-col cols="8">
                <v-text-field disabled label="Adres Sprzętu" v-model="productAddress"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-btn class="primary" style="width:100%;" @click="showproductAddress=!showproductAddress">Zmień</v-btn>
              </v-col>
              <v-col cols="12" v-if="showproductAddress">
                <vuetify-google-autocomplete id="map2" required append-icon="mdi-search" autocomplete="new-password"
                  classname="form-control" placeholder="Nowy Adres Sprzętu" ref="addr" v-model="rentProductAddress"
                  v-on:placechanged="getAddressData1" country="pl">
                </vuetify-google-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-text-field autocomplete="new-password" label="Numer Mieszkania - Sprzęt" v-model="rentProductAddressApt">
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-radio-group v-model="radios">
                  <template v-slot:label>
                    <div><strong>Wybierz Telefon Do Kontaktu:</strong></div>
                  </template>
                  <v-radio value="caller">
                    <template v-slot:label>
                      <v-text-field autocomplete="new-password" label="Telefon Dzwoniącego"
                        :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="rentCallerPhone">
                      </v-text-field>
                    </template>
                  </v-radio>
                  <v-radio value="contact">
                    <template v-slot:label>
                      <v-text-field autocomplete="new-password" label="Telefon Do Kontaktu"
                        :rules="[(v) => !!v || 'Uzupełnij Pole']" v-model="rentClientPhone">
                      </v-text-field>
                    </template>
                  </v-radio>
                  <v-radio value="other">
                    <template v-slot:label>
                      <v-text-field autocomplete="new-password" label="Inny Telefon" :rules="[(v) => !!v || 'Uzupełnij Pole']"
                        v-model="otherPhone">
                      </v-text-field>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12">
                <v-text-field label="E-mail" autocomplete="new-password" v-model="rentMail">
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea label="Uwagi Księgowe" autocomplete="new-password" v-model="accData">
              </v-textarea>
              </v-col>
              <v-col cols="12">
                <v-select label="Produkt" v-model="currentProduct" :items="currentProducts" item-text="name" item-value="id">
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      
        <v-card-actions>
          <v-btn text @click="detailsDialog = false">Anuluj</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="updateClientFast()"> Zapisz </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
<v-dialog v-model="historyDialog" width="500">
      <v-card>
        <v-toolbar color="primary" dark>Historia Płatności</v-toolbar>
        <v-card-actions class="justify-end"> </v-card-actions>
        <v-container>
          <v-data-table
            :headers="headersHistory"
            :items="history"
            class="elevation-1"
            mobile-breakpoint="0"
          :loading="loadingHistory"             
          >
          </v-data-table>
        </v-container>
        <v-card-actions>
          <v-btn text @click="historyDialog = false">Anuluj</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
<v-dialog v-model="dialog" width="500">
      <v-card>
        <v-toolbar color="primary" dark>Dodawanie płatności</v-toolbar>
        <v-card-actions class="justify-end"> </v-card-actions>
        <v-container>
          <v-form>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  type="date"
                  background-color="secondary"
                  label="Przedłużono Do"
                  :rules="[(v) => !!v || 'Uzupełnij Pole']"
                  v-model="paidUntil"
                  @change="countDays()"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  disabled
                  type="number"
                  :rules="[(v) => !!v || 'Uzupełnij Pole']"
                  label="Ilość Miesięcy"
                  v-model="daysExceeded"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  type="number"
                  :rules="[(v) => !!v || 'Uzupełnij Pole']"
                  label="Kwota"
                  v-model="rentPrice"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field :rules="[(v) => !!v || 'Uzupełnij Pole']" label="Wpłacający" v-model="payer">
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  label="Notatka"
                  :rules="[(v) => !!v || 'Uzupełnij Pole']"
                  v-model="paymentNote"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-card-actions>
          <v-btn text @click="dialog = false">Anuluj</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="savePayment()"> Zapisz </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
// import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete'
const dayjs = require("dayjs");
const axios = require("axios");
import Swal from "sweetalert2";
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";

export default {
  name: "Payments",
  components: {    
    VuetifyGoogleAutocomplete, // make the <FullCalendar> tag available
  },
  data: () => ({
    accData:'',
    currentProduct: null,
    currentProducts: [],
    currentRent: null,
    dealNoRent: '',
    rentFirstName: '',
    rentLastName: '',
    clientAddress: '',
    showClientAddres: false,
    rentClientAddress: '',
    rentClientAddressApt: '',
    productAddress: '',
    showproductAddress: false,
    rentProductAddress: '',
    rentProductAddressApt: '',
    radios: '',
    rentCallerPhone: '',
    rentClientPhone: '',
    otherPhone: '',
    rentMail: '',
    
    search:'',
    options: {},
    totalRecords: 0,
    loading: false, 
    optionsHistory: {},
    totalRecordsHistory: 0,
    loadingHistory: false,                  
    payer:'',
    clientDealNo:'',
    clientFirstName:'',
    clientLastName:'',
    address: "",
    phone: "",
    action: "",
    warehouseName: "",
    invoiceData: "",
    dialog: false,
    invoiceDialog: false,
    historyDialog: false,
    detailsDialog:false,
    aptNo:'',
    headersHistory: [
      {
        text: "Data",
        sortable: false,
        value: "date",
      },
      {
        text: "Kwota",
        sortable: false,
        value: "price",
      },
      {
        text: "Wpłacający",
        sortable: false,
        value: "payer",
      },      
      {
        text: "Notatka",
        sortable: false,
        value: "note",
      },
    ],
    headers: [
      {
        text: "#",
        sortable: false,
        value: "number",
      },
      {
        text: "Numer Umowy",
        align: "start",
        sortable: false,
        value: "dealNo",
      },
      {
        text: "Imię",
        align: "start",
        sortable: false,
        value: "firstName",
      },      
      {
        text: "Nazwisko",
        align: "start",
        sortable: false,
        value: "lastName",
      },
      {
        text: "Adres",
        align: "start",
        sortable: false,
        value: "productAddress",
      },
      {
        text: "Telefon",
        align: "start",
        sortable: false,
        value: "phone",
      },
      {
        text: "Przedmiot",
        align: "start",
        sortable: false,
        value: "product",
      },
      {
        text: "Ważne do",
        align: "start",
        sortable: true,
        value: "paidToDate",
      },
      {
        text: "Ilość Dni",
        align: "start",
        sortable: false,
        value: "expirationDays",
      }, 
      {
        text: "Faktura",
        align: "start",
        sortable: false,
        value: "isInvoice",
      }, 
      {
        text: "Uwagi Księgowe",
        align: "start",
        sortable: false,
        value: "accData",
      },     
      {
        text: "Utworzono Odbiór?",
        align: "start",
        sortable: false,
        value: "isReturnCreated",
      },          
      {
        text: "Wpłacający",
        sortable: false,
        value: "payer",
      },            
      { text: "", value: "actions1", sortable: false },
      { text: "", value: "actions2", sortable: false },
      { text: "", value: "actions3", sortable: false },
    ],
    orders: [],
    history: [],
    payments: [],          
    paidUntil: "",
    rentPrice: "",    
    paymentNote: "",
    daysExceeded: "",
    invoiceType:'',    
    invoiceTypes:[          {
            id: "personal",
            name: "Osoba Fizyczna",
          },
          {
            id: "company",
            name: "Firma",
          },]
  }),

  async created() {
  },
  watch: {
    options: {
      async handler() {
        await this.initialize()
      },
      deep: true,
    },  
    async search() {
      this.options.page = 1
      await this.initialize()
    }, 
  }, 

  methods: {
    initFormData() {
      this.currentRent = null
      this.dealNoRent = ''
      this.rentFirstName = ''
      this.rentLastName = ''
      this.clientAddress = ''
      this.showClientAddres = false
      this.rentClientAddress = ''
      this.rentClientAddressApt = ''
      this.productAddress = ''
      this.showproductAddress = false
      this.rentProductAddress = ''
      this.rentProductAddressApt = ''
      this.radios = ''
      this.rentCallerPhone = ''
      this.rentClientPhone = ''
      this.otherPhone = ''
      this.rentMail = ''
      this.accData = ''
    },    
    row_classes(item) {
      if (dayjs(item.paidToDate).diff(dayjs().format("YYYY-MM-DD"),'day',false) < 0) {
        return "red";
      }
    },
    getAddressData: async function (addressData) {
      await this.toggleLoading(true);
      try {
        this.productAddressHelper = addressData;
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      await this.toggleLoading(false);
    },  
    async getProductsForWarehouseRent(id) {
      const prods = await axios({
        method: "get",
        url:
          process.env.VUE_APP_ROOT_API +
          "/products/get-in-warehouse/" +
          id,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      this.currentProducts = prods.data.data
    },   
    async updateClientFast() {
      await this.toggleLoading(true);
      try {
        await axios({
          method: "patch",
          url:
            process.env.VUE_APP_ROOT_API +
            "/rents/update-deal/" +
            this.currentRent,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: {
            dealNo: this.dealNoRent,
            accData: this.accData,
            firstName: this.rentFirstName,
            lastName: this.rentLastName,
            clientAddress: this.clientAddress,
            showClientAddres: this.showClientAddres,
            showproductAddress: this.showproductAddress,
            productAddress: this.rentProductAddress,
            clientAddressApt: this.rentClientAddressApt,
            productAddressApt: this.rentProductAddressApt,
            selectedPhone: this.radios,
            callerPhone: this.rentCallerPhone,
            clientPhone: this.rentClientPhone,
            otherPhone: this.otherPhone,
            mail: this.rentMail,
            product: this.currentProduct
          },
        });
        this.initFormData()
        this.detailsDialog = false
        await this.initialize();
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      await this.toggleLoading(false);
    },       
    async getRentDetails(id){
      await this.toggleLoading(true);
      const currentRent = await axios({
        method: "get",
        url:
          process.env.VUE_APP_ROOT_API +
          "/rents/find-by-id/" +
          id,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      await this.getProductsForWarehouseRent(currentRent.data.data.products.id)
      this.currentProduct = currentRent.data.data.products.id
      this.currentRent = id
      this.dealNoRent = currentRent.data.data.dealNo
      this.accData = currentRent.data.data.accData
      this.rentFirstName = currentRent.data.data.firstName
      this.rentLastName = currentRent.data.data.lastName
      this.clientAddress = currentRent.data.data.clientAddress
      this.productAddress = currentRent.data.data.productAddress
      this.rentClientAddressApt = currentRent.data.data.clientAddressApt
      this.rentProductAddressApt = currentRent.data.data.productAddressApt
      this.radios = currentRent.data.data.selectedPhone
      this.rentCallerPhone = currentRent.data.data.callerPhone
      this.rentClientPhone = currentRent.data.data.clientPhone
      this.otherPhone = currentRent.data.data.otherPhone
      this.rentMail = currentRent.data.data.mail
      this.detailsDialog = true
      await this.toggleLoading(false);
    },
    async toggleLoading(type) {
      const toggle = type;
      localStorage.setItem("loading", toggle);
      window.dispatchEvent(
        new CustomEvent("toggleLoading", {
          detail: {
            loading: localStorage.getItem("loading"),
            overlay: localStorage.getItem("loading"),
          },
        })
      );
    },
    async savePayment() {
      await this.toggleLoading(true);
      try {
        await axios({
          method: "post",
          url: process.env.VUE_APP_ROOT_API + "/payments/create-payment",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: {
            rents: this.currentRent,
            paidToDate: this.paidUntil,
            totalToPay: this.rentPrice, 
            payer: this.payer,            
            paymentNote: this.paymentNote,
          },
        });
        const currentRent = await axios({
          method: "get",
          url:
            process.env.VUE_APP_ROOT_API +
            "/rents/find-by-id/" +
            this.currentRent,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.currentRent = "";
        this.paidUntil = "";
        this.rentPrice = "";  
        this.payer= ''      
        this.paymentNote = "";
        this.dialog = false;
        this.dealNo = currentRent.data.data.dealNo;
        await this.toggleLoading(false);
        await this.initialize();
      } catch (e) {
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      await this.toggleLoading(false);
    },
    async saveInvoice() {
      await this.toggleLoading(true);
      try {
        await axios({
          method: "patch",
          url:
            process.env.VUE_APP_ROOT_API +
            "/payments/update-invoice/" +
            this.currentRent,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: {
            invoiceData: this.invoiceData,
            invoiceType: this.invoiceType,
          },
        });
        await this.initialize();
        this.invoiceData = "";
        this.invoiceDialog = false;
      } catch (e) {
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      await this.toggleLoading(false);
    },
    async countDays() {
      try{
        let rent = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "/rents/find-by-id/" + this.currentRent,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        const date1 = dayjs(rent.data.data.paidToDate).startOf('day');
        this.daysExceeded = dayjs(this.paidUntil).startOf('day').diff(date1, "month",true).toFixed(2) ;        
      }catch(e){
        Swal.fire("Błąd", e.response.statusText, "error");
      }      
    },
    showDialog(id) {
      this.currentRent = id;  
      this.dialog = true;
    },
    async showHistory(id) {
      this.history = []
      this.loadingHistory = true
      this.currentRent = id;
      try {
        let history = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "/payments/get-payments/" + id,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        history.data.data.forEach((item) => {
          this.history.push({
            date: dayjs(item.paymentDate).format("YYYY-MM-DD HH:mm"),
            price: item.totalToPay/100,
            payer:item.payer,
            note: item.paymentNote,
          });
        });
        this.historyDialog = true;
      } catch (e) {
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      this.loadingHistory = false
    },
    async showInvoice(id) {
      await this.toggleLoading(true);
      this.currentRent = id;
      try {
        let rent = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "/rents/find-by-id/" + id,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.invoiceDialog = true;
        this.invoiceType = rent.data.data.invoiceType
        this.invoiceData = rent.data.data.invoiceData;
      } catch (e) {
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      await this.toggleLoading(false);
    },
    async deleteRent(id){
      try {
        Swal.fire({
          title: "Przenieść do archiwum?",
          text: "",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Tak",
          cancelButtonText: "Anuluj",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await axios({
              method: "patch",
              url: process.env.VUE_APP_ROOT_API + "/rents/mark-archived/" + id,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
              data: {
                status: false,
              },
            });
            await this.initialize();
          }
        })      
      }catch(e){
        Swal.fire("Błąd", e.response.statusText, "error");
      }
    },
    async initialize() {
      this.loading = true
      let { sortBy, sortDesc, page, itemsPerPage } = this.options 
      try {
        let rents = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/rents/get-table-payments?sort=${sortBy.length ? sortBy : 'paidToDate'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}&search=${this.search}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.payments = [];
        rents.data.data.items.forEach((item) => {          
          if (item.isReturnCreated) {
            item.isReturnCreated = 'Utworzony Odbiór: ' + item.returns ? (dayjs(item.returns.startDate).format('DD-MM-YYYY HH:mm') + ' ' + item.returns.user.firstName + ' ' + item.returns.user.lastName) : ''
          } else {
            item.isReturnCreated = 'Nie'
          }            
          item.paidToDate= dayjs(item.paidToDate).format("YYYY-MM-DD"),
          item.isInvoice= (item.invoiceData.length ? ('Tak- ' + (item.invoiceType == 'personal' ? 'Osoba F.' : 'Firma')) : 'Nie'),
          item.expirationDays= dayjs(item.paidToDate).diff(dayjs().format("YYYY-MM-DD"), 'day')
          item.product = item.products.name 
          if (item.selectedPhone) {
            switch (item.selectedPhone) {
              case 'caller':
                item.phone = item.callerPhone
                break;
              case 'other':
                item.phone = item.otherPhone
                break;
              case 'contact':
                item.phone = item.clientPhone
                break;
            }
          } else {
            item.phone = item.clientPhone
          }                 
        });
        this.payments = rents.data.data.items
        this.itemsPerPage = rents.data.data.meta.itemsPerPage
        this.page = rents.data.data.meta.currentPage
        this.totalRecords = rents.data.data.meta.totalItems  
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      this.loading = false
    },
  },
};
</script>