<style scoped>
.v-list-item__title, .v-list-item__subtitle{
text-overflow:none !important;
}
</style>
<template>
  <v-container>
    <v-row>
      <v-col cols="6" v-if="isAdmin">
        <v-select
          label="Wybierz Magazyn"
          v-model="warehouse"
          :items="warehouses"
          item-text="name"
          item-value="id"
          @change="getUsers()"
        ></v-select>
      </v-col>
      <v-col cols="6" v-if="isAdmin">
        <v-select
          label="Wybierz Użytkownika"
          v-model="user"
          :items="users"
          item-text="fullName"
          item-value="id"
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-text-field
          background-color="secondary"
          v-model="startDate"
          label="Data początkowa"
          type="date"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          type="date"
          background-color="secondary"
          v-model="endDate"
          label="Data końcowa"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-btn class="primary" style="width: 100%" @click="getRaport()"
          >Pokaż</v-btn
        >
      </v-col>
    <v-col cols="12"  v-if="isAdmin">
        <v-btn class="warning" style="width: 100%" @click="downloadRaport()">Pobierz Raport</v-btn>
    </v-col>
    </v-row>
    <v-col cols="12">
        <v-card class="mx-auto text-center" color="green" dark>
            <v-card-text>
                <v-list subheader>
                    <v-subheader>Podsumowanie: Godziny</v-subheader>
        
                    <v-list-item v-for="raport in raports" :key="raport.title">
                        <v-list-item-content>
                            <v-list-item-title v-text="raport.title"></v-list-item-title>
                        </v-list-item-content>
        
                        <v-list-item-content>
                            <v-list-item-title v-text="raport.numbers"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card-text>
            <v-divider></v-divider>
        </v-card>
    </v-col>
    <v-col cols="12">
        <v-card class="mx-auto text-center" color="green" >
            <v-card-text>
                <v-list subheader>
                    <v-subheader>Podsumowanie: Wypłaty</v-subheader>
    
                    <v-list-item v-for="raport in payouts" :key="raport.title">
                        <v-list-item-content>
                            <v-list-item-title v-text="raport.title"></v-list-item-title>
                        </v-list-item-content>
    
                        <v-list-item-content>
                            <v-list-item-title v-text="raport.numbers"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card-text>
            <v-divider></v-divider>
            <v-container secondary v-if="isAdmin">
            <v-form>                
                <v-text-field label="Kwota Wypłaty" v-model="payout" type="number" step="1">
                </v-text-field>
                <v-btn class="success" @click="createPayout()">Dodaj Wypłatę</v-btn>
            </v-form></v-container>
        </v-card>
    </v-col>
    <v-col cols="12">
                <v-data-table :loading="loadingHistory" :headers="headers" :items="events" class="elevation-1">
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>Wydarzenia</v-toolbar-title>
            </v-toolbar>
            <!-- <div>
                <v-text-field v-model="search" label="Szukaj" class="mx-4"></v-text-field>
            </div> -->
            </template>
                    <template v-slot:item.sell1="{ item }" v-if="isAdmin">
                        <v-text-field :rules="[(v) => !!v || 'Uzupełnij Pole']" type="number" step="0.1" v-model="sell[item.id]">
                        </v-text-field>
                    </template>
                    <template v-slot:item.delivery1="{ item }" v-if="isAdmin">
                        <v-text-field :rules="[(v) => !!v || 'Uzupełnij Pole']" type="number" step="0.1" v-model="delivery[item.id]">
                        </v-text-field>
                    </template>
                    <template v-slot:item.actions="{ item }" v-if="isAdmin">
                        <v-btn class="warning" @click="updateEventPrices(item.id)">
                            <v-icon small class="pt-1">mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
    </v-col>
    <v-dialog v-model="dialog" fullscreen hide-overlay v-if="isAdmin">
        <v-card>
            <v-toolbar dark color="primary">
                <v-btn icon dark @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Edycja Danych</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
                <v-data-table :loading="loadingHistory"   :headers="headers" :items="events" class="elevation-1" mobile-breakpoint="0">
<template v-slot:item.sell1="{ item }">
    <v-text-field :rules="[(v) => !!v || 'Uzupełnij Pole']" label="Nowa Kwota Sprzedaży" type="number" step="0.1" v-model="sell[item.id]">
    </v-text-field>
</template>
        <template v-slot:item.delivery1="{ item }">
            <v-text-field :rules="[(v) => !!v || 'Uzupełnij Pole']" label="Nowa Kwota Dostawa" type="number" step="0.1" v-model="delivery[item.id]">
            </v-text-field>
        </template>
                    <template v-slot:item.actions="{ item }">
                        <v-btn class="warning" @click="updateEventPrices(item.id)" style="width:100%;">
                            <v-icon small class="pt-1">mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
        </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import Swal from "sweetalert2";
const axios = require("axios");
import * as dayjs from 'dayjs'
import * as reader from 'xlsx'

export default {
  name: "Raports",
  data: () => ({
    loadingHistory:false,
    sell:[],
    delivery:[],
    headers:[
        {
            text: "Data Rozp.",
            align: "start",
            sortable: false,
            value: "startDate",
        },
        {
            text: "Data Zak.",
            align: "start",
            sortable: false,
            value: "endDate",
        },
        {
            text: "Rodzaj Wyd.",
            align: "start",
            sortable: false,
            value: "eventType",
        }, 
        {
            text: "Adres",
            align: "start",
            sortable: false,
            value: "productAddress",
        },         
        {
            text: "Sposób Płatności",
            align: "start",
            sortable: false,
            value: "paymentType",
        }, 
        {
            text: "Nr Umowy",
            align: "start",
            sortable: false,
            value: "dealNo",
        },
        {
            text: "Produkt",
            align: "start",
            sortable: false,
            value: "productName",
        },                                                
        {
            text: "Sprzedaż",
            align: "start",
            sortable: false,
            value: "totalSell",
        },
        {
            text: "Dostawa",
            align: "start",
            sortable: false,
            value: "totalDelivery",
        }, 
        {
            text: "Wydatek",
            align: "start",
            sortable: false,
            value: "totalBuy",
        },
        {
            text: "Sprzedaż Dost.",
            align: "start",
            sortable: false,
            value: "extraCost",
        },                                               
        { text: "Nowa Kwota Sprzedaży", value: "sell1", sortable: false },
        { text: "Nowa Kwota Dostawy", value: "delivery1", sortable: false },
        { text: "", value: "actions", sortable: false },
    ],
    payouts:[],
    payout:0,
    events:[],
    dialog:false,
    endDate:'',
    startDate:'',
    user:null,
    users:[],
    warehouse:null,
    warehouses:[],
    raports:[],
    rents:[],
    returns:[],
    services:[],
    transports:[],
    others:[],
    isAdmin: true
  }),
    async created() {
        const role = localStorage.getItem("role")
        if (role == 'admin') {
            await this.initialize();
        }else{
            this.user = localStorage.getItem("userid") 
            this.isAdmin = false
        }
    },

    methods: {
        async downloadRaport(){
            if (!this.warehouse) {
                Swal.fire("Błąd", 'Wybierz Magazyn', "error");
                return false
            } 
            try{
                const response = await axios({
                    method: "post",
                    url: process.env.VUE_APP_ROOT_API + "/raports/download-raport",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    data: {
                        warehouse: this.warehouse,
                        startDate: this.startDate,
                        endDate: this.endDate,
                    },
                });
                response.data.data.forEach((item)=>{
                    item.totalDelivery=item.totalDelivery / 100
                    item.totalSell = item.totalSell / 100
                    item.totalBuy = item.totalBuy / 100
                    item.extraCost = item.extraCost / 100
                })
                const obj = response.data.data.map((item) => {
                    return{
                        startDate: item.startDate,
                        endDate: item.endDate,
                        title: item.title,
                        dealNo: item.dealNo,
                        eventType: item.eventType,
                        productName: item.productName,
                        totalSell: item.totalSell,
                        paymentType: item.paymentType,
                        firstName: item.firstName,
                        lastName: item.lastName,                        
                        productAddress: item.productAddress,
                        totalDelivery: item.totalDelivery,
                        callerPhone: item.callerPhone,
                        clientPhone: item.clientPhone,
                        otherPhone: item.otherPhone,
                        fullName: item.fullName,
                        totalBuy: item.totalBuy,
                        extraCost: item.extraCost
                    }
                })
                const worksheet = reader.utils.json_to_sheet(obj);
    const workbook = reader.utils.book_new();
     reader.utils.book_append_sheet(workbook, worksheet, "Raport");

  /* fix headers */
                reader.utils.sheet_add_aoa(worksheet, [["Data Rozpoczęcia", "Data Zakończenia", 'Tytuł-Inne', 'Nr Umowy', 'Rodzaj Wydarzenia', 'Produkty', 'Sprzedaż', 'Sposób Płatności', 'Imię', 'Nazwisko', 'Adres', 'Kilometrówka', 'Telefon Dzwoniącego', 'Telefon Wypożyczającego', 'Telefon Inny', 'Pracownik', 'Wydatek', 'Sprzedaż-Wypożyczenie',]], { origin: "A1" });

  /* create an XLSX file and try to save to Presidents.xlsx */
    reader.writeFile(workbook, "Raport.xlsx", { compression: true });                
            }catch(e){
                console.log(e)
                Swal.fire("Błąd", e.response.statusText, "error");
            }
        },
        async createPayout() {
            if(!this.user){
                Swal.fire("Błąd", 'Wybierz użytkownika', "error");
                return false                
            }
            await this.toggleLoading(true);
            try {
                await axios({
                    method: "post",
                    url: process.env.VUE_APP_ROOT_API + "/raports/create-payout",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    data: {
                        user: this.user,
                        payout: this.payout
                    },
                });
                await this.getRaport();                
            } catch (e) {
                console.log(e)
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            await this.toggleLoading(false);
        },        
        async updateEventPrices(id){
           if(this.isAdmin){

           
            if (!this.sell[id]){
                Swal.fire("Błąd", 'Uzupełnij Kwotę Sprzedaży', "error");
                return false
            }
            if (!this.delivery[id]) {
                Swal.fire("Błąd", 'Uzupełnij Kwotę Dostawy', "error");
                return false
            }     
            await this.toggleLoading(true)       
            try {
                await axios({
                    method: "patch",
                    url: process.env.VUE_APP_ROOT_API + "/events/update-sales/" + id,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    data: {
                        sell: parseFloat(this.sell[id]),
                        delivery: parseFloat(this.delivery[id]),
                    },
                });
                this.sell[id] = "";
                this.delivery[id] = "";
                await this.getRaport()
                this.dialog = false
            } catch (e) {
                console.log(e)
                Swal.fire("Błąd", e.response.statusText, "error");
            }            
            await this.toggleLoading(false)
           }
        },
        async getRaport(){
            this.loadingHistory = true
            let raport = await axios({
                method: "get",
                url:
                    process.env.VUE_APP_ROOT_API +
                    "/raports/get-raports-for-user/" +
                    this.user +
                    "/" + this.warehouse +
                    "/" +
                    this.startDate +
                    "/" +
                    this.endDate,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }); 
            if (!this.isAdmin) {
                this.headers = [
                    {
                        text: "Data Rozp.",
                        align: "start",
                        sortable: false,
                        value: "startDate",
                    },
                    {
                        text: "Data Zak.",
                        align: "start",
                        sortable: false,
                        value: "endDate",
                    },
                    {
                        text: "Rodzaj Wyd.",
                        align: "start",
                        sortable: false,
                        value: "eventType",
                    },
                    {
                        text: "Adres",
                        align: "start",
                        sortable: false,
                        value: "productAddress",
                    },                     
                    {
                        text: "Sprzedaż",
                        align: "start",
                        sortable: false,
                        value: "totalSell",
                    },
                    {
                        text: "Dostawa",
                        align: "start",
                        sortable: false,
                        value: "totalDelivery",
                    },                    
                    {
                        text: "Wydatek",
                        align: "start",
                        sortable: false,
                        value: "totalBuy",
                    },
                    {
                        text: "Sprzedaż Dost.",
                        align: "start",
                        sortable: false,
                        value: "extraCost",
                    },                      
                ]
            }            
            raport.data.data.events.forEach((item)=>{
                item.totalSell = item.totalSell/100
                item.totalBuy = item.totalBuy / 100
                item.extraCost = item.extraCost / 100
                item.totalDelivery = item.totalDelivery/100
                item.startDate = dayjs(item.startDate).format('DD-MM-YYYY HH:mm')
                item.endDate = dayjs(item.endDate).format('DD-MM-YYYY HH:mm')
                switch(item.eventType){
                    case 'rent':
                        item.eventType1 = 'Dostawa';
                        if (item.deliveryType =='delivery'){
                            item.eventType = 'Dostawa';                            
                        }else{
                            item.eventType = 'Wypożycznie Osobiste';
                        }                       
                        break;
                    case 'return': 
                        item.eventType1 = 'Odbiór';                       
                        if (item.deliveryType =='personal') {
                            item.eventType = 'Zwrot Osobisty';
                        } else {
                            item.eventType = 'Odbiór';
                        }                        
                        break;                  
                    case 'transport':
                        item.eventType1 = 'Transport';
                        item.eventType = 'Transport';
                        break; 
                    case 'service':
                        item.eventType = 'Serwis';
                        item.eventType1 = 'Serwis';
                        break;                         
                    case 'other':
                        item.eventType = 'Inne';
                        item.eventType1 = 'Inne';
                        break;                                                                        
                }
                switch (item.paymentType) {
                    case 'cash':
                        item.paymentType = 'Gotówka'
                        break;
                    case 'card':
                        item.paymentType = 'Karta'
                        break;
                    case 'gate':
                        item.paymentType = 'Sklep Online'
                        break;
                    case 'wire':
                        item.paymentType = 'Przelew'
                        break;
                }                
            })
            this.events = raport.data.data.events
            this.loadingHistory = false
            let totalHour = 0
            let totalExtraHour = 0
            
            let totalBuyOtherCard = 0
            let totalBuyOtherCash = 0
            let totalBuyOtherWire = 0
            let totalBuyOtherPanel = 0
            let totalSellOtherCard = 0
            let totalSellOtherCash = 0
            let totalSellOtherWire = 0
            let totalSellOtherPanel = 0            

            let totalSellServiceCash = 0            
            let totalSellServiceCard = 0
            let totalSellServiceWire = 0
            let totalSellServicePanel = 0
            let totalDeliverServiceCash = 0
            let totalDeliverServiceCard = 0
            let totalDeliverServiceWire = 0
            let totalDeliverServicePanel = 0    
            
            let totalSellRentCash = 0
            let totalSellRentCard = 0
            let totalSellRentWire = 0
            let totalSellRentPanel = 0
            let totalDeliverRentCash = 0
            let totalDeliverRentCard = 0
            let totalDeliverRentWire = 0
            let totalDeliverRentPanel = 0

            let totalSellTransportCash = 0
            let totalSellTransportCard = 0
            let totalSellTransportWire = 0
            let totalSellTransportPanel = 0
            let totalDeliverTransportCash = 0
            let totalDeliverTransportCard = 0
            let totalDeliverTransportWire = 0
            let totalDeliverTransportPanel = 0 
            
            let totalSellReturnCash = 0
            let totalSellReturnCard = 0
            let totalSellReturnWire = 0
            let totalSellReturnPanel = 0
            let totalBuyReturnCash = 0
            let totalBuyReturnCard = 0
            let totalBuyReturnWire = 0
            let totalBuyReturnPanel = 0            
            let totalDeliverReturnCash = 0
            let totalDeliverReturnCard = 0
            let totalDeliverReturnWire = 0
            let totalDeliverReturnPanel = 0    
            
            let totalExternalCard = 0
            let totalExternalCash = 0
            let totalExternalWire = 0
            let totalExternalPanel = 0         
            this.returns = []
            this.transports = []
            this.services = []
            this.rents = []
            this.others = []
            this.payouts = []            
            raport.data.data.payouts.forEach((item) => {
                this.payouts.push({
                        numbers: item.payout,
                    title: dayjs(item.raportDate).format('DD-MM-YYYY'),
                    })                
            })
            raport.data.data.raports.forEach((item)=>{
                totalHour += parseFloat(item.normalHour)
                totalExtraHour += parseFloat(item.extraHour)
            })               
            raport.data.data.events.forEach((item) => {                
                switch(item.eventType1){
                    case 'Inne':
                        item.productAddress = item.title
                        switch (item.paymentType) {
                            case 'Karta':
                                totalBuyOtherCard += item.totalBuy || 0
                                totalSellOtherCard += item.totalSell || 0
                                break
                            case 'Gotówka':
                                totalBuyOtherCash += item.totalBuy || 0
                                totalSellOtherCash += item.totalSell || 0
                                break
                            case 'Przelew':
                                totalBuyOtherWire += item.totalBuy || 0
                                totalSellOtherWire += item.totalSell || 0
                                break
                            case 'Sklep Online':
                                totalBuyOtherPanel += item.totalBuy || 0
                                totalSellOtherPanel += item.totalSell || 0
                                break
                        }
                        break;
                    case 'Dostawa':                                                                                                                                                                                                            
                        switch(item.paymentType){
                            case 'Karta':
                                totalSellRentCard += item.totalSell||0
                                totalDeliverRentCard += item.totalDelivery || 0   
                                totalExternalCard += item.extraCost || 0                                
                            break
                            case 'Gotówka':                                
                                totalSellRentCash += item.totalSell ||0
                                totalDeliverRentCash += item.totalDelivery ||0
                                totalExternalCash += item.extraCost || 0                                
                                break
                            case 'Przelew':
                                totalSellRentWire += item.totalSell || 0
                                totalDeliverRentWire += item.totalDelivery || 0 
                                totalExternalWire += item.extraCost || 0                                                               
                                break
                            case 'Sklep Online':
                                totalSellRentPanel += item.totalSell || 0
                                totalDeliverRentPanel += item.totalDelivery || 0
                                totalExternalPanel += item.extraCost || 0                                   
                                break                                                                                            
                        }
                        break;
                    case 'Odbiór':                      
                        switch (item.paymentType) {
                            case 'Karta':
                                totalSellReturnCard += item.totalSell ||0
                                totalDeliverReturnCard += item.totalDelivery || 0
                                totalBuyReturnCard += item.totalBuy || 0
                                break
                            case 'Gotówka':
                                totalSellReturnCash += item.totalSell || 0
                                totalDeliverReturnCash += item.totalDelivery || 0
                                totalBuyReturnCash += item.totalBuy || 0
                                break
                            case 'Przelew':
                                totalSellReturnWire += item.totalSell || 0
                                totalDeliverReturnWire += item.totalDelivery || 0
                                totalBuyReturnWire += item.totalBuy || 0
                                break
                            case 'Sklep Online':
                                totalSellReturnPanel += item.totalSell || 0
                                totalDeliverReturnPanel += item.totalDelivery || 0
                                totalBuyReturnPanel += item.totalBuy || 0
                                break
                        }                        
                        break;                         
                    case 'Serwis':
                        switch (item.paymentType) {
                            case 'Karta':
                                totalSellServiceCard += item.totalSell || 0
                                totalDeliverServiceCard += item.totalDelivery || 0
                                break
                            case 'Gotówka':
                                totalSellServiceCash += item.totalSell || 0
                                totalDeliverServiceCash += item.totalDelivery || 0
                                break
                            case 'Przelew':
                                totalSellServiceWire += item.totalSell || 0
                                totalDeliverServiceWire += item.totalDelivery || 0
                                break
                            case 'Sklep Online':
                                totalSellServicePanel += item.totalSell ||0
                                totalDeliverServicePanel += item.totalDelivery || 0
                                break
                        }                          
                        break;                        
                    case 'Transport':
                        switch (item.paymentType) {
                            case 'Karta':
                                totalSellTransportCard += item.totalSell || 0
                                totalDeliverTransportCard += item.totalDelivery || 0
                                break
                            case 'Gotówka':
                                totalSellTransportCash += item.totalSell || 0
                                totalDeliverTransportCash += item.totalDelivery || 0
                                break
                            case 'Przelew':
                                totalSellTransportWire += item.totalSell ||0
                                totalDeliverTransportWire += item.totalDelivery || 0
                                break
                            case 'Sklep Online':
                                totalSellTransportPanel += item.totalSell || 0
                                totalDeliverTransportPanel += item.totalDelivery || 0
                                break
                        }
                        break;                                                                                                  
                }
            })   
            let totalDeliverService = (totalDeliverServiceCash + totalDeliverServiceCard + totalDeliverServicePanel + totalDeliverServiceWire)
            let totalDeliverRent = (totalDeliverRentCash + totalDeliverRentCard + totalDeliverRentPanel + totalDeliverRentWire)         
            let totalDeliverReturn = (totalDeliverReturnCash + totalDeliverReturnCard + totalDeliverReturnPanel + totalDeliverReturnWire)         
            let totalDeliverTransport = (totalDeliverTransportCash + totalDeliverTransportCard + totalDeliverTransportPanel + totalDeliverTransportWire)                     
            this.raports = [
                {
                    numbers: totalHour,
                    title: "Ilość Godzin",
                },
                {
                    numbers: totalExtraHour,
                    title: "Ilość Nadgodzin",
                },               
                {
                    numbers: (totalDeliverService+totalDeliverRent+totalDeliverReturn+totalDeliverTransport).toFixed(2),
                    title: "Kilometrówka",
                },  
                {
                    numbers: totalSellRentCash + totalSellRentPanel + totalSellRentCard + totalSellRentWire,
                    title: "Łącznie Umowy",
                }, 
                {
                    numbers: totalExternalCard + totalExternalCash + totalExternalWire + totalExternalPanel,
                    title: "Sprzedaż Dostawa",
                },                  
                {
                    numbers: ((totalSellOtherCash + totalSellRentCash + totalSellReturnCash + totalSellServiceCash + totalSellTransportCash) + (totalDeliverRentCash + totalDeliverReturnCash + totalDeliverServiceCash + totalDeliverTransportCash) - (totalBuyOtherCash) ).toFixed(2),
                    title: "Łącznie Gotówka",
                },                                                 
                {
                    numbers: (totalSellOtherCard + totalSellRentCard + totalSellReturnCard + totalSellServiceCard + totalSellTransportCard) + (totalDeliverRentCard + totalDeliverReturnCard + totalDeliverServiceCard + totalDeliverTransportCard),
                    title: "Łącznie Karta",
                },
                {
                    numbers: (totalSellOtherWire + totalSellRentWire + totalSellReturnWire + totalSellServiceWire + totalSellTransportWire) + (totalDeliverRentWire + totalDeliverReturnWire + totalDeliverServiceWire + totalDeliverTransportWire),
                    title: "Łącznie Przelewy",
                },
                {
                    numbers: (totalSellOtherPanel + totalSellRentPanel + totalSellReturnPanel + totalSellServicePanel + totalSellTransportPanel) + (totalDeliverRentPanel + totalDeliverReturnPanel + totalDeliverServicePanel + totalDeliverTransportPanel),
                    title: "Łącznie Sklep Online",
                },              
                {
                    numbers: (totalSellOtherCard+totalSellOtherCash+totalSellOtherPanel+totalSellOtherWire),
                    title: "Inne Przychody",
                },
                {
                    numbers: totalBuyOtherCash + totalBuyOtherCard + totalBuyOtherPanel + totalBuyOtherWire,
                    title: "Inne Wydatki",
                },                 
                {
                    numbers: totalSellReturnCard+totalSellReturnCash+totalSellReturnPanel+totalSellReturnWire,
                    title: "Przychód Odbiór",
                }, 
                {
                    numbers: totalBuyReturnWire+totalBuyReturnCash+totalBuyReturnPanel+totalBuyReturnCard,
                    title: "Koszt Odbiór"
                },                               
                {
                    numbers: totalSellServiceCard + totalSellServiceCash + totalSellServicePanel + totalSellServiceWire + totalDeliverServiceCard + totalSellServiceCash + totalSellServicePanel + totalSellServiceWire,
                    title: "Łącznie Serwis",
                }, 
                {
                    numbers: totalSellTransportCard + totalSellTransportCash + totalSellTransportPanel + totalSellTransportWire,
                    title: "Łącznie Transport",
                },                                                                                                                                                                                               
            ]                                  
        },
        async toggleLoading(type) {
            const toggle = type;
            localStorage.setItem("loading", toggle);
            window.dispatchEvent(
                new CustomEvent("toggleLoading", {
                    detail: {
                        loading: localStorage.getItem("loading"),
                        overlay: localStorage.getItem("loading"),
                    },
                })
            );
        },
        async getUsers() {
            await this.toggleLoading(true);
            this.user = null;
            try {
                let users = await axios({
                    method: "get",
                    url:
                        process.env.VUE_APP_ROOT_API +
                        "/warehouse/user-list/" +
                        this.warehouse,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });              
                users.data.data.forEach((item) => {
                    item.fullName = item.firstName + " " + item.lastName;
                });
                this.users = users.data.data;
            } catch (e) {
                console.log(e);
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            await this.toggleLoading(false);
        },
        async getWarehouse() {
            await this.toggleLoading(true);
            try {
                let warehouse = await axios({
                    method: "get",
                    url: process.env.VUE_APP_ROOT_API + `/warehouse?sort=${'id'}&direction=${false}&page=${1}&limit=${100}&wh=raports`,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                if(warehouse.data.data.items.length>5){
                    warehouse.data.data.items.unshift(warehouse.data.data.items.splice(4, 1)[0]);
                    warehouse.data.data.items.push(warehouse.data.data.items.splice(4, 1)[0]);
                }

                this.warehouses = warehouse.data.data.items;
                this.warehouse = warehouse.data.data.items[0].id
                await this.getUsers()
            } catch (e) {
                console.log(e);
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            await this.toggleLoading(false);
        },
        async initialize() {
            await this.getWarehouse();
        },        
    }  
}
</script>