<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-data-table :options.sync="options"  :server-items-length="totalRecords" 
          :loading="loading"  :headers="headers" :items="rails" class="elevation-1" :sort-desc="true">
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>Szyny</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                    </template>
                    <template v-slot:item.accept="{ item }">
                        <v-btn class="primary"
                            @click="acceptDeal(item.id)"><v-icon small class="pt-1">mdi-check</v-icon></v-btn>                        
                    </template>
                    <template v-slot:item.pending="{ item }">
                        <v-btn class="warning" @click="markAsPending(item.id)"><v-icon small class="pt-1">mdi-archive-clock</v-icon></v-btn>
                    </template>
                    <template v-slot:item.reject="{ item }">
                        <v-btn class="danger"
                            @click="rejectDeal(item.id)"><v-icon small class="pt-1">mdi-delete</v-icon></v-btn>                        
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
const dayjs = require("dayjs");
const axios = require("axios");
import Swal from "sweetalert2";

export default {
    name: "Rails",
    data: () => ({
        totalRecords: 0,
        loading: false,
        options: {},        
        headers: [
            {
                text: "Data Dodania Zlecenia",
                sortable: true,                            
                value: "created_at",
            },
            {
                text: "Dane Kontaktowe",
                align: "start",
                sortable: true,
                value: "clientInfo",
            },
            {
                text: "Aktualny Status",
                align: "start",
                sortable: false,
                value: "currentStatus",
            },            
            { text: "", value: "accept", sortable: false },
            { text: "", value: "pending", sortable: false },
            { text: "", value: "reject", sortable: false },
        ],
        rails: [],
    }),

    watch: {
        options: {
            async handler() {
                await this.initialize()
            },
            deep: true,
        },
    },
    methods: {
        async toggleLoading(type) {
            const toggle = type;
            localStorage.setItem("loading", toggle);
            window.dispatchEvent(
                new CustomEvent("toggleLoading", {
                    detail: {
                        loading: localStorage.getItem("loading"),
                        overlay: localStorage.getItem("loading"),
                    },
                })
            );
        },
        async acceptDeal(id) {
            await this.toggleLoading(true);
            try {
                await axios({
                    method: "patch",
                    url: process.env.VUE_APP_ROOT_API + "/rails/update-rails-event/" + id,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    data: {
                        isAccepted: true,
                        isRejected: false,
                        isPending: false,
                    },
                });
                await this.initialize();
            } catch (e) {
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            await this.toggleLoading(false);
        },
        async rejectDeal(id) {
            await this.toggleLoading(true);
            try {
                await axios({
                    method: "patch",
                    url: process.env.VUE_APP_ROOT_API + "/rails/update-rails-event/" + id,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    data: {
                        isRejected: true,
                        isAccepted: false,
                        isPending: false,
                    },
                });
                await this.initialize();
            } catch (e) {
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            await this.toggleLoading(false);
        },
        async markAsPending(id) {
            await this.toggleLoading(true);
            try {
                await axios({
                    method: "patch",
                    url: process.env.VUE_APP_ROOT_API + "/rails/update-rails-event/" + id,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    data: {
                        isRejected: false,
                        isAccepted: false,
                        isPending: true,
                    },
                });
                await this.initialize();
            } catch (e) {
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            await this.toggleLoading(false);
        },        
        async initialize() {
            this.loading = true  
            let { sortBy, sortDesc, page, itemsPerPage } = this.options             

            try {
                let rails = await axios({
                    method: "get",
                    url: process.env.VUE_APP_ROOT_API + `/rails/get-my-rails?sort=${sortBy.length ? sortBy : 'created_at'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}`,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                this.rails = [];
                rails.data.data.items.forEach((item) => {
                    item.show = false
                    if (item.isAccepted) {
                        item.show = true
                    }
                    if (item.isRejected) {
                        item.show = true
                    }
                    if (item.isPending) {
                        item.show = true
                    }                    
                    item.currentStatus = 'Nowe'
                    if(item.isAccepted){
                        item.currentStatus = 'Zaakceptowane'
                    }
                    if(item.isRejected){
                        item.currentStatus = 'Rezygnacja'
                    }
                    if (item.isPending) {
                        item.currentStatus = 'Wstrzymane'
                    }                    
                    const offset = new Date().getTimezoneOffset();
                    item.showBtnAccept= item.isAccepted,
                    item.showBtnReject= item.isRejected,
                    item.showBtnPending = item.isPending,
                    item.created_at= dayjs(item.created_at).add(Math.abs(offset / 60), 'hours').format('YYYY-MM-DD HH:mm')
                });
                this.rails = rails.data.data.items
                this.itemsPerPage = rails.data.data.meta.itemsPerPage
                this.page = rails.data.data.meta.currentPage
                this.totalRecords = rails.data.data.meta.totalItems                 
            } catch (e) {
                console.log(e);
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            this.loading = false  
        },
    },
};
</script>