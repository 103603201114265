<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-data-table :headers="headers"           :options.sync="options"
          :server-items-length="totalRecords"
          :loading="loading" :items="invoices" class="elevation-1">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Faktury do Wystawienia</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn class="primary" @click="makeDone(item.id)"
              ><v-icon small class="pt-1">mdi-check</v-icon></v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
const dayjs = require("dayjs");
const axios = require("axios");
import Swal from "sweetalert2";

export default {
  name: "Invoice",
  data: () => ({
    options: {},
    totalRecords: 0,
    loading: false,     
    headers: [
      {
        text: "Data Dodania Płatności",
        sortable: false,
        value: "paymentDate",
      },
      {
        text: "Numer Umowy",
        align: "start",
        sortable: false,
        value: "dealNo",
      },      
      {
        text: "Klient",
        align: "start",
        sortable: false,
        value: "rname",
      },            
      {
        text: "Dane do Faktury",
        align: "start",
        sortable: true,
        value: "invoiceData",
      },
      {
        text: "Typ Faktury",
        align: "start",
        sortable: true,
        value: "invoiceType",
      },   
      {
        text: "Uwagi",
        align: "start",
        sortable: true,
        value: "paymentNote",
      },            
      {
        text: "Kwota",
        align: "start",
        sortable: true,
        value: "price",
      },            
      { text: "", value: "actions", sortable: false },
    ],
    orders: [],
    invoices: [],
  }),

  async created() {    
  },
  watch: {
    options: {
      async handler() {
        await this.initialize()
      },
      deep: true,
    },
  },
  methods: {
    async toggleLoading(type) {
      const toggle = type;
      localStorage.setItem("loading", toggle);
      window.dispatchEvent(
        new CustomEvent("toggleLoading", {
          detail: {
            loading: localStorage.getItem("loading"),
            overlay: localStorage.getItem("loading"),
          },
        })
      );
    },
    async makeDone(id) {
      await this.toggleLoading(true);      
      try {
        Swal.fire({
          title: "Napewno Wystawiono?",
          text: "",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Tak",
          cancelButtonText: "Anuluj",
        }).then(async (result) => {
          if (result.isConfirmed) {        
        await axios({
          method: "patch",
          url: process.env.VUE_APP_ROOT_API + "/payments/mark-invoice/" + id,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: {
            invoiceDone: true,
          },
        });
        await this.initialize();
          }
        });        
      } catch (e) {
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      await this.toggleLoading(false);
    },
    async initialize() {
      this.loading = true
      let { sortBy, sortDesc, page, itemsPerPage } = this.options      
      try {
        let invoices = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/payments/get-invoices?sort=${sortBy.length ? sortBy : 'paymentDate'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.invoices = [];
        if (invoices.data.data.items){
          invoices.data.data.items.forEach((item) => {
            this.invoices.push({
              paymentDate: dayjs(item.paymentDate).format("YYYY-MM-DD"),
              invoiceData: item.rents.invoiceData,
              rname: item.rents.firstName+' '+item.rents.lastName,
              dealNo: item.rents.dealNo,
              price: item.totalToPay/100,
              id: item.id,
              invoiceType: item.rents.invoiceType == 'personal' ? 'Osoba Fizyczna' : 'Firma'
            });
          });  
        }      
        this.itemsPerPage = invoices.data.data.meta.itemsPerPage
        this.page = invoices.data.data.meta.currentPage
        this.totalRecords = invoices.data.data.meta.totalItems          
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      this.loading = false
    },
  },
};
</script>