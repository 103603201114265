<template>
    <v-container>
        <v-row>
<v-col cols="12" >
    <v-select label="Rodzaj Odbioru Z/O" v-model="returnType" :items="returnTypes" item-text="name" item-value="id"
        required :rules="[(v) => !!v || 'Uzupełnij Pole']"></v-select>
</v-col>
<v-col cols="12" >
    <v-select v-model="selectedproducts" @change="getClient()" :items="itemsToReturn" item-text="rentName" item-value="id" attach chips
        label="Przedmioty Do Odbioru" multiple>
        <template #item="{ item }">
            <span v-if="item.color == 'red'" style="color:white; background:red">{{ item.rentName }}</span>
            <span v-if="item.color == 'green'" style="color:white; background:green">{{ item.rentName }}</span>
        </template>
        <template v-slot:prepend-item>
            <v-list-item>
                <v-list-item-content>
                    <v-text-field v-model="searchTerm" placeholder="Szukaj" @input="searchItems">
                    </v-text-field>
                </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
        </template>
    </v-select>
</v-col>
<v-col cols="12" >
    <vuetify-google-autocomplete id="map" required append-icon="mdi-search" classname="form-control"
        placeholder="Adres Odbioru"  ref="addr" v-on:placechanged="getAddressData"
        v-model="returnAddr" country="pl" autocomplete="new-password">
    </vuetify-google-autocomplete>
</v-col>
<v-col cols="12" >
    <v-text-field autocomplete="new-password" label="Numer Mieszkania" v-model="returnAddrHelperApt"></v-text-field>
</v-col>
<v-col cols="12" >
    <v-text-field autocomplete="new-password" label="Ilość KM" v-model="returnKmLengthHelper" disabled></v-text-field>
</v-col>
<v-col cols="12" autocomplete="new-password" >
    <v-text-field v-model="returnPhoneCaller" label="Telefon do Kontaktu" required autocomplete="new-password"
        :rules="[(v) => !!v || 'Uzupełnij Pole']"></v-text-field>
</v-col>
<v-col cols="12">
    <v-text-field label="Kilometrówka" v-model="totalDeliveryReturn" type="number" step="0.1"
        autocomplete="new-password" required></v-text-field>
</v-col>
<v-col cols="12" >
    <v-text-field label="Przychód z odbioru" v-model="totalSellReturn" type="number" step="0.1" autocomplete="new-password" required></v-text-field>
</v-col>
                    <v-col cols="12">
                        <v-checkbox v-model="showTransport" label="Darmowy Odbiór?"> </v-checkbox>
                    </v-col>
    </v-row>
    </v-container>
</template>
<script
  type="text/javascript"
  src="http://maps.google.com/maps/api/js?key=AIzaSyDGlgz880rqzKKuiWTugR3JsWRjCtjdH_g&sensor=false&libraries=places&language=pl-PL"
></script>
<script>
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";
import Swal from "sweetalert2";
const axios = require("axios");
const dayjs = require("dayjs");
export default {
    components: {
        VuetifyGoogleAutocomplete, // make the <FullCalendar> tag available
    },       
    props: ['user', 'warehouse', 'getEvents', 'dialogCreate', 'eventNote', 'startDate', 'startTime', 'endTime', 'rentsIds'],
    data() {
        return {
            returnTypes: [
            {
                id: "personal",
                name: "Zwrot osobisty",
            },
            {
                id: "return",
                name: "Odbiór",
            }],   
            totalDeliveryReturn:0,         
            searchTerm:'',
            returnType:'',  
            selectedproducts:[],
            itemsToReturn:[],
            returnAddr:'',
            returnAddrHelper:'',
            returnAddrHelperApt:'',
            returnKmLengthHelper:0,
            returnPhoneCaller:'',            
            totalSellReturn:0,      
            showTransport:false,         
        }
    },
    async created(){
        if (this.rentsIds) {                   
                let ids = [];
                let arrs = [];
                for (let a = 0; a < this.rentsIds.length; a++) {
                let products = await axios({
                    method: "get",
                    url:
                    process.env.VUE_APP_ROOT_API +
                    "/rents/find-by-id/" +
                    this.rentsIds[a].id,
                    headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });              
                arrs.push({
                    rentName:
                    products.data.data.dealNo +
                    "-" +
                    (products.data.data.products.name)+(products.data.data.isMateracPianka?'*':''),
                    id: products.data.data.id,
                });              
                ids.push(products.data.data.id);
                }                         
                this.itemsToReturn = arrs;                
                this.selectedproducts = ids;   
                await this.getClient()                
        }        
    },
    methods:{
        async createReturn(){
          if(this.returnType != 'personal'){
            if(!this.returnAddr){
              console.log('Adres Odbioru nie jest wymagany')
              Swal.fire("Błąd", 'Uzupełnij Adres Odbioru', "error");
              return false
            }                        
          }          
          if(!this.returnType){
            Swal.fire("Błąd", 'Uzupełnij Typ Odbioru', "error");
            return false
          }
          if(!this.selectedproducts.length){
            Swal.fire("Błąd", 'Wybierz Produkty do odbioru', "error");
            return false            
          }
          if(!this.returnPhoneCaller.trim().length){
            Swal.fire("Błąd", 'Uzupełnij Telefon Kontaktowy', "error");
            return false
          } 
          await this.toggleLoading(true);
          try {
            const data = {
              startDate:
                dayjs(this.startDate).format("YYYY-MM-DD") +
                " " +
                this.startTime,
              endDate:
                dayjs(this.startDate).format("YYYY-MM-DD") + " " + this.endTime,
              modNote: this.eventNote,
              eventType: "return",              
              user: this.user[0],
              warehouse:this.warehouse,
              showDeliveryCost:this.showTransport,
              rentedProductId: this.selectedproducts,
              deliveryType: this.returnType,
              totalDelivery: this.totalDeliveryReturn||0,
              totalSell:this.totalSellReturn||0,
              callerPhone: this.returnPhoneCaller,              
              returnAddress: this.returnType=='personal'?null:this.returnAddr,
              returnAddressApt: this.returnType=='personal'?null:this.returnAddrHelperApt,
              returnAddressGeoLat: this.returnType=='personal'?null:this.returnAddrHelper.latitude,
              returnAddressGeoLng: this.returnType=='personal'?null:this.returnAddrHelper.longitude,              
            };
            await axios({
              method: "post",
              url: process.env.VUE_APP_ROOT_API + "/events/return",
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
              data: data,
            }); 
             this.showTransport = false           
            this.$emit('closeDialogCreate')
            await this.getEvents();            
          }catch(e){
            console.log(e);
            Swal.fire("Błąd", e.response.statusText, "error");
          }
          await this.toggleLoading(false);
        },
        async toggleLoading(type) {
            const toggle = type;
            localStorage.setItem("loading", toggle);
            window.dispatchEvent(
                new CustomEvent("toggleLoading", {
                detail: {
                    loading: localStorage.getItem("loading"),
                    overlay: localStorage.getItem("loading"),
                },
                })
            );
        },  
        getDistance(start, end) {
        const origin = new google.maps.LatLng(start[0], start[1]);
        const final = new google.maps.LatLng(end[0], end[1]);
        const service = new google.maps.DistanceMatrixService();

        return new Promise((resolve, reject) => {
            service.getDistanceMatrix(
            {
                origins: [origin],
                destinations: [final],
                travelMode: "DRIVING",
            },
            (response, status) => {
                if (status === "OK") {
                resolve({
                    distance: (
                    response.rows[0].elements[0].distance.value / 1000
                    ).toFixed(2),
                });
                } else {
                reject(new Error("Not OK"));
                }
            }
            );
        });
        },               
        getAddressData: async function (addressData) {
            await this.toggleLoading(true);
            try {
                let warehouse = await axios({
                method: "get",
                url:
                    process.env.VUE_APP_ROOT_API +
                    "/warehouse/get-by-id/" +
                    this.warehouse,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                });
                let dist = await this.getDistance(
                [warehouse.data.data[0].getLat, warehouse.data.data[0].geoLng],
                [addressData.latitude, addressData.longitude]
                );                
                if(this.returnType!='personal'){
                    this.returnKmLengthHelper = dist.distance;
                }else{
                    this.returnKmLengthHelper=0
                }                   
                this.returnAddrHelper = addressData;
            } catch (e) {
                console.log(e);
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            await this.toggleLoading(false);
        },
        async searchItems() {
        await this.toggleLoading(true);
        try {
            if (this.searchTerm.length) {
            let products = await axios({
                method: "get",
                url:
                process.env.VUE_APP_ROOT_API +
                "/rents/find-rents/" +
                this.searchTerm,
                headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            if(products.data.data){
                products.data.data.forEach((item) => {            
                    if (dayjs(item.paidToDate).diff(dayjs().format("YYYY-MM-DD")) < 0){
                    item.color = 'red'
                    }else{
                    item.color = 'green'
                    }
                    item.rentName =
                    item.dealNo +
                    "-" +
                    (item.products?item.products.name:'')+(item.isMateracPianka?'*':'') + ' Ważne: ' + dayjs(item.paidToDate).diff(dayjs().format("YYYY-MM-DD"),'days') + (item.accData.length ? ('U: '+item.accData) : '');
                });
                this.itemsToReturn = products.data.data;
            }
            }
        } catch (e) {
            console.log(e);
            Swal.fire("Błąd", e.response.statusText, "error");
        }
        await this.toggleLoading(false);
        },  
        async getClient(){
                let products = await axios({
                    method: "get",
                    url:
                    process.env.VUE_APP_ROOT_API +
                    "/rents/find-by-id/" +
                    this.selectedproducts[0],
                    headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }); 
                this.returnAddr = products.data.data.productAddress
                this.returnKmLengthHelper = products.data.data.kmLength/100
                this.returnAddrHelperApt = products.data.data.productAddressApt                
                this.returnPhoneCaller = products.data.data.callerPhone
                this.totalDeliveryReturn = products.data.data.events?  (products.data.data.events.totalDelivery/100):0                       
        }               
    }
}
</script>