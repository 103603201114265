<template>
    <v-container>
        <v-row>
            <v-col cols="6">
                <v-select label="Wybierz Magazyn" v-model="warehouse" :items="warehouses" item-text="name"
                    item-value="id" @change="getUsers()"></v-select>
            </v-col>
            <v-col cols="6">
                <v-select label="Wybierz Użytkownika" v-model="user" :items="users" item-text="fullName" item-value="id"
                    @change="getEvents()"></v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" style="height: 650px">
                <FullCalendar ref="fullCalendar" style="height: 650px" :options="calendarOptions" />
            </v-col>
        </v-row>
        <v-menu v-model="quickDetailsDialog" :close-on-content-click="false" style="width: 100%">
            <v-card color="dark" flat>
                <v-toolbar color="green" dark>
                    <v-toolbar-title>Szczegóły Wydarzenia</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-container>
                <v-sheet color="warning" rounded="rounded" class="mx-auto">
                    Numer Umowy: {{ dealNoInfo }}<br />
                    Adres Sprzętu: {{ deliveryProductAddrInfo }}<br />
                    Adres Transportu: {{ transportProductAddrInfo }}<br />
                    Telefon Do Kontaktu: <a :href="'tel: '+clientPhoneInfo">{{ clientPhoneInfo }}</a><br />
                    Telefon Dzwoniącego: <a :href="'tel: ' + callerPhoneInfo">{{ callerPhoneInfo }}</a><br />
                    Uwagi: {{ noteInfo }}<br />
                    Uwagi Kierowcy: {{ noteInfoDriver }}<br />
                    Data Aktualizacji Rekordu: {{ updateDate }}<br />
                    Produkty: {{ productsStr }}
                </v-sheet>
                </v-container>
                <v-card-actions>
                    <v-btn text color="secondary" @click="quickDetailsDialog = false">
                        Anuluj
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-menu>
    </v-container>
</template>
<script>
// ES6 Modules or TypeScript
import Swal from "sweetalert2";
import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import plLocale from "@fullcalendar/core/locales/pl";
const axios = require("axios");
const dayjs = require("dayjs");

export default {
    name: "TimeTable",
    components: {
        FullCalendar,
    },
    data() {
        return {
            dealNoInfo:'',
            deliveryProductAddrInfo:'',
            transportProductAddrInfo:'',
            clientPhoneInfo:'',
            callerPhoneInfo:'',
            noteInfo:'',
            noteInfoDriver:'',
            updateDate:'',
            productsStr:'',
            quickDetailsDialog: false,
            warehouse: '',
            warehouses: [],
            user: '',
            users: [],
            calendarOptions: {
                customButtons: {
                    prev: { // this overrides the prev button
                        text: "Poprzedni",
                        click: async () => {
                            console.log("eventPrev");
                            let calendarApi = this.$refs.fullCalendar.getApi();
                            calendarApi.prev();
                            await this.getEvents()
                        }
                    },
                    next: { // this overrides the next button
                        text: "Następny",
                        click: async () => {
                            console.log("eventNext");
                            let calendarApi = this.$refs.fullCalendar.getApi();
                            calendarApi.next();
                            await this.getEvents()
                        }
                    },
                },
                plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
                headerToolbar: {
                    left: "prev,today,next",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay,timeGridFourDay",
                },
                events: [],
                views: {
                    timeGridFourDay: {
                        type: "timeGrid",
                        duration: {
                            days: 3,
                        },
                        buttonText: "3 Dniowy",
                    },
                },
                businessHours: {
                    daysOfWeek: [1, 2, 3, 4, 5, 6, 7], // Monday - Thursday
                    // days of week. an array of zero-based day of week integers (0=Sunday)
                    startTime: "06:00", // a start time (10am in this example)
                    endTime: "22:00", // an end time (6pm in this example)
                },
                displayEventTime: false,
                selectable: true,
                longPressDelay: 750,
                locale: plLocale,
                buttonIcons: false, // show the prev/next text
                allDaySlot: false,
                weekNumbers: false,
                draggable: false,
                disableDragging: false,
                disableResizing: false,
                initialView: "timeGridFourDay",
                navLinks: true, // can click day/week names to navigate views
                editable: false,
                dayMaxEvents: true, // allow "more" link when too many events                
                eventClick: this.handleEventClick,
            },
        }
    },
    async created() {
        await this.initialize();
    },
    methods: {
        async toggleLoading(type) {
            const toggle = type;
            localStorage.setItem("loading", toggle);
            window.dispatchEvent(
                new CustomEvent("toggleLoading", {
                    detail: {
                        loading: localStorage.getItem("loading"),
                        overlay: localStorage.getItem("loading"),
                    },
                })
            );
        },
        async initialize() {
            await this.getWarehouse();
        },
        async handleEventClick(ev) {            
            const event = await axios({
                method: "get",
                url: process.env.VUE_APP_ROOT_API + "/events/" + ev.event._def.publicId,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            const rent = await axios({
                method: "get",
                url:
                    process.env.VUE_APP_ROOT_API +
                    "/rents/get-for-event/" +
                    ev.event._def.publicId,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            if (event.data.data.eventType != 'other') {
                this.noteInfo = event.data.data.modNote
                this.quickNote = event.data.data.modNote
                this.noteInfoDriver = event.data.data.deliveryNote
                this.userQuick = event.data.data.user.id;
                this.quickEditTimeStart = dayjs(event.data.data.startDate).format(
                    "HH:mm"
                );
                this.quickEditDataStart = new Date(event.data.data.startDate)
                    .toISOString()
                    .substr(0, 10);
                this.updateDate = dayjs(event.data.data.updated_at).format('YYYY-MM-DD HH:mm')
                this.quickEditTimeEnd = dayjs(event.data.data.endDate).format("HH:mm");
                this.dealNoInfo = rent.data.data[0].dealNo ? rent.data.data[0].dealNo : 'Brak';
                this.clientPhoneInfo = rent.data.data[0].clientPhone ? rent.data.data[0].clientPhone : 'Brak'
                switch (rent.data.data[0].selectedPhone) {
                    case 'contact':
                        this.clientPhoneInfo = rent.data.data[0].clientPhone ? rent.data.data[0].clientPhone : 'Brak'
                        break;
                    case 'caller':
                        this.clientPhoneInfo = rent.data.data[0].callerPhone ? rent.data.data[0].callerPhone : 'Brak'
                        break;
                    case 'other':
                        this.clientPhoneInfo = rent.data.data[0].otherPhone ? rent.data.data[0].otherPhone : 'Brak'
                        break;
                }
                this.callerPhoneInfo = rent.data.data[0].callerPhone ? rent.data.data[0].callerPhone : 'Brak'
                this.deliveryProductAddrInfo = rent.data.data[0].productAddress + ' Mieszkanie: ' + rent.data.data[0].productAddressApt
                this.transportProductAddrInfo = rent.data.data[0].transportAddress ? (rent.data.data[0].transportAddress + ' Mieszkanie: ' + rent.data.data[0].transportAddressApt) : 'N/D'
                let productsStr = ''
                this.productsStr = ''

                if (rent.data.data.length) {
                    rent.data.data.forEach((item) => {
                        if (item.products.name == 'Łóżko') {
                            if (item.isMateracPianka) {
                                productsStr = productsStr + item.products.name + '*, '
                            } else {
                                productsStr = productsStr + item.products.name + ', '
                            }
                        } else {
                            productsStr = productsStr + item.products.name + ', '
                        }
                    })
                }
                this.productsStr = productsStr
            } else {
                this.noteInfo = event.data.data.modNote
                this.quickNote = event.data.data.modNote
                this.noteInfoDriver = event.data.data.deliveryNote
                this.userQuick = event.data.data.user.id;
                this.quickEditTimeStart = dayjs(event.data.data.startDate).format(
                    "HH:mm"
                );
                this.quickEditDataStart = new Date(event.data.data.startDate)
                    .toISOString()
                    .substr(0, 10);
                this.updateDate = dayjs(event.data.data.updated_at).format('YYYY-MM-DD HH:mm')
                this.quickEditTimeEnd = dayjs(event.data.data.endDate).format("HH:mm");
            } 
            this.quickDetailsDialog = true;
        },
        async getWarehouse() {
            await this.toggleLoading(true);
            try {
                let warehouse = await axios({
                    method: "get",
                    url: process.env.VUE_APP_ROOT_API + `/warehouse?sort=${'id'}&direction=${false}&page=${1}&limit=${100}`,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                if (warehouse.data.data.items.length > 5) {
                    warehouse.data.data.items.unshift(warehouse.data.data.items.splice(4, 1)[0]);
                    warehouse.data.data.items.push(warehouse.data.data.items.splice(4, 1)[0]);
                }
                this.warehouses = warehouse.data.data.items;
                this.warehouse = warehouse.data.data.items[0].id
                this.whColor = warehouse.data.data.items[0].color
                await this.getUsers()
            } catch (e) {
                console.log(e);
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            await this.toggleLoading(false);
        },
        async getUsers() {
            await this.toggleLoading(true);
            this.user = null;
            try {
                let users = await axios({
                    method: "get",
                    url:
                        process.env.VUE_APP_ROOT_API +
                        "/warehouse/user-list/" +
                        this.warehouse,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                const wh = await axios({
                    method: "get",
                    url:
                        process.env.VUE_APP_ROOT_API +
                        "/warehouse/get-by-id/" +
                        this.warehouse,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                })
                this.whColor = wh.data.data[0].color
                users.data.data.forEach((item) => {
                    item.fullName = item.firstName + " " + item.lastName;
                });
                this.users = users.data.data;
            } catch (e) {
                console.log(e);
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            await this.toggleLoading(false);
        },
        async getEvents() {
            await this.toggleLoading(true);
            try {
                let events = await axios({
                        method: "get",
                        url:
                            process.env.VUE_APP_ROOT_API +
                            "/events/get-events-for-user/" +
                            this.user + '/' + this.warehouse +
                            "/" +
                            dayjs(this.$refs.fullCalendar.getApi().view.currentStart).format(
                                "YYYY-MM-DD"
                            ) +
                            "/" +
                            dayjs(this.$refs.fullCalendar.getApi().view.currentEnd).format(
                                "YYYY-MM-DD"
                            ),
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                    });

                let ev = [];
                events.data.data.forEach(async (item) => {
                    const rents = await axios({
                        method: "get",
                        url:
                            process.env.VUE_APP_ROOT_API +
                            "/rents/get-for-event/" +
                            item.id,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                    });
                    let productsStr = ''
                    for (let a = 0; a < rents.data.data.length; a++) {
                        productsStr += rents.data.data[a].products.name
                    }                    
                    let str = "";
                    if (item.eventType == "rent") {
                        if (item.deliveryType == "rent") {
                            str = "W: " + productsStr;
                        } else {
                            str = "D: " + rents.data.data[0].productAddress;
                        }
                    } else if (item.eventType == "return") {
                        if (item.deliveryType == "personal") {
                            str = "Z: " + productsStr;
                        } else {
                            str = "O: " + rents.data.data[0].productAddress;
                        }
                    } else if (item.eventType == "service") {
                        str = "S: " + rents.data.data[0].productAddress;
                    } else if (item.eventType == "transport") {
                        str = "T: " + rents.data.data[0].productAddress;
                    } else {
                        str = item.title;
                    }
                    if (this.showOptions) {
                        ev.push({
                            id: item.id,
                            title: (item.isDone ? '✅ ' : '❌ ') + str,
                            color: item.warehouse.color || this.whColor || "blue",
                            textColor: "black",
                            start: item.startDate,
                            end: item.endDate,
                        });
                    } else {
                        ev.push({
                            id: item.id,
                            title: (item.isDone ? '✅ ' : '❌ ') + str,
                            color: item.user.color || "blue",
                            textColor: "black",
                            start: item.startDate,
                            end: item.endDate,
                        });
                    }

                });
                this.calendarOptions.events = ev;
            } catch (e) {
                console.log(e);
                Swal.fire("Błąd", e.response.statusText || e, "error");
            }
            await this.toggleLoading(false);
        },  
    }
}
</script>