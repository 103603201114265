<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>MedApp</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form  ref="form"
                        v-model="valid"
                        lazy-validation>
              <v-text-field
                prepend-icon="mdi-account"
                name="login"
                v-model="username"
                label="E-mail"
                :rules="emailRules"
                type="text"
              ></v-text-field>
              <v-text-field
                id="password"
                v-model="password"
                prepend-icon="mdi-lock"
                name="password"
                label="Hasło"
                type="password"
                :rules="passwordRules"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" :disabled="!valid" @click="login()">Zaloguj</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
const axios = require("axios");
import Swal from "sweetalert2";
import Vue from "vue";
import { VueReCaptcha } from "vue-recaptcha-v3";
Vue.use(VueReCaptcha, {
  siteKey: "6Lc7Z-ciAAAAAAd--5gKnnrGlZk5FPmnSqfx4RbP", loaderOptions: {
    useRecaptchaNet: true,
    autoHideBadge: true
  } });

export default {
  name: "Login",
  data: () => ({
    valid: true,
    password: "",
    username: "",
    emailRules: [
      v => !!v || 'Podaj Poprawny Adres E-mail',
      v => /.+@.+\..+/.test(v) || 'Podaj Poprawny Adres E-mail',
    ],
    passwordRules: [
      v => !!v || 'Hasło Jest Wymagane',
    ],     
  }),
  props: {
    source: String,
  },
  methods: {
    async login() {
      try {
        await this.$recaptchaLoaded()
        const token = await this.$recaptcha('login')  
        let user = await axios({
          method: "post",
          url: process.env.VUE_APP_ROOT_API + "/auth/login",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
          data: {
            email: this.username,
            password: this.password,
            token:token
          },
        });        
        localStorage.setItem("user", true);
        localStorage.setItem("token", user.data.access_token);
        localStorage.setItem("role", user.data.role);
        localStorage.setItem("userid", user.data.id);
        localStorage.setItem("whid", user.data.whid);
        window.location.href = "/";
      } catch (e) {
        Swal.fire("Błąd", e.response.statusText, "error");
        localStorage.setItem("user", false);
        localStorage.setItem("token", null);
        localStorage.setItem("role", null);
        localStorage.setItem("userid", null);
        localStorage.setItem("whid",null);
      }
    },
  },
};
</script>