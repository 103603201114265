<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-sheet color="grey lighten-3">
          <v-sheet :elevation="2" rounded>
            <v-row class="pa-5">
              <v-col cols="12">
                <v-text-field
                 type="date"
                 background-color="secondary"
                  label="Data Dodania Płatności"
                  v-model="paymentDate" 
                  @input="initialize()"                 
                ></v-text-field>
              </v-col>               
              <!-- <v-col cols="12">
                <v-btn
                  class="primary"
                  style="width: 100%"
                  @click="initialize()"
                  ><v-icon>mdi-account-search</v-icon></v-btn
                >
              </v-col>                                              -->
            </v-row>
          </v-sheet>
        </v-sheet>
      </v-col>
      <v-spacer></v-spacer>           
      <v-col cols="12">
        <v-data-table :loading="loading" :headers="headers" :items="payments" class="elevation-1">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Historia Płatności</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>        
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
const dayjs = require("dayjs");
const axios = require("axios");
import Swal from "sweetalert2";

export default {
  name: "PaymentsHistory",
  data: () => ({
    loading: false,    
    headers: [
      {
        text: "Data Płatności",
        sortable: false,
        value: "paymentDate",
      },
      {
        text: "Numer Umowy",
        align: "start",
        sortable: true,
        value: "dealNo",
      },
      {
        text: "Kwota Płatności",
        align: "start",
        sortable: true,
        value: "totalToPay",
      }      
    ],    
    payments: [],
    paymentDate:new Date().toISOString().substr(0, 10)
  }),

  async created() {
    await this.initialize();
  },

  methods: {
    async toggleLoading(type) {
      const toggle = type;
      localStorage.setItem("loading", toggle);
      window.dispatchEvent(
        new CustomEvent("toggleLoading", {
          detail: {
            loading: localStorage.getItem("loading"),
            overlay: localStorage.getItem("loading"),
          },
        })
      );
    },   
    async initialize() {
      this.loading = true    
      try {
        let payments = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "/payments/get-payments-for-date/"+this.paymentDate,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.payments = [];
        payments.data.data.forEach((item) => {        
          this.payments.push({
            id: item.id,
            paymentDate: dayjs(item.paymentDate).format("YYYY-MM-DD"),
            totalToPay: item.totalToPay/100,            
            dealNo:item.rents.dealNo            
          });          
        });
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      this.loading = false    
    },
  },
};
</script>