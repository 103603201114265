<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-sheet color="grey lighten-3">
          <v-sheet :elevation="2" rounded>
            <v-row class="pa-5">
              <v-col cols="12">
                <v-text-field
                  label="Dane Klienta"
                  v-model="clientInfo"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-btn
                  class="primary"
                  style="width: 100%"
                  @click="createRails()"
                  ><v-icon>mdi-content-save</v-icon></v-btn
                >
              </v-col>
               <v-spacer></v-spacer>   
                <v-col cols="12">
                <v-text-field
                    label="Data"
                    type="date"
                    background-color="secondary"
                    v-model="startDate"                                
                ></v-text-field>
                </v-col>
                <v-col cols="12">
                <v-text-field
                    label="Data"
                    type="date"
                    background-color="secondary"
                    v-model="endDateSearch"                                
                ></v-text-field>
                </v-col> 
                <v-col cols="12">
                                              <v-select item-text="name" item-value="id" v-model="railType" :items="railsType" label="Status">
                                              </v-select>
                </v-col>               
              <v-col cols="12">
                <v-btn
                  class="primary"
                  style="width: 100%"
                  @click="initialize()"
                  ><v-icon>mdi-account-search</v-icon></v-btn
                >
              </v-col>                                             
            </v-row>
          </v-sheet>
        </v-sheet>
      </v-col>
      <v-spacer></v-spacer>        
      <v-col cols="12">
        <v-data-table :headers="headers" :loading="loading" :items="rails" class="elevation-1">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Szyny</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:item.accept="{ item }">
            <p  v-if="item.showBtnAccept">Zaakceptowane</p>          
          </template>
          <template v-slot:item.pending="{ item }">
            <p v-if="item.showBtnPending">Wstrzymane</p>
          </template>
          <template v-slot:item.reject="{ item }">
            <p  v-if="item.showBtnReject">Odrzucone</p>
          </template>          
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
const dayjs = require("dayjs");
const axios = require("axios");
import Swal from "sweetalert2";

export default {
  name: "Rails",
  data: () => ({
    loading: false,      
    headers: [
      {
        text: "Data Utworzenia Zlecenia",
        sortable: true,
        value: "created_at",
      },
      {
        text: "Dane Kontaktowe",
        align: "start",
        sortable: true,
        value: "clientInfo",
      },
      { text: "", value: "accept", sortable: false },
      { text: "", value: "pending", sortable: false },
      { text: "", value: "reject", sortable: false },      
    ], 
    railType: 'null',
    railsType: [
      {
        id: 'accepted',
        name: 'Zaakceptowane'
      },
      {
        id: 'rejected',
        name: 'Odrzucone'
      },
      {
        id: 'pending',
        name: 'Wstrzymane'
      },
      {
        id: 'null',
        name: 'Wszystkie'
      },      
    ],         
    rails: [],  
    clientInfo:'',
    startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
    endDateSearch: dayjs().endOf('month').format('YYYY-MM-DD'),
  }),

  async created() {
    await this.initialize()    
  },

  methods: {
    async toggleLoading(type) {
      const toggle = type;
      localStorage.setItem("loading", toggle);
      window.dispatchEvent(
        new CustomEvent("toggleLoading", {
          detail: {
            loading: localStorage.getItem("loading"),
            overlay: localStorage.getItem("loading"),
          },
        })
      );
    },   
    async createRails() {
      await this.toggleLoading(true);      
      try {
        await axios({
          method: "post",
          url: process.env.VUE_APP_ROOT_API + "/rails/create-rails-event",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: {
            clientInfo: this.clientInfo,            
          },
        });
        await this.initialize();
        this.clientInfo = ''        
      } catch (e) {
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      await this.toggleLoading(false);
    },  
    async initialize() {      
      this.loading = true                  
      try {
        let rails = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "/rails/get-rails-for-user/" + this.startDate + '/' + this.endDateSearch + '/' +this.railType,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.rails = [];
        rails.data.data.forEach((item) => {
          let show = false
          if(item.isAccepted){
            show = true
          }
          if(item.isRejected){
            show = true
          } 
          if (item.isPending) {
            show = true
          }           
          const offset = new Date().getTimezoneOffset();         
          this.rails.push({

            created_at: dayjs(item.created_at).add(Math.abs(offset / 60), 'hours').format('YYYY-MM-DD HH:mm'),
            clientInfo: item.clientInfo,
            id: item.id,
            showBtnAccept:item.isAccepted,
            showBtnReject:item.isRejected,
            showBtnPending: item.isPending,
            show:show
          });          
        });
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      this.loading = false
    },
  },
};
</script>