<template>
  <v-container>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="products"
          :search="search"
           :options.sync="options"
          class="elevation-1"
          :server-items-length="totalRecords" 
          :loading="loading"                     
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Baza Sprzętu</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>              
            </v-toolbar>
            <div>
              <v-text-field v-model="search" label="Szukaj" class="mx-4"></v-text-field>
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn class="info" @click="showHistory(item.id)"
              ><v-icon small class="pt-1">mdi-file-image</v-icon></v-btn
            >
          </template>
        </v-data-table>
      </v-col>
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Galeria</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-img :src="imageUrl" aspect-ratio="1" class="grey lighten-2">
                </v-img>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import Swal from "sweetalert2";
const axios = require("axios");

export default {
  name: "Service",
  data: () => ({
    search:'',
    options: {
    },
    totalRecords: 0,
    loading: false,     
    dealNo: "",
    lastName: "",
    address: "",
    dialog: false,
    headers: [
      {
        text: "Numer Umowy",
        align: "start",
        sortable: false,
        value: "dealNo",
      },
      {
        text: "Nazwisko",
        align: "start",
        sortable: false,
        value: "lastName",
      },
      {
        text: "Adres",
        align: "start",
        sortable: false,
        value: "address",
      },
      {
        text: "Sprzęt",
        align: "start",
        sortable: false,
        value: "product",
      },
      { text: "", value: "actions", sortable: false },
    ],
    products: [],
    imageUrl: "",
  }),

  async created() {
  },
  watch: {
    options: {
      async handler() {
        await this.initialize()
      },
      deep: true,
    },
    async search() {
      this.options.page = 1 
      await this.initialize()
    }    
  },

  methods: {
    async toggleLoading(type) {
      const toggle = type;
      localStorage.setItem("loading", toggle);
      window.dispatchEvent(
        new CustomEvent("toggleLoading", {
          detail: {
            loading: localStorage.getItem("loading"),
            overlay: localStorage.getItem("loading"),
          },
        })
      );
    },
    async initialize() {
      this.loading = true
      let { sortBy, sortDesc, page, itemsPerPage } = this.options 
      try {
        let rents = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/rents/get-table?sort=${sortBy.length ? sortBy : 'dealNo'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}&search=${this.search}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.products=[]
        rents.data.data.items.forEach((item) => {
          this.products.push({
            id: item.id,
            dealNo: item.dealNo,
            lastName: item.lastName,
            address: item.clientAddress,
            product: item.products.name,
          });
        });
        this.itemsPerPage = rents.data.data.meta.itemsPerPage
        this.page = rents.data.data.meta.currentPage
        this.totalRecords = rents.data.data.meta.totalItems         
      } catch (e) {
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      this.loading = false
    },
    toBase64(arr) {
  //arr = new Uint8Array(arr) if it's an ArrayBuffer
    return btoa(
      arr.reduce((data, byte) => data + String.fromCharCode(byte), '')
    );
  } ,  
    async showHistory(id) {
      await this.toggleLoading(true);
      this.imageUrl = "";
      try {
        let rents = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "/rents/get-image/" + id,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });        
        this.imageUrl = 'data:image/jpeg;base64,' + this.toBase64(rents.data.data.Body.data);
        this.dialog = true;
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      await this.toggleLoading(false);
    },
  },
};
</script>