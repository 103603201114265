import Vue from 'vue'
import VueRouter from 'vue-router'
import Calendar from '../views/Calendar.vue'
import Login from '../views/Login.vue'
import Warehouse from '../views/Warehouse.vue'
import Store from '../views/Store.vue'
import Users from '../views/Users.vue'
import Payments from '../views/Payments.vue'
import Invoice from '../views/Invoice.vue'

import Clients from '../views/Clients.vue'
import Hours from '../views/Hours.vue'
import Service from '../views/Service.vue'
import Raports from '../views/Raports.vue'
import MyRails from '../views/MyRails.vue'
import Rails from '../views/Rails.vue'
import PaymentsHistory from '../views/PaymentsHistory.vue'
import InvoiceHistory from '../views/InvoiceHistory.vue'
import Archive from '../views/Archive.vue'
import Receipts from '../views/Receipts.vue'
import ReceiptsHistory from '../views/ReceiptHistory.vue'
import TimeTable from '../views/TimeTable.vue'
import Personal from '../views/Personal.vue'
import Company from '../views/Company.vue'
import Mattress from '../views/Mattress.vue'
import Stats from '../views/Stats.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/calendar',
    name: 'Calendar',
    component: Calendar,   
  },
  {
    path: '/stats',
    name: 'Stats',
    component: Stats,   
  },  
  {
    path: '/personal',
    name: 'Personal',
    component: Personal,   
  },  
  {
    path: '/dirty',
    name: 'Mattress',
    component: Mattress,   
  },  
  {
    path: '/company',
    name: 'Company',
    component: Company,   
  },  
  {
    path: '/timetable',
    name: 'TimeTable',
    component: TimeTable,   
  },  
  {
    path: '/raports',
    name: 'Raports',
    component: Raports,     
  },  
  {
    path: '/hours',
    name: 'Hours',
    component: Hours,   
  },  
  {
    path: '/service',
    name: 'Service',
    component: Service,     
  },   
  {
    path: '/day',
    name: 'Day',
    component: Calendar
  },  
  {
    path: '/clients',
    name: 'Clients',
    component: Clients,   
  },  
  {
    path: '/users',
    name: 'Users',
    component: Users,    
  },    
  {
    path: '/store',
    name: 'Store',
    component: Store,   
  },  
  {
    path: '/payments',
    name: 'Payments',
    component: Payments,   
  },
  {
    path: '/payments-history',
    name: 'PaymentsHistory',
    component: PaymentsHistory,   
  }, 
  {
    path: '/receipts-history',
    name: 'ReceiptsHistory',
    component: ReceiptsHistory,   
  },   
  {
    path: '/invoice-history',
    name: 'InvoiceHistory',
    component: InvoiceHistory,   
  },      
  {
    path: '/invoice',
    name: 'Invoice',
    component: Invoice,    
  },  
  {
    path: '/warehouse',
    name: 'Warehouse',
    component: Warehouse,   
  }, 
  {
    path: '/receipts',
    name: 'Receipts',
    component: Receipts,   
  },   
  {
    path: '/archive',
    name: 'Archive',
    component: Archive,   
  },    
  {
    path: '/my-rails',
    name: 'MyRails',
    component: MyRails,   
  }, 
  {
    path: '/rails',
    name: 'Rails',
    component: Rails,   
  },        
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to,from,next) => {
  if (to.name !== 'Login' && localStorage.getItem('user')!='true'){
     next({ name: 'Login' })
  }else{
    if(to.fullPath=='/'){
      let role = localStorage.getItem('role');
      if(role=='user'){
        location.href='/day'
      }else if(role == 'account'){
        location.href='/payments'
      }else if(role == 'rails'){
        location.href='/my-rails'     
      }
      if(role=='admin'){
        location.href='/calendar'      
      }
      next()
    }else{
      next()
    }
  }

})
export default router
