<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-data-table           :options.sync="options"
          :server-items-length="totalRecords"
          :loading="loading"  :headers="headers" :items="dirty" class="elevation-1">
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>Brudne Materace</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                        </v-toolbar>

                    </template>
                    <template v-slot:item.sel="{ item }">
                        <v-select v-model="actionSelect[item.id]" item-text="name" item-value="id"
                            :items="warehouses[item.id]">
                        </v-select>
                    </template>
                    <template v-slot:item.actions1="{ item }">
                        <v-btn class="warning" @click="changeWh(item.id)">
                            <v-icon small class="mr-2"> Zmień </v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-btn class="primary" @click="returnMatress(item.id)">
                            <v-icon small class="mr-2"> Przywróć </v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
const axios = require("axios");
import Swal from "sweetalert2";
import * as dayjs from 'dayjs'
export default {
    name: "Company",
    data: () => ({
        options: {},
        totalRecords: 0,
        loading: false,         
        currentCompanyId: null,
        companyName: '',
        dialogCreate: false,
        dialog: false,
        warehouses:[],
        actionSelect:[],        
        headers: [
            {
                text: "Numer Umowy",
                align: "start",
                sortable: false,
                value: "dealNo",
            },
            {
                text: "Magazyn Pochodzenia",
                align: "start",
                sortable: false,
                value: "whName",
            }, 
            {
                text: "Zmieniono na:",
                align: "start",
                sortable: false,
                value: "warehouseNote",
            },             
            {
                text: "Przedmiot",
                align: "start",
                sortable: false,
                value: "product",
            },                         
            {
                text: "Data Ostatniej Aktualizacji",
                align: "start",
                sortable: false,
                value: "updated_at",
            },                       
            { text: "Nowy Magazyn", value: "sel", sortable: false },
            { text: "", value: "actions1", sortable: false },
            { text: "", value: "actions", sortable: false },
            
            
        ],
        dirty: [],
    }),
    watch: {
        options: {
            async handler() {
                await this.initialize()
            },
            deep: true,
        },
    },
    async created() {                    
    },

    methods: {
        async toggleLoading(type) {
            const toggle = type;
            localStorage.setItem("loading", toggle);
            window.dispatchEvent(
                new CustomEvent("toggleLoading", {
                    detail: {
                        loading: localStorage.getItem("loading"),
                        overlay: localStorage.getItem("loading"),
                    },
                })
            );
        },
        async returnMatress(id){
            try {
                Swal.fire({
                    title: "Przywrócić na magazyn?",
                    text: "",
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Tak",
                    cancelButtonText: "Anuluj",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await axios({
                            method: "post",
                            url: process.env.VUE_APP_ROOT_API + "/rents/return-dirty",
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("token"),
                            },
                            data: {
                                warehouse: this.actionSelect[id],
                                rentId: id,
                            },
                        });    
                        await this.initialize()                    
                    }
                })
            } catch (e) {
                console.log(e);
                Swal.fire("Błąd", e.response.statusText, "error");
            }
        },
        async changeWh(id) {
            try {
                Swal.fire({
                    title: "Zmienić magazyn?",
                    text: "",
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Tak",
                    cancelButtonText: "Anuluj",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await axios({
                            method: "post",
                            url: process.env.VUE_APP_ROOT_API + "/rents/change-dirty",
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("token"),
                            },
                            data: {
                                warehouse: this.actionSelect[id],
                                rentId: id,
                            },
                        });
                        await this.initialize()
                    }
                })
            } catch (e) {
                console.log(e);
                Swal.fire("Błąd", e.response.statusText, "error");
            }
        },        
        async initialize() {
            const wh = await this.getWarehouseSelect()    
            this.loading = true
            let { sortBy, sortDesc, page, itemsPerPage } = this.options 
            try {
                let recs = await axios({
                    method: "get",
                    url: process.env.VUE_APP_ROOT_API + `/rents/get-dirty-records?sort=${sortBy.length ? sortBy : 'dealNo'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}`,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                recs.data.data.items.forEach((item)=>{
                    this.warehouses[item.id] = wh
                    this.actionSelect[item.id] = item.products.warehouse.id
                    item.whName = item.products.warehouse.name
                    item.product = item.products.name
                    const offset = new Date().getTimezoneOffset();                    
                    item.updated_at = dayjs(item.updated_at).add(Math.abs(offset / 60), 'hours').format('YYYY-MM-DD HH:mm')                    
                })
                this.dirty = recs.data.data.items;
                this.itemsPerPage = recs.data.data.meta.itemsPerPage
                this.page = recs.data.data.meta.currentPage
                this.totalRecords = recs.data.data.meta.totalItems  
            } catch (e) {
                console.log(e)
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            this.loading = false
        },
        async getWarehouseSelect(){            
            try {
                let warehouse = await axios({
                    method: "get",
                    url: process.env.VUE_APP_ROOT_API + `/warehouse?sort=${'id'}&direction=${false}&page=${1}&limit=${100}`,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                if (warehouse.data.data.items.length > 5) {
                    warehouse.data.data.items.unshift(warehouse.data.data.items.splice(4, 1)[0]);
                    warehouse.data.data.items.push(warehouse.data.data.items.splice(4, 1)[0]);
                }              
               return warehouse.data.data.items;
            } catch (e) {  
                console.log(e)              
                Swal.fire("Błąd", e.response.statusText, "error");
            }            
        }
    },
};
</script>