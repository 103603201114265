<template>
  <v-container>
    <UpdateRentForm ref="updateRentForm" v-if="showComponent" :id="currentId" :records="event" :warehouse="warehouse" :getEvents="initializeForm">
    </UpdateRentForm>
    <v-row>
      <v-col cols="12">
        <v-select
          label="Wybierz Magazyn"
          v-model="warehouse"
          :items="warehouses"
          item-text="name"
          item-value="id"  
          @change = "getProducts()"                  
        ></v-select>
      </v-col>
                    <v-col cols="12">
                        <v-select @change="initForm()" v-model="eventTypeModel" :items="EventTypes" label="Typ wydarzenia"
                            :rules="[(v) => !!v || 'Uzupełnij Pole']"></v-select>
                    </v-col>
      </v-row>
    <v-row v-if="eventTypeModel == 'Wypożyczenie Osobiste'">
                    <v-col cols="12">
                        <h3>Produkty</h3>
                        <v-list subheader two-line>
                            <v-list-item v-for="product in products" :key="product.id">
                                <v-row>
                                    <v-col cols="6">
                                        <v-list-item-content>
                                            <v-list-item-title v-text="product.name"></v-list-item-title>
                                            <v-list-item-title :class="product.color" v-text="product.subtitle">
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-list-item-action class="text-center">
                                            <v-list-item-title>
                                                <v-text-field type="number" label="Ilość" placeholder="Ilość" autocomplete="new-password"
                                                    v-model="qtyInput[product.id]">
                                                </v-text-field>
                                            </v-list-item-title>
                                        </v-list-item-action>
                                    </v-col>
                                </v-row>
                            </v-list-item>
                        </v-list>
                    </v-col>
    </v-row>
      <v-row v-if="eventTypeModel == 'Zwrot Osobisty'">
    <v-col cols="12">
        <v-select v-model="selectedproducts" :items="itemsToReturn" item-text="rentName"
            item-value="id" attach chips label="Przedmioty Do Odbioru" multiple>
            <template #item="{ item }">
                <span v-if="item.color == 'red'" style="color:white; background:red">{{ item.rentName }}</span>
                <span v-if="item.color == 'green'" style="color:white; background:green">{{ item.rentName }}</span>
            </template>
            <template v-slot:prepend-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-text-field v-model="searchTerm" placeholder="Szukaj" @input="searchItems">
                        </v-text-field>
                    </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
            </template>
        </v-select>
    </v-col>
                            <v-col cols="12">
                                <v-text-field v-model="totalSellReturn" autocomplete="new-password" type="number" value="0"
                                    label="Dodatkowy Przychód Odbioru" :rules="[(v) => v>=0 || 'Uzupełnij Pole']"></v-text-field>
                            </v-col>
    <v-col cols="12">
        <v-text-field label="Dodatkowy Koszt Odbioru" v-model="totalBuyReturn" type="number" step="0.1" autocomplete="new-password"
            required></v-text-field>
    </v-col>
    </v-row>
                        <v-col cols="12">
                            <v-select item-text="name" item-value="id" v-model="paymentType" :items="paymentTypes" label="Rodzaj Płatności">
                            </v-select>
                        </v-col>
    <v-col cols="12">
        <v-textarea label="Notatka" v-model="deliveryNote"></v-textarea>
    </v-col>
    <v-col cols="12" v-if="eventTypeModel == 'Zwrot Osobisty'">
        <v-btn class="success" @click="createReturn()" style="width:100%;">Wykonano</v-btn>
    </v-col>
    <v-col cols="12" v-if="eventTypeModel == 'Wypożyczenie Osobiste'">
        <v-btn class="success" @click="createRent()" style="width:100%;">Dalej</v-btn>
    </v-col>
</v-container>
</template>    
<script>
import Swal from "sweetalert2";
const axios = require("axios");
import * as dayjs from 'dayjs'
import UpdateRentForm from '../components/update/update.rent.form.vue'

export default {
  name: "Raports",
    components: {
        UpdateRentForm
    },
    data: () => ({
        totalSellReturn:0,
        totalBuyReturn:0,      
        showComponent:true,
        paymentType: null,
        paymentTypes: [
            {
                id: 'cash',
                name: 'Gotówka'
            },
            {
                id: 'card',
                name: 'Karta'
            },
            {
                id: 'gate',
                name: 'Sklep Online'
            },
            {
                id: 'wire',
                name: 'Przelew'
            },
        ],       
        deliveryNote:'',
        products: [],        
        searchTerm: '',
        selectedproducts: [],
        itemsToReturn: [],        
        warehouse: null,
        warehouses: [],
        EventTypes: ["Wypożyczenie Osobiste", "Zwrot Osobisty"],
        eventTypeModel: '',   
        eventNote: '',   
        qtyInput: [],
        currentId:null,
        event:{}  
    }),
    async created() {
        await this.initialize()
    },  
  methods:{
      async initializeForm(){
        this.totalSellReturn= 0
        this.totalBuyReturn= 0,          
        this.deliveryNote= '',
        this.paymentType= null,
        this.paymentTypes= [
            {
                id: 'cash',
                name: 'Gotówka'
            },
            {
                id: 'card',
                name: 'Karta'
            },
            {
                id: 'gate',
                name: 'Sklep Online'
            },
            {
                id: 'wire',
                name: 'Przelew'
            },
        ]   
          this.showComponent = true
        this.products= []
        this.totalSellReturn=0
        this.searchTerm= ''
        this.selectedproducts= []
        this.itemsToReturn= []        
        this.warehouse= null
        this.EventTypes= ["Wypożyczenie Osobiste","Zwrot Osobisty"]
        this.warehouses= []
        this.eventTypeModel= ''   
        this.eventNote= ''   
        this.currentId=null
        this.qtyInput= []
        this.event={} 
        await this.getWarehouse()
      },
    async createRent(){
        if (!this.paymentType) {
            Swal.fire("Błąd", 'Uzupełnij Płatność', "error");
            return false
        } 
        let productsId = [];
        try{
            let products = await axios({
                method: "get",
                url: process.env.VUE_APP_ROOT_API + "/products/" + this.warehouse + `/?sort=${'id'}&direction=${false}&page=${1}&limit=${100}`,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            products.data.data.items.forEach((item) => {
                if (this.qtyInput[item.id] > 0) {
                    for (let a = 0; a < this.qtyInput[item.id]; a++) {
                        productsId.push(item.id);
                    }
                }
            });
            if (!productsId.length) {
                Swal.fire("Błąd", 'Ilość produktu', "error");
                return false
            }   
            await this.toggleLoading(true);
            const data = {
                warehouse: this.warehouse,
                rentedProductId: productsId,
                paymentType: this.paymentType,
                deliveryNote:this.deliveryNote
            }
            // const created = 
            await axios({
                method: "post",
                url: process.env.VUE_APP_ROOT_API + "/events/rent-personal",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                data: data,
            });  
            location.href='/calendar'
            // setTimeout(async ()=>{
            //     const event = await axios({
            //         method: "get",
            //         url: process.env.VUE_APP_ROOT_API + "/events/" + created.data.data.id,
            //         headers: {
            //             Authorization: "Bearer " + localStorage.getItem("token"),
            //         },
            //     });
            //     this.event = event.data.data
            //     this.currentId = event.data.data.id
            //     this.$refs.updateRentForm.showRentForm(event.data.data)
            // },100)                    
            await this.toggleLoading(true);
        }catch(e){
            console.log(e);
            Swal.fire("Błąd", e.response.statusText, "error");            
        }
    },
    async createReturn(){
        if (!this.selectedproducts.length) {
            Swal.fire("Błąd", 'Wybierz Produkty do odbioru', "error");
            return false
        }   
        if (this.totalSellReturn < 0) {
            Swal.fire("Błąd", 'Uzupełnij Przychód Odbioru', "error");
            return false
        }
        if (this.totalBuyReturn < 0) {
            Swal.fire("Błąd", 'Uzupełnij Koszt Odbioru', "error");
            return false
        }             
        await this.toggleLoading(true);
        try{
            const data = {
                warehouse: this.warehouse,
                rentedProductId: this.selectedproducts,                
                totalSell: parseFloat(this.totalSellReturn),
                totalBuy: parseFloat(this.totalBuyReturn),
                deliveryNote: this.deliveryNote,
                paymentType:this.paymentType,
            }
            await axios({
                method: "post",
                url: process.env.VUE_APP_ROOT_API + "/events/return-personal",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                data: data,
            });       
            location.href = '/calendar'      
        }catch(e){
            console.log(e);
            Swal.fire("Błąd", e.response.statusText, "error");
        }
        this.initializeForm()
        await this.toggleLoading(false);
    },
      async toggleLoading(type) {
          const toggle = type;
          localStorage.setItem("loading", toggle);
          window.dispatchEvent(
              new CustomEvent("toggleLoading", {
                  detail: {
                      loading: localStorage.getItem("loading"),
                      overlay: localStorage.getItem("loading"),
                  },
              })
          );
      },  
      async initForm(){
        if(this.eventTypeModel == 'Wypożyczenie Osobiste'){
            await this.getProducts()
        }
      },
      async getProducts() {
          
          await this.toggleLoading(true);
          this.products = [];
          try {
              let products = await axios({
                  method: "get",
                  url: process.env.VUE_APP_ROOT_API + "/products/" + this.warehouse + `/?sort=${'id'}&direction=${false}&page=${1}&limit=${100}`,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token"),
                  },
              });   
              const ids = products.data.data.items.map(item => item.id)

              let bed = await axios({
                  method: "get",
                  url: process.env.VUE_APP_ROOT_API + "/rents/rented-beds/" + ids.toString() + '/' + dayjs().format('YYYY-MM-DD HH:mm'),
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token"),
                  },
              });            
              if(this.warehouse == 2 || this.warehouse == 8){
                  const products1 = await axios({
                      method: "get",
                      url: process.env.VUE_APP_ROOT_API + "/products/" + (this.warehouse == 2 ? 8:2) + `/?sort=${'id'}&direction=${false}&page=${1}&limit=${100}`,
                      headers: {
                          Authorization: "Bearer " + localStorage.getItem("token"),
                      },
                  });
                  const ids1 = products1.data.data.items.map(item => item.id)
                  let bed1 = await axios({
                      method: "get",
                      url: process.env.VUE_APP_ROOT_API + "/rents/rented-beds/" + ids1.toString() + '/' + dayjs().format('YYYY-MM-DD HH:mm'),
                      headers: {
                          Authorization: "Bearer " + localStorage.getItem("token"),
                      },
                  }); 
                  for (let a = 0; a < products.data.data.items.length;a++){
                      let rented = 0
                      let rentIndex = bed.data.data.rents.find(it => it.id == products.data.data.items[a].id)
                      let rentIndex1 =  bed1.data.data.rents.find(it => it.id == products1.data.data.items[a].id);                    
                      rented = (rentIndex ? parseInt(rentIndex.sumRents) : 0) + (rentIndex1 ? parseInt(rentIndex1.sumRents) : 0)                    
                      let returns = 0
                      let returnIndex = bed.data.data.returns.find(it => it.id == products.data.data.items[a].id) 
                      let returnIndex1 = bed1.data.data.returns.find(it => it.id == products1.data.data.items[a].id);                                  
                      returns = (returnIndex ? parseInt(returnIndex.sumReturns) : 0) + (returnIndex1 ? parseInt(returnIndex1.sumReturns) : 0)                                            
                      let returns_till = 0
                      let returnTillIndex = bed.data.data.returns_till_date.find(it => it.id == products.data.data.items[a].id)
                      let returnTillIndex1 = bed1.data.data.returns_till_date.find(it => it.id == products1.data.data.items[a].id);
                      returns_till = (returnTillIndex ? parseInt(returnTillIndex.sumReturns) : 0) + (returnTillIndex1 ? parseInt(returnTillIndex1.sumReturns) : 0)
                      products.data.data.items[a].subtitle = ` ${products.data.data.items[a].qty - rented + returns_till} | ${products.data.data.items[a].qty - rented + returns} | ${products.data.data.items[a].qty} | ${returns} | ${rented}`
                      if ((products.data.data.items[a].qty - rented + returns) > 0) {
                          products.data.data.items[a].color = "green";
                      } else {
                          products.data.data.items[a].color = "red";
                      }                  
                  }                  
              }else{
                  products.data.data.items.forEach((item) => {
                      let rented = 0
                      let rentIndex = bed.data.data.rents.find(it => it.id == item.id);
                      if (rentIndex) {
                          rented = parseInt(rentIndex.sumRents)
                      }
                      let returns = 0
                      let returnIndex = bed.data.data.returns.find(it => it.id == item.id);
                      if (returnIndex) {
                          returns = parseInt(returnIndex.sumReturns)
                      }
                      let returns_till = 0
                      let returnTillIndex = bed.data.data.returns_till_date.find(it => it.id == item.id);
                      if (returnTillIndex) {
                          returns_till = parseInt(returnTillIndex.sumReturns)
                      }
                      item.subtitle = ` ${item.qty - rented + returns_till} | ${item.qty - rented + returns} | ${item.qty} | ${returns} | ${rented}`
                      if ((item.qty - rented + returns) > 0) {
                          item.color = "green";
                      } else {
                          item.color = "red";
                      }
                  });
              }                              
              this.products = products.data.data.items;
          } catch (e) {
              console.log(e);
              Swal.fire("Błąd", e.response.statusText, "error");
          }
          await this.toggleLoading(false);
      },       
      async searchItems() {
          await this.toggleLoading(true);
          try {
              if (this.searchTerm.length) {
                  let products = await axios({
                      method: "get",
                      url:
                          process.env.VUE_APP_ROOT_API +
                          "/rents/find-rents/" +
                          this.searchTerm,
                      headers: {
                          Authorization: "Bearer " + localStorage.getItem("token"),
                      },
                  });
                  if (products.data.data) {
                      products.data.data.forEach((item) => {
                          if (dayjs(item.paidToDate).diff(dayjs().format("YYYY-MM-DD")) < 0) {
                              item.color = 'red'
                          } else {
                              item.color = 'green'
                          }
                          item.rentName =
                              item.dealNo +
                              "-" +
                              (item.products ? item.products.name : '') + (item.isMateracPianka ? '*' : '') + ' Ważne: ' + dayjs(item.paidToDate).diff(dayjs().format("YYYY-MM-DD"), 'days') + (item.accData.length ? ('U: '+item.accData) : '');
                      });
                      this.itemsToReturn = products.data.data;
                  }
              }
          } catch (e) {
              console.log(e);
              Swal.fire("Błąd", e.response.statusText, "error");
          }
          await this.toggleLoading(false);
      },            
        async getWarehouse() {
            await this.toggleLoading(true);
            try {
                let warehouse = await axios({
                    method: "get",
                    url: process.env.VUE_APP_ROOT_API + `/warehouse?sort=${'id'}&direction=${false}&page=${1}&limit=${100}&wh=raports`,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });                
                this.warehouses = warehouse.data.data.items;
                this.warehouse = warehouse.data.data.items[0].id                
            } catch (e) {
                console.log(e);
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            await this.toggleLoading(false);
        },
        async initialize() {
            await this.getWarehouse();
        },
    }     
}
</script>        