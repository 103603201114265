<template>
    <v-dialog transition="dialog-bottom-transition" fullscreen persistent hide-overlay v-model="fullDetailsDialog">
        <v-toolbar dark color="primary">
            <v-btn icon dark @click="fullDetailsDialog = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Szczegóły</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-card>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-sheet color="brown darken-4" rounded="rounded" class="mx-auto">                    
                    Numer Umowy: {{ dealNoInfo }}<br />
Adres Sprzętu: <a :href="'http://maps.google.com/?q='+deliveryProductAddrInfo" target="_blank">{{
deliveryProductAddrInfo }}</a><br />
Adres Transportu: <a :href="'http://maps.google.com/?q=' + transportProductAddrInfo" target="_blank">{{
transportProductAddrInfo }}</a><br />                    
                    Telefon Do Kontaktu: <a :href="'tel: '+clientPhoneInfo">{{ clientPhoneInfo }}</a><br />
                    Telefon Dzwoniącego: <a :href="'tel: ' + callerPhoneInfo">{{ callerPhoneInfo }}</a><br />                    
                    Uwagi: {{ noteInfo }} <br />
                    Uwagi Kierowcy: {{ noteInfoDriver }}<br />
                    Produkt: {{productsStr}}
                </v-sheet>
            </v-col>
        </v-row>
    </v-container>
    <v-stepper v-model="e1">
            <v-stepper-step step="5" editable>
                Formularz Transportu
            </v-stepper-step>
            <v-stepper-content step="5">
                <v-form>
                    <v-row>
                <v-col cols="8">
                    <v-text-field disabled label="Adres Transportu" v-model="transportAddress"></v-text-field>
                
                </v-col>
                <v-col cols="4">
                    <v-btn class="primary" style="width:100%;" @click="showTransportAddress = !showTransportAddress">Zmień</v-btn>
                </v-col>
                <v-col cols="12" v-if="showTransportAddress">
                    <vuetify-google-autocomplete id="map1" required autocomplete="new-password" append-icon="mdi-search"
                        classname="form-control" placeholder="Nowy Adres" ref="addr" v-model="transportAddressNew"
                        v-on:placechanged="getAddressData" country="pl">
                    </vuetify-google-autocomplete>
                </v-col>
                        <v-col cols="12">
                            <v-text-field label="Numer Mieszkania" v-model="transportAddressApt"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field autocomplete="new-password" label="Kwota Transportu" type="number"
                                :rules="[(v) => v>=0 || 'Uzupełnij Pole']" v-model="totalTransportPrice"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-select item-text="name" item-value="id" v-model="paymentType" :items="paymentTypes" label="Rodzaj Płatności">
                            </v-select>
                        </v-col>
                        <v-col cols="12"  class="text-center">
                            <v-textarea label="Notatka Pracownika" v-model="transportNote" autocomplete="new-password">
                            </v-textarea>
                            <v-btn color="warning" @click="updateNote()">Aktualizuj Notatkę</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
                <div>
                    <v-btn v-if="isAdmin" color="warning" class="float-center" @click="saveTransport(true)">
                        Edytuj
                    </v-btn>
                    <v-btn v-if="showButton" color="green" class="float-right" @click="saveTransport()">
                        Wykonano
                    </v-btn>                    
                </div>
            </v-stepper-content>
    </v-stepper>
        </v-card>
    </v-dialog>
</template>
<script
  type="text/javascript"
  src="http://maps.google.com/maps/api/js?key=AIzaSyDGlgz880rqzKKuiWTugR3JsWRjCtjdH_g&sensor=false&libraries=places&language=pl-PL"
></script>
<script>
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";
import Swal from "sweetalert2";
const axios = require("axios");
import * as dayjs from 'dayjs'
var isToday = require('dayjs/plugin/isToday')
dayjs.extend(isToday)
export default {
    components: {
        VuetifyGoogleAutocomplete, // make the <FullCalendar> tag available
    },  
    props: ['id', 'getEvents','warehouse'],
    data(){
        return { 
            isAdmin:false,
            transportAddress:'',
            transportAddressHelper:'',
            showTransportAddress:false,
            transportAddressNew:'',           
            showButton:false,
            dealNoInfo:'',
            fullDetailsDialog:false,
            deliveryProductAddrInfo:'',
            transportProductAddrInfo:'',
            clientPhoneInfo:'',
            callerPhoneInfo:'',
            noteInfo:'',
            noteInfoDriver:'',
            productsStr:'',
            e1:5,
            transportAddress:'',
            transportAddressApt:'',
            totalTransportPrice:0,        
            transportNote:'',
            paymentType: null,
            paymentTypes: [
                {
                    id: 'cash',
                    name: 'Gotówka'
                },                
                {
                    id: 'card',
                    name: 'Karta'
                },
                {
                    id: 'gate',
                    name: 'Sklep Online'
                },
                {
                    id: 'wire',
                    name: 'Przelew'
                },
            ]          
        }     
    },
    created(){
        const role = localStorage.getItem("role")
        if (role == 'admin') {
            this.isAdmin = true
        }else{
            this.isAdmin = false
        }
    },    
    methods: {
        initData(){
            this.transportAddress=''
            this.transportAddressHelper=''
            this.showTransportAddress=false
            this.transportAddressNew=''           
            this.showButton=false
            this.dealNoInfo=''
            this.fullDetailsDialog=false
            this.deliveryProductAddrInfo=''
            this.transportProductAddrInfo=''
            this.clientPhoneInfo=''
            this.callerPhoneInfo=''
            this.noteInfo=''
            this.noteInfoDriver=''
            this.productsStr=''
            this.e1=5
            this.transportAddress=''
            this.transportAddressApt=''
            this.totalTransportPrice=0        
            this.transportNote=''
            this.paymentType= null
            this.paymentTypes= [
                {
                    id: 'cash',
                    name: 'Gotówka'
                },                
                {
                    id: 'card',
                    name: 'Karta'
                },
                {
                    id: 'gate',
                    name: 'Sklep Online'
                },
                {
                    id: 'wire',
                    name: 'Przelew'
                },
            ]               
        },
        getAddressData: async function (addressData) {
            await this.toggleLoading(true);
            try {
                this.transportAddressHelper = addressData;
            } catch (e) {
                console.log(e);
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            await this.toggleLoading(false);
        },         
        async toggleLoading(type) {
            const toggle = type;
            localStorage.setItem("loading", toggle);
            window.dispatchEvent(
                new CustomEvent("toggleLoading", {
                    detail: {
                        loading: localStorage.getItem("loading"),
                        overlay: localStorage.getItem("loading"),
                    },
                })
            );
        },
        async updateNote(){
            await this.toggleLoading(true);
            try {
                await axios({
                method: "patch",
                url:
                    process.env.VUE_APP_ROOT_API +
                    "/events/update-note/" +
                    this.id,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                data: {
                    deliveryNote: this.transportNote,
                },
                });
                Swal.fire("Sukces", "Zapisano", "success");
            } catch (e) {
                console.log(e);
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            await this.toggleLoading(false);
        },
        async saveTransport(isUpdate = false){
          if(this.totalTransportPrice<0){
            Swal.fire("Błąd", 'Uzupełnij Koszt Transportu', "error");
            return false
          }      
        await this.toggleLoading(true);
        try {
            await axios({
            method: "patch",
            url:
                process.env.VUE_APP_ROOT_API +
                "/events/update-transport/" +
                this.id,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            data: {
                deliveryNote: this.transportNote,
                showTransportAddress:this.showTransportAddress,
                transportAddress: this.transportAddressNew,
                transportAddressApt: this.transportAddressApt,
                transportAddressGeoLat: this.transportAddressHelper.latitude,
                transportAddressGeoLng: this.transportAddressHelper.longitude,
                totalSell: parseFloat(this.totalTransportPrice),
                isOnlyUpdate:isUpdate,
                paymentType:this.paymentType
            },
            });
            await this.initData()
            await this.getEvents();
            this.fullDetailsDialog = false;                
        } catch (e) {
            console.log(e);
            Swal.fire("Błąd", e.response.statusText, "error");
        }
        await this.toggleLoading(false);            
        },
        async showTransportForm(evt) {
            this.fullDetailsDialog = true
            const rent = await axios({
                method: "get",
                url:
                process.env.VUE_APP_ROOT_API +
                "/rents/get-for-event/" +
                this.id,
                headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            this.showButton = !evt.isDone
            if(evt.isDone){
                const role = localStorage.getItem("role")
                if(role!='admin'){
                    if (dayjs(evt.startDate).isToday()){
                        this.isAdmin = true
                    }
                }
            }              
            this.noteInfoDriver = evt.deliveryNote
            this.noteInfo = evt.modNote
            this.totalTransportPrice = evt.totalSell/100;
            this.paymentType = evt.paymentType
            this.transportNote = evt.deliveryNote
            let productsStr = ''
            this.productsStr = ''

            if (rent.data.data.length) {
                rent.data.data.forEach((item) => {
                    if (item.products.name == 'Łóżko') {
                        if (item.isMateracPianka) {
                            productsStr = productsStr + item.products.name + '*, '
                        } else {
                            productsStr = productsStr + item.products.name + ', '
                        }
                    } else {
                        productsStr = productsStr + item.products.name + ', '
                    }
                })
            }
            this.productsStr = productsStr 
            this.dealNoInfo = rent.data.data[0].dealNo
            this.deliveryProductAddrInfo = rent.data.data[0].productAddress + ' Mieszkanie: ' + rent.data.data[0].productAddressApt
            this.transportProductAddrInfo = rent.data.data[0].transportAddress + ' Mieszkanie: ' +  rent.data.data[0].transportAddressApt
            this.callerPhoneInfo = rent.data.data[0].callerPhone 
            this.callerPhoneInfo = rent.data.data[0].callerPhone
            switch (rent.data.data[0].selectedPhone) {
                case 'contact':
                    this.clientPhoneInfo = rent.data.data[0].clientPhone ? rent.data.data[0].clientPhone : 'Brak'
                    break;
                case 'caller':
                    this.clientPhoneInfo = rent.data.data[0].callerPhone ? rent.data.data[0].callerPhone : 'Brak'
                    break;
                case 'other':
                    this.clientPhoneInfo = rent.data.data[0].otherPhone ? rent.data.data[0].otherPhone : 'Brak'
                    break;
            }            
            this.transportAddress = rent.data.data[0].transportAddress
            this.transportAddressApt = rent.data.data[0].transportAddressApt          
        },
    }
}
</script>
