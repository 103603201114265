<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-data-table :headers="headers" :items="orders"           
          :options.sync="options"
          :server-items-length="totalRecords"
          :loading="loading"     class="elevation-1">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Zamówienia Online</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template  v-slot:item.actions1="{ item }">
            <v-btn v-if="item.orderNote.length" @click="showAlert(item.orderNote)">Info</v-btn>
          </template>
          <template v-slot:item.actions="{ item }">            
            <v-btn v-if="item.isEventCreated == false" class="primary" @click="showCalendar(item.id)"
              ><v-icon small class="pt-1">mdi-calendar</v-icon></v-btn
            >
            <v-btn v-if="item.isEventCreated == true" class="warning" @click="unmarkImported(item.id)"
              ><v-icon small class="pt-1">mdi-backup-restore</v-icon></v-btn
            >     
          </template>
          <template v-slot:item.p24="">
          <a class="success" href="https://panel.przelewy24.pl" target="_blank"><v-btn class="success"><v-icon small
              class="pt-1">mdi-cash</v-icon></v-btn></a>

          </template>
          <template v-slot:item.delBtn="{ item }">
            <v-btn class="error" @click="deleteImport(item.id)"><v-icon small
                  class="pt-1">mdi-delete</v-icon></v-btn>
            </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog fullscreen hide-overlay v-model="dialog">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Tworzenie Wydarzenia</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container>
          <v-sheet color="grey" rounded="rounded" class="mx-auto">
            Numer Zamówienia:{{ storeWoocommerceId }}<br />            
            Produkty: {{ storeProducts }}<br />           
            Telefon Do Kontaktu: {{ storePhone }}<br />
            Adres: {{ storeAddress }}<br />
            Notatka do Dostawy: {{ storeTransportNote }}<br />
            Koszt Dostawy: {{ storeDelivery }}<br />
            Uwagi: {{ storeNote }}            
          </v-sheet>          
          <Calendar 
          :createFromShop="shopId"
          :shopPhone = "shopPhone"
          :shopMail = "shopMail"
          :shopDeliveryCost = "deliveryCost"
          :shopDeliveryAddress = "deliveryAddress"
          :shopStr="shopStr"
          />
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import Calendar from "./Calendar.vue";
const dayjs = require("dayjs");
const axios = require("axios");
import Swal from "sweetalert2";

export default {
  name: "Store",
  components: { Calendar },
  data: () => ({
    options: {},
    totalRecords: 0,    
    loading: false,  
    shopMail: '',  
    address: "",
    storeWoocommerceId: "",
    storeProducts: "",
    storeDelivery: "",
    storePhone: "",
    storeAddress: "",
    storeTransportNote: "",
    shopStr:"",
    storeNote: "",
    shopId:'',
    action: "",    
    dialog: false,
    headers: [
      {
        text: "Data Dodania",
        sortable: false,
        value: "created_at",
      },
      {
        text: "Id Zamówienia",
        align: "start",
        sortable: true,
        value: "woocommerceId",
      },
      {
        text: "Dostawa",
        align: "start",
        sortable: false,
        value: "address",
      },
      {
        text: "Telefon",
        align: "start",
        sortable: false,
        value: "phone",
      },
      {
        text: "Sprzęt",
        align: "start",
        sortable: false,
        value: "products",
      },           
      {
        text: "Sposób Płatności",
        align: "start",
        sortable: false,
        value: "paymentType",
      },
      { text: "Uwagi Klienta", value: "actions1", sortable: false },
      { text: "", value: "actions", sortable: false },
      { text: "", value: "p24", sortable: false },    
      { text: "", value: "delBtn", sortable: false },      
    ],
    orders: [],
    shopPhone:null,
    deliveryCost:null,
    deliveryAddress:null,
  }),

  created() {    
  },
  watch: {
    options: {
      async handler() {
        await this.initialize()
      },
      deep: true,
    },
  },
  methods: {
    showAlert: (msg) => {      
      alert(decodeURIComponent(escape(window.atob(msg))))
    },  
    async deleteImport(id){
      await this.toggleLoading(true);
      try {
        Swal.fire({
          title: "Napewno Usunąć?",
          text: "",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Tak",
          cancelButtonText: "Anuluj",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await axios({
              method: "delete",
              url: process.env.VUE_APP_ROOT_API + "/external/" + id,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            });
            await this.initialize();
          }
        });
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      await this.toggleLoading(false);      
    } ,   
    async showCalendar(id) {      
      this.dialog = true;
      this.shopId = id
      try{
        let record = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "/external/get-event/"+id,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.storeWoocommerceId = record.data.data.woocommerceId
        this.storeProducts = record.data.data.products
        this.storeDelivery = record.data.data.deliveryCost
        this.storePhone = record.data.data.phone
        this.shopMail = record.data.data.email
        this.storeAddress = record.data.data.address
        this.storeTransportNote = record.data.data.transportNote
        this.storeNote = record.data.data.orderNote 
        this.shopPhone = record.data.data.phone
        this.deliveryCost = record.data.data.deliveryCost
        this.deliveryAddress = record.data.data.address   
        this.shopStr = `Produkty ze sklepu: ${record.data.data.products},
Transport: ${record.data.data.transportNote},
Koszt Dostawy: ${record.data.data.deliveryCost},
Płatność: ${record.data.data.paymentType},
Uwagi Klienta: ${decodeURIComponent(escape(window.atob(record.data.data.orderNote)))}
        `
      }catch(e){
        console.log(e);
        Swal.fire("Błąd", e.response.statusText, "error");
      }
    },
    async unmarkImported(id){
      Swal.fire({
        title: "Napewno zdjąć blokadę?",
        text: "",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Tak",
        cancelButtonText: "Anuluj",
      }).then(async (result) => {
        if (result.isConfirmed) {      
      try {
        await axios({
          method: "patch",
          url: process.env.VUE_APP_ROOT_API + "/external/unmark-imported/" + id,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },          
        });
        await this.initialize()
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.statusText, "error");
      }
    }
  })
    },
    async toggleLoading(type) {
      const toggle = type;
      localStorage.setItem("loading", toggle);
      window.dispatchEvent(
        new CustomEvent("toggleLoading", {
          detail: {
            loading: localStorage.getItem("loading"),
            overlay: localStorage.getItem("loading"),
          },
        })
      );
    },
    async initialize() {
      this.loading = true
      let { sortBy, sortDesc, page, itemsPerPage } = this.options        
      try {
        let orders = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/external/get-events-to-import?sort=${sortBy.length ? sortBy : 'woocommerceId'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });        
        this.orders = [];
        orders.data.data.items.forEach((item) => {
          this.orders.push({
            id: item.id,
            created_at: dayjs(item.created_at).format("YYYY-MM-DD HH:mm"),
            woocommerceId: item.woocommerceId,
            address: item.address,
            phone: item.phone,
            orderNote: item.orderNote,
            paymentType: item.paymentType,
            products:item.products,
            isEventCreated:item.isEventCreated
          });
        });        
        this.itemsPerPage = orders.data.data.meta.itemsPerPage
        this.page = orders.data.data.meta.currentPage
        this.totalRecords = orders.data.data.meta.totalItems           
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      this.loading = false
    },
  },
};
</script>