<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-form>
                    <v-row>
                        <v-col cols="12">
                            <v-select label="Wybierz Magazyn" v-model="warehouse" :items="warehouses" item-text="name"
                                item-value="id" @change="initialize()"></v-select>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
            <v-divider></v-divider>
            <v-spacer></v-spacer>
            <v-col cols="12">
                <v-data-table :loading="loadingHistory" :headers="headers" :items="stats" class="elevation-1">
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>Statystyki</v-toolbar-title>
                        </v-toolbar>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
const axios = require("axios");
import Swal from "sweetalert2";
import * as dayjs from 'dayjs'
export default {
    name: "Stats",
    data: () => ({
        warehouse:null,
        warehouses:[],
        loadingHistory: false,
        stats: [],
        headers: [
            {
                text: "Sprzęt",
                align: "start",
                sortable: false,
                value: "name",
            },
            {
                text: "Wypożyczono",
                align: "start",
                sortable: false,
                value: "rtotal",
            },
            {
                text: "Łącznie Wypożyczonych",
                align: "start",
                sortable: false,
                value: "atotal",
            }, 
            {
                text: "% Udziału",
                align: "start",
                sortable: false,
                value: "percent",
            },             
            {
                text: "Łącznie Fizyczna Ilość",
                align: "start",
                sortable: false,
                value: "wtotal",
            },
            {
                text: "Łącznie",
                align: "start",
                sortable: false,
                value: "tttotal",
            },                                    
        ],
    }),

    async created() {
        await this.getWarehouse();
    },

    methods: {
        async toggleLoading(type) {
            const toggle = type;
            localStorage.setItem("loading", toggle);
            window.dispatchEvent(
                new CustomEvent("toggleLoading", {
                    detail: {
                        loading: localStorage.getItem("loading"),
                        overlay: localStorage.getItem("loading"),
                    },
                })
            );
        },         
        async getWarehouse() {
            await this.toggleLoading(true);
            try {
                let warehouse = await axios({
                    method: "get",
                    url: process.env.VUE_APP_ROOT_API + `/warehouse?sort=${'id'}&direction=${false}&page=${1}&limit=${100}`,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                if (warehouse.data.data.items.length > 5) {
                    warehouse.data.data.items.unshift(warehouse.data.data.items.splice(4, 1)[0]);
                    warehouse.data.data.items.push(warehouse.data.data.items.splice(4, 1)[0]);
                }
                this.warehouses = warehouse.data.data.items;
                this.warehouse = warehouse.data.data.items[0].id
                await this.initialize()
            } catch (e) {
                console.log(e);
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            await this.toggleLoading(false);
        },
        async getProducts(id) {            
            this.products = [];
            try {
                let products = await axios({
                    method: "get",
                    url: process.env.VUE_APP_ROOT_API + "/products/" + id + `/?sort=${'id'}&direction=${false}&page=${1}&limit=${100}`,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                const ids = products.data.data.items.map(item => item.id)
                let bed = await axios({
                    method: "get",
                    url: process.env.VUE_APP_ROOT_API + "/rents/rented-beds/" + ids.toString() + '/' + dayjs().format('YYYY-MM-DD HH:mm'),
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                if (id == 2 || id == 8) {
                    const products1 = await axios({
                        method: "get",
                        url: process.env.VUE_APP_ROOT_API + "/products/" + (id == 2 ? 8 : 2) + `/?sort=${'id'}&direction=${false}&page=${1}&limit=${100}`,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                    });
                    const ids1 = products1.data.data.items.map(item => item.id)
                    let bed1 = await axios({
                        method: "get",
                        url: process.env.VUE_APP_ROOT_API + "/rents/rented-beds/" + ids1.toString() + '/' + dayjs().format('YYYY-MM-DD HH:mm'),
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                    });
                    for (let a = 0; a < products.data.data.items.length; a++) {
                        let rented = 0
                        let rentIndex = bed.data.data.rents.find(it => it.id == products.data.data.items[a].id)
                        let rentIndex1 = bed1.data.data.rents.find(it => it.id == products1.data.data.items[a].id);
                        rented = (rentIndex ? parseInt(rentIndex.sumRents) : 0) + (rentIndex1 ? parseInt(rentIndex1.sumRents) : 0)
                        let returns = 0
                        let returnIndex = bed.data.data.returns.find(it => it.id == products.data.data.items[a].id)
                        let returnIndex1 = bed1.data.data.returns.find(it => it.id == products1.data.data.items[a].id);
                        returns = (returnIndex ? parseInt(returnIndex.sumReturns) : 0) + (returnIndex1 ? parseInt(returnIndex1.sumReturns) : 0)
                        let returns_till = 0
                        let returnTillIndex = bed.data.data.returns_till_date.find(it => it.id == products.data.data.items[a].id)
                        let returnTillIndex1 = bed1.data.data.returns_till_date.find(it => it.id == products1.data.data.items[a].id);
                        returns_till = (returnTillIndex ? parseInt(returnTillIndex.sumReturns) : 0) + (returnTillIndex1 ? parseInt(returnTillIndex1.sumReturns) : 0)
                        products.data.data.items[a].subtitle = ` ${products.data.data.items[a].qty - rented + returns_till} | ${products.data.data.items[a].qty - rented + returns} | ${products.data.data.items[a].qty} | ${returns} | ${rented}`
                        if ((products.data.data.items[a].qty - rented + returns) > 0) {
                            products.data.data.items[a].color = "green";
                        } else {
                            products.data.data.items[a].color = "red";
                        }  
                    }
                } else {
                    products.data.data.items.forEach((item) => {
                        let rented = 0
                        let rentIndex = bed.data.data.rents.find(it => it.id == item.id);
                        if (rentIndex) {
                            rented = parseInt(rentIndex.sumRents)
                        }
                        let returns = 0
                        let returnIndex = bed.data.data.returns.find(it => it.id == item.id);
                        if (returnIndex) {
                            returns = parseInt(returnIndex.sumReturns)
                        }
                        let returns_till = 0
                        let returnTillIndex = bed.data.data.returns_till_date.find(it => it.id == item.id);
                        if (returnTillIndex) {
                            returns_till = parseInt(returnTillIndex.sumReturns)
                        }
                        item.subtitle = ` ${item.qty - rented + returns_till} | ${item.qty - rented + returns} | ${item.qty} | ${returns} | ${rented}`
                        if ((item.qty - rented + returns) > 0) {
                            item.color = "green";
                        } else {
                            item.color = "red";
                        }
                    });
                }  
                return products.data.data.items;   
            } catch (e) {
                console.log(e);
                Swal.fire("Błąd", e.response.statusText, "error");
            }      
        },
        async initialize() {
            try {
                this.loadingHistory = true
                let other = await axios({
                    method: "get",
                    url:
                        process.env.VUE_APP_ROOT_API +
                        "/rents/get-stats/" +
                        this.warehouse,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                let prods = []
                for(let a=0;a<7;a++){
                    prods.push(await this.getProducts(a+1))
                }                
                other.data.data.rents.forEach((item,i)=>{    
                    item.wtotal = 0     
                                               
                    for(let a=0;a<7;a++){                                            
                        item.wtotal += parseInt(prods[a][i].qty)                        
                    }                               
                    item.atotal = other.data.data.total.find((i)=> i.name == item.name).rtotal
                    item.percent = ((item.rtotal/item.atotal)*100).toFixed(2)
                    item.tttotal = item.wtotal + parseInt(item.atotal)
                })
                this.stats = other.data.data.rents
                
            } catch (e) {
                console.log(e);
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            this.loadingHistory = false
        },
    },
};
</script>