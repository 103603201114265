<template>
    <v-container>
        <v-row>
            <v-toolbar flat>
                <v-toolbar-title>Raport Godzin</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-col cols="12">
                <v-form>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field label="Data" type="date" background-color="secondary" v-model="raportDate" @input="initialize()"
                                :rules="[(v) => !!v || 'Uzupełnij Pole']"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="totalNormalHour" label="Ilość Godzin"
                                :rules="[(v) => !!v || 'Uzupełnij Pole']"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="totalExtraHour" label="Ilość Nadgodzin"
                                :rules="[(v) => !!v || 'Uzupełnij Pole']"></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <v-textarea label="Notatka Do Raportu" :rules="[(v) => !!v || 'Uzupełnij Pole']"
                                v-model="raportNote"></v-textarea>
                        </v-col>

                        <v-spacer></v-spacer>
                        <v-col cols="12">
                            <v-btn class="primary" style="width: 100%" @click="saveRaport()">Zapisz</v-btn>
                        </v-col>

                    </v-row>
                </v-form>
            </v-col>
            <v-divider></v-divider>
            <v-spacer></v-spacer>
            <v-col cols="12" v-if="isAdmin">
                    <v-form>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field label="Nazwa Rekordu" v-model="title"
                                        :rules="[(v) => !!v || 'Uzupełnij Pole']"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field type="number" label="Wydatki" v-model="buy" :rules="[(v) => !!v || 'Uzupełnij Pole']"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field type="number" label="Przychód" v-model="sell" :rules="[(v) => !!v || 'Uzupełnij Pole']"></v-text-field>
                            </v-col>
                        <v-col cols="12">
                            <v-select item-text="name" item-value="id" v-model="paymentType" :items="paymentTypes" label="Rodzaj Płatności">
                            </v-select>
                        </v-col>
                        </v-row>
                        <v-spacer></v-spacer>
                        <v-col cols="12">
                            <v-btn class="primary" style="width: 100%" @click="saveOther()">Zapisz</v-btn>
                        </v-col>
                    </v-form>
            </v-col>
    <v-col cols="12">
        <v-data-table :loading="loadingHistory" :headers="headers" :items="events" class="elevation-1">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Rekordy</v-toolbar-title>
                </v-toolbar>
            </template>
            <template v-slot:item.sell1="{ item }" v-if="isAdmin">
                <v-text-field :rules="[(v) => !!v || 'Uzupełnij Pole']" type="number" step="0.1" v-model="buyNew[item.id]">
                </v-text-field>
            </template>
            <template v-slot:item.delivery1="{ item }" v-if="isAdmin">
                <v-text-field :rules="[(v) => !!v || 'Uzupełnij Pole']" type="number" step="0.1"
                    v-model="sellNew[item.id]">
                </v-text-field>
            </template>
            <template v-slot:item.actions="{ item }" v-if="isAdmin">
                <v-btn class="warning" @click="updateEventPrices(item.id)">
                    <v-icon small class="pt-1">mdi-pencil</v-icon>
                </v-btn>
            </template>
        </v-data-table>
    </v-col>
        </v-row>
    </v-container>
</template>
<script>
const axios = require("axios");
import Swal from "sweetalert2";
import * as dayjs from 'dayjs'
var isToday = require('dayjs/plugin/isToday')
dayjs.extend(isToday)
export default {
    name: "DayRaport",
    data: () => ({
        paymentType: null,
        paymentTypes: [
            {
                id: 'cash',
                name: 'Gotówka'
            },
            {
                id: 'card',
                name: 'Karta'
            },
            {
                id: 'gate',
                name: 'Sklep Online'
            },
            {
                id: 'wire',
                name: 'Przelew'
            },
        ],        
        title:'',
        buy:'',
        sell:'',     
        loadingHistory:false,        
        events:[],  
        buyNew:[],
        sellNew:[],
        raportDate: new Date().toISOString().substr(0, 10),
        totalNormalHour: 0,
        totalExtraHour: 0,
        raportNote: '',
        currentId: null,
        isAdmin:false,
        headers: [
            {
                text: "Rekord",
                align: "start",
                sortable: false,
                value: "title",
            },
            {
                text: "Wydatki",
                align: "start",
                sortable: false,
                value: "totalBuy",
            },
            {
                text: "Przychód",
                align: "start",
                sortable: false,
                value: "totalSell",
            },            
            { text: "Nowa Kwota Wydatki", value: "sell1", sortable: false },
            { text: "Nowa Kwota Przychód", value: "delivery1", sortable: false },
            { text: "", value: "actions", sortable: false },
        ],        
    }),

    async created() {
        await this.initialize();
    },

    methods: {
        async toggleLoading(type) {
            const toggle = type;
            localStorage.setItem("loading", toggle);
            window.dispatchEvent(
                new CustomEvent("toggleLoading", {
                    detail: {
                        loading: localStorage.getItem("loading"),
                        overlay: localStorage.getItem("loading"),
                    },
                })
            );
        },
        async saveOther(){
            if (!this.title) {
                Swal.fire("Błąd", 'Uzupełnij Tytuł', "error");
                return false
            }            
            if (!this.buy) {
                Swal.fire("Błąd", 'Uzupełnij Wydatki', "error");
                return false
            }
            if (!this.sell) {
                Swal.fire("Błąd", 'Uzupełnij Przychód', "error");
                return false
            }
            await this.toggleLoading(true);
            try{
                await axios({
                    method: "post",
                    url:
                        process.env.VUE_APP_ROOT_API +
                        "/events/create-other-raport/"                        ,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    data: {
                        totalBuy: parseFloat(this.buy),
                        totalSell: parseFloat(this.sell),
                        paymentType: this.paymentType,
                        title: this.title,
                    },
                }); 
                this.buy = ''
                this.sell = ''
                this.title = ''
                this.paymentType = null
            }catch(e){
                console.log(e);
                Swal.fire("Błąd", e.response.statusText, "error");
            }    
            await this.initialize()
            await this.toggleLoading(false);
        },
        async updateEventPrices(id) {
                if (!this.sellNew[id]) {
                    Swal.fire("Błąd", 'Uzupełnij Kwotę Przychodu', "error");
                    return false
                }
                if (!this.buyNew[id]) {
                    Swal.fire("Błąd", 'Uzupełnij Kwotę Wydatków', "error");
                    return false
                }
                await this.toggleLoading(true)
                try {
                    await axios({
                        method: "patch",
                        url: process.env.VUE_APP_ROOT_API + "/events/update-raport-sales/" + id,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                        data: {
                            sell: parseFloat(this.sellNew[id]),
                            buy: parseFloat(this.buyNew[id]),
                        },
                    });
                    this.sellNew[id] = "";
                    this.buyNew[id] = "";
                    await this.initialize()                    
                } catch (e) {
                    console.log(e)
                    Swal.fire("Błąd", e.response.statusText, "error");
                }
                await this.toggleLoading(false)            
        },        
        async initialize() {
            await this.toggleLoading(true);
            try {
                let raport = await axios({
                    method: "get",
                    url:
                        process.env.VUE_APP_ROOT_API +
                        "/raports/get-raport/" +
                        this.raportDate,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                this.loadingHistory = true            
                if (dayjs(this.raportDate).isToday()) {
                        this.isAdmin = true
                }else{
                    this.isAdmin = false
                    this.headers= [
                        {
                            text: "Rekord",
                            align: "start",
                            sortable: false,
                            value: "title",
                        },
                        {
                            text: "Wydatki",
                            align: "start",
                            sortable: false,
                            value: "totalBuy",
                        },
                        {
                            text: "Wydatki",
                            align: "start",
                            sortable: false,
                            value: "totalSell",
                        },
                    ]
                }         
                let other = await axios({
                    method: "get",
                    url:
                        process.env.VUE_APP_ROOT_API +
                        "/events/other-raport/" +
                        this.raportDate,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });   
                other.data.data.forEach((item)=>{
                    item.totalSell = item.totalSell/100
                    item.totalBuy = item.totalBuy / 100
                })
                this.events = other.data.data
                this.loadingHistory = false
                if (raport.data.data.length) {
                    this.totalNormalHour = raport.data.data[0].normalHour;
                    this.totalExtraHour = raport.data.data[0].extraHour;                                        
                    this.currentId = raport.data.data[0].id;
                    this.raportNote = raport.data.data[0].raportNote;
                }else{
                    this.totalNormalHour = 0;
                    this.totalExtraHour = 0;
                    this.currentId = null;
                    this.raportNote = '';                    
                }

            } catch (e) {
                console.log(e);
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            await this.toggleLoading(false);
        },
        async saveRaport() {
            await this.toggleLoading(true);
            try {
                await axios({
                    method: "post",
                    url: process.env.VUE_APP_ROOT_API + "/raports/create-raport",
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    data: {
                        id: this.currentId,
                        normalHour: this.totalNormalHour,
                        extraHour: this.totalExtraHour,
                        date: this.raportDate,
                        raportNote: this.raportNote
                    },
                });
                await this.initialize();
                window.location.href = "/day"
            } catch (e) {
                console.log(e)
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            await this.toggleLoading(false);
        },
    },
};
</script>