<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-sheet color="grey lighten-3">
          <v-sheet :elevation="2" rounded>
            <v-row class="pa-5">
              <v-col cols="12">
                <v-text-field
                 type="date"
                 background-color="secondary"
                  label="Data Dodania Płatności"
                  v-model="paymentDate"  
                  @input="initialize()"                
                ></v-text-field>
              </v-col>               
              <!-- <v-col cols="12">
                <v-btn
                  class="primary"
                  style="width: 100%"
                  @click="initialize()"
                  ><v-icon>mdi-account-search</v-icon></v-btn
                >
              </v-col>                                              -->
            </v-row>
          </v-sheet>
        </v-sheet>
      </v-col>
      <v-spacer></v-spacer>           
      <v-col cols="12">
        <v-data-table
          :loading="loading" :headers="headers" :items="invoices" class="elevation-1">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Historia Płatności</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>   
          <template v-slot:item.actions="{ item }">
            <v-btn class="warning" @click="rejectInvoice(item.id)"
              >Confnij Fakturę</v-btn
            >
          </template>               
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
const dayjs = require("dayjs");
const axios = require("axios");
import Swal from "sweetalert2";

export default {
  name: "invoicesHistory",
  data: () => ({
    loading: false,     
    headers: [
      {
        text: "Data Dodania Płatności",
        sortable: false,
        value: "paymentDate",
      },
      {
        text: "Numer Umowy",
        align: "start",
        sortable: false,
        value: "dealNo",
      },
      {
        text: "Klient",
        align: "start",
        sortable: false,
        value: "rname",
      },       
      {
        text: "Dane do Faktury",
        align: "start",
        sortable: true,
        value: "invoiceData",
      },
      {
        text: "Typ Faktury",
        align: "start",
        sortable: true,
        value: "invoiceType",
      },  
      {
        text: "Uwagi",
        align: "start",
        sortable: true,
        value: "paymentNote",
      },            
      {
        text: "Kwota",
        align: "start",
        sortable: true,
        value: "price",
      },            
      { text: "", value: "actions", sortable: false },
    ],    
    invoices: [],
    paymentDate:new Date().toISOString().substr(0, 10)
  }),

  async created() {
    await this.initialize();
  },

  methods: {
    async toggleLoading(type) {
      const toggle = type;
      localStorage.setItem("loading", toggle);
      window.dispatchEvent(
        new CustomEvent("toggleLoading", {
          detail: {
            loading: localStorage.getItem("loading"),
            overlay: localStorage.getItem("loading"),
          },
        })
      );
    }, 
    async rejectInvoice(id) {
      await this.toggleLoading(true);      
      try {
        Swal.fire({
          title: "Napewno Cofnąć Fakturę?",
          text: "",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Tak",
          cancelButtonText: "Anuluj",
        }).then(async (result) => {
          if (result.isConfirmed) {        
                await axios({
                method: "patch",
                url: process.env.VUE_APP_ROOT_API + "/payments/reject-invoice/" + id,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                });
                await this.initialize();
          }
        });        
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      await this.toggleLoading(false);
    }, 
    async initialize() {
      this.loading = true        
      try {
        let invoices = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "/payments/get-invoices-for-date/"+this.paymentDate,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.invoices = [];
        invoices.data.data.forEach((item) => {        
          this.invoices.push({
            id: item.id,
            rname: item.rents.firstName + ' ' + item.rents.lastName,
            dealNo: item.rents.dealNo,            
            price:item.totalToPay/100,
            paymentDate: dayjs(item.paymentDate).format("YYYY-MM-DD"),
            invoiceData: item.rents.invoiceData, 
            invoiceType:item.invoiceType=='personal'?'Osoba Fizyczna':'Firma',                              
          });          
        });
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      this.loading = false
    },
  },
};
</script>