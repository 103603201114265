<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="users"
          class="elevation-1"
           :search="search" 
          :options.sync="options"
          :server-items-length="totalRecords"
          :loading="loading"                   
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Użytkownicy</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-btn
                class="primary"
                @click="
                  (dialog = true),
                    (action = 'Tworzenie'),
                    (currentUserId = null),
                    (passwordLabel = 'Hasło'),
                    (warehouseLabel = 'Magazyn')
                "
                >Dodaj</v-btn
              >
            </v-toolbar>
                        <div>
                          <v-text-field v-model="search" label="Szukaj" class="mx-4"></v-text-field>
                        </div>
          </template>
          <template v-slot:item.details="{ item }">
            <v-btn class="warning" @click="infoUser(item.id)"
              ><v-icon small class="pt-1">mdi-account-edit</v-icon></v-btn
            >
          </template>
          <template v-slot:item.deleteUser="{ item }">
            <v-btn class="error" @click="deleteUser(item.id)"
              ><v-icon small class="pt-1">mdi-delete</v-icon></v-btn
            >
          </template>
          <template v-slot:item.editNote="{ item }">
            <v-btn class="info" @click="editNote(item.id)"><v-icon small class="pt-1">mdi-eye</v-icon></v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogNote" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Uwagi</span>
        </v-card-title>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
        <v-textarea v-model="note" label="Wpisz Notatkę"></v-textarea>
        </v-col>
        </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogNote = false">
            Anuluj
          </v-btn>
          <v-btn color="blue darken-1" text @click="saveEditNote()">
            Aktualizuj
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ action }} Użytkownika</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Imię"
                  v-model="firstName"
                  required
                  placeholder="Imię"
                  :rules="[(v) => !!v || 'Uzupełnij Pole']"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Nazwisko"
                  v-model="lastName"
                  :rules="[(v) => !!v || 'Uzupełnij Pole']"
                  required
                  placeholder="Nazwisko"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Telefon"
                  v-model="phone"
                  :rules="[(v) => !!v || 'Uzupełnij Pole']"
                  required
                  placeholder="Telefon"
                ></v-text-field>
              </v-col> 
              <v-col cols="12">
                <v-text-field
                  label="Kolor"
                  type="color"
                  v-model="color"
                  :rules="[(v) => !!v || 'Uzupełnij Pole']"
                  required
                  placeholder="Kolor"
                ></v-text-field>
              </v-col>                            
              <v-col cols="12">
                <v-text-field
                  label="E-mail"
                  :rules="[(v) => !!v || 'Uzupełnij Pole']"
                  required
                  type="email"
                  v-model="email"
                  placeholder="E-mail"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="passwordLabel"
                  v-model="password"
                  :rules="[(v) => !!v || 'Uzupełnij Pole']"
                  required
                  type="password"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  :label="warehouseLabel"
                  :rules="[(v) => !!v || 'Uzupełnij Pole']"
                  v-model="warehouse"
                  :items="warehouses"
                  item-text="name"
                  item-value="id"
                  multiple
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Anuluj
          </v-btn>
          <v-btn color="blue darken-1" text @click="createUser()">
            Zapisz
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
const axios = require("axios");
import Swal from "sweetalert2";
export default {
  name: "Users",
  data: () => ({
    dialogNote: false,
    note: '',
    options: {},
    totalRecords: 0,
    loading: false,
    // 
    dialog: false,
    action: "",
    firstName: "",
    lastName: "",
    password: "",
    email: "",
    warehouse: "",
    warehouseLabel: "",
    passwordLabel: "",
    phone:'',
    color:'',
    search: '',
    currentUserId: null,
    headers: [
      {
        text: "Imię",
        align: "start",
        sortable: false,
        value: "firstName",
      },
      {
        text: "Nazwisko",
        align: "start",
        sortable: false,
        value: "lastName",
      },
      {
        text: "Telefon",
        align: "start",
        sortable: false,
        value: "phone",
      },      
      {
        text: "Magazyn",
        align: "start",
        sortable: false,
        value: "warehouseName",
      },
      { text: "", value: "details", sortable: false },
      { text: "", value: "deleteUser", sortable: false },
      { text: "", value: "editNote", sortable: false },
    ],
    users: [],
    warehouses: [],
  }),
  async created() {
  },
  watch: {
    options: {
      async handler() {
        await this.initialize()
      },
      deep: true,
    },
    async search() {
      this.options.page = 1
      await this.initialize()
    }    
  },

  methods: {
    async toggleLoading(type) {
      const toggle = type;
      localStorage.setItem("loading", toggle);
      window.dispatchEvent(
        new CustomEvent("toggleLoading", {
          detail: {
            loading: localStorage.getItem("loading"),
            overlay: localStorage.getItem("loading"),
          },
        })
      );
    },
    async initialize() {
      this.loading = true      
      let { sortBy, sortDesc, page, itemsPerPage } = this.options  
      await this.getWarehouseSelect();
      try {
        let users = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/users?sort=${sortBy.length?sortBy:'email'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}&search=${this.search}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });                
        users.data.data.items.forEach((item)=>{
          item.warehouseName = ''
          if(item.warehouse){
            item.warehouse.forEach((o) => {              
              item.warehouseName += o.name + ', '
            })
          }else{
            item.warehouseName += 'Brak Magazynu'
          }
        })
        this.users = users.data.data.items
        this.itemsPerPage = users.data.data.meta.itemsPerPage
        this.page = users.data.data.meta.currentPage
        this.totalRecords = users.data.data.meta.totalItems         
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      this.loading = false
    },
    async editNote(id){
      let user = await axios({
        method: "get",
        url: process.env.VUE_APP_ROOT_API + "/users/" + id,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      this.currentUserId = id
      this.note = user.data.data.note
      this.dialogNote = true
    },
    async saveEditNote(){
      await this.toggleLoading(true);
      const method = "patch";
      const url = process.env.VUE_APP_ROOT_API + "/users/update-note/" + this.currentUserId;      
      try{
        await axios({
          method: method,
          url: url,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: {
            note: this.note,
          },
        });
        this.note = false;
        this.dialogNote = false;
        this.currentUserId = null;
      }catch(e){
        console.log(e)
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      await this.toggleLoading(false);
    },
    async createUser() {
      await this.toggleLoading(true);
      let method = "";
      let url = "";
      if (this.currentUserId) {
        method = "patch";
        url = process.env.VUE_APP_ROOT_API + "/users/" + this.currentUserId;
      } else {
        method = "post";
        url = process.env.VUE_APP_ROOT_API + "/auth/create-user";
      }
      try {
        await axios({
          method: method,
          url: url,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            color: this.color,
            phone: this.phone,
            password: this.password || null,
            warehouse: this.warehouse || null,
          },
        });
        await this.getWarehouseSelect();
        await this.initialize();
        this.firstName = "";
        this.lastName = "";
        this.email = "";
        this.password = "";
        (this.warehouse = null), (this.dialog = false);
      } catch (e) {
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      await this.toggleLoading(false);
    },
    async deleteUser(item) {
      await this.toggleLoading(true);
      try {
        await axios({
          method: "delete",
          url: process.env.VUE_APP_ROOT_API + "/users/" + item,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: {
            status: false,
          },
        });
        await this.initialize();
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      await this.toggleLoading(false);
    },
    async getWarehouseSelect() {
      await this.toggleLoading(true);
      try {
        let warehouse = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/warehouse?sort=${'id'}&direction=${false}&page=${1}&limit=${100}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        if (warehouse.data.data.items.length > 5) {
          warehouse.data.data.items.unshift(warehouse.data.data.items.splice(4, 1)[0]);
          warehouse.data.data.items.push(warehouse.data.data.items.splice(4, 1)[0]);
        }
        this.warehouses = warehouse.data.data.items;
      } catch (e) {
        console.log(e)
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      await this.toggleLoading(false);
    },
    async infoUser(item) {
      await this.toggleLoading(true);
      try {
        let user = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + "/users/" + item,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.dialog = true;
        this.action = "Edycja";
        this.currentUserId = item;
        this.firstName = user.data.data.firstName;
        this.lastName = user.data.data.lastName;
        this.color = user.data.data.color;
        this.phone = user.data.data.phone;
        this.email = user.data.data.email;
        this.warehouseLabel = "Nowy Magazyn";
        this.passwordLabel = "Nowe Hasło";
      } catch (e) {
        console.log(e);
        this.dialog = false;
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      await this.toggleLoading(false);
    },
  },
};
</script>