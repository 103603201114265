<template>
  <v-app id="inspire">
    <!-- <div id="nav" v-if="user=='true'">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>
    <router-view/> -->
    <v-app-bar app v-if="user == 'true'"  color="blue darken-3">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title></v-toolbar-title>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        bottom
        style="z-index: 9999"
        color="deep-purple accent-4"
      ></v-progress-linear>
    </v-app-bar>
    <v-overlay :value="overlay" style="z-index: 9999"></v-overlay>

    <v-navigation-drawer v-model="drawer" fixed temporary v-if="user == 'true'">
      <v-list>
        <v-list-item v-for="[icon, text, link,showBadge] in links" :key="icon" :to="link" active-class="blue darken-3--text " >
          <v-list-item-icon>
            <v-icon v-if="icon">{{ icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title  v-if="showBadge"><v-badge  color="green" :content="unimported">{{ text }}</v-badge></v-list-item-title>
            <v-list-item-title v-if="!showBadge">{{ text }}</v-list-item-title>
          </v-list-item-content>                 
          
        </v-list-item>       
      </v-list>
      <!--  -->
      <template v-slot:append>
        <div class="pa-2">
          <v-btn color="blue darken-3" block @click="logout()"> Wyloguj </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-content>
      <!-- <v-container class="fill-height" fluid> -->
      <router-view />
      <!-- </v-container> -->
    </v-content>
    <v-footer padless color="blue darken-3">
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }} — <strong>Medistuff</strong>
      </v-col>
    </v-footer>
  </v-app>
</template>
<script>
const axios = require("axios");
export default {
  name: "App",
  data: () => ({
    user: false,
    drawer: null,
    loading: false,
    overlay: false,
    unimported:0,
    links: [],
  }),
  async mounted() {
    axios.interceptors.response.use(response => {
      return response;
    }, error => {
      if (error.response.status === 401) {
        localStorage.setItem("user", false);
        localStorage.setItem("token", null);
        localStorage.setItem("role", null);
        localStorage.setItem("userid", null);
        localStorage.setItem("whid", null);
        window.location.href = "/";            
      }else{
        return error.response.status
      }
      return Promise.reject(error);
    })    
    window.addEventListener("toggleLoading", (event) => {
      this.loading = event.detail.loading == "true" ? true : false;
      this.overlay = event.detail.overlay == "true" ? true : false;
    });
    if (localStorage.getItem("user") == "true") {
      this.user = localStorage.getItem("user");
      if (localStorage.getItem("role") == "admin") {
        this.links = [          
          ["mdi-calendar", "Kalendarz", "calendar",false],
          ["mdi-store-plus", "Sklep Online", "store", true],          
          ["mdi-account-group", "Klienci", "clients", false],           
          ["mdi-medication", "Szyny", "rails", false],
          ["mdi-office-building-plus", "Firmy", "company", false],
          ["mdi-warehouse", "Magazyn", "warehouse", false],
          ["mdi-bed-empty", "Brudne Materace", "dirty"], 
          ["mdi-information", "Podsumowanie", "stats", false],          
          ["mdi-account", "Użytkownicy", "users", false], 
          ["mdi-file-chart", "Raporty", "raports", false],               
        ];
        const rent = await axios({
          method: "get",
          url:
            process.env.VUE_APP_ROOT_API +
            "/external/count/",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.unimported = rent.data.data         
        setInterval(async()=>{
          const rent = await axios({
            method: "get",
            url:
              process.env.VUE_APP_ROOT_API +
              "/external/count/",              
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          });  
          this.unimported = rent.data.data                   
        },1000*60)
      }else if (localStorage.getItem("role") == "preview") {
        this.links = [
          ["mdi-calendar-account", "Wydarzenia", "timetable"],
        ]
      } else if (localStorage.getItem("role") == "account") {
        this.links = [
          ["mdi-cash-clock", "Płatności", "payments"],
          ["mdi-archive", "Archiwum", "archive"],
          ["mdi-cash", "Historia Płatności", "payments-history"],
          ["mdi-receipt-text", "Paragony", "receipts"],
          ["mdi-receipt-text-check-outline", "Wystawione Paragony", "receipts-history"],
          ["mdi-receipt", "Faktury", "invoice"],
          ["mdi-receipt-text-check", "Wystawione Faktury", "invoice-history"],
        ]
      } else if (localStorage.getItem("role") == "user") {
        this.links = [
          ["mdi-calendar-today", "Mój Dzień", "day"],
          ["mdi-account-arrow-up", "Osobiste", "personal"],
          ["mdi-calendar-account", "Grafik", "timetable"],
          ["mdi-bed", "Sprzęt", "service"], 
          ["mdi-warehouse", "Magazyn", "warehouse"],
          ["mdi-bed-empty", "Brudne Materace", "dirty"], 
          ["mdi-finance", "Raport Godzin", "hours"],  
          ["mdi-file-chart", "Raporty", "raports"],         
        ];
      } else if (localStorage.getItem("role") == "rails"){
        this.links = [
          ["mdi-medication", "Szyny", "my-rails"]
        ]
      }
    }
  },
  methods: {
    logout() {
      localStorage.setItem("token", null);
      localStorage.setItem("user", false);
      window.location.href = "/";
    },
  },
};
</script>