<template>
    <div>
    <v-dialog transition="dialog-bottom-transition" fullscreen persistent hide-overlay v-model="fullDetailsDialog">
        <v-toolbar dark color="primary">
            <v-btn icon dark @click="fullDetailsDialog = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Szczegóły</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-card>
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <v-sheet color="brown darken-4" rounded="rounded" class="mx-auto">
                            Numer Umowy: {{ dealNoInfo }}<br />
                            Adres Sprzętu: <a :href="'http://maps.google.com/?q='+deliveryProductAddrInfo" target="_blank">{{
                                deliveryProductAddrInfo }}</a><br />
                            Telefon Do Kontaktu: <a :href="'tel: '+clientPhoneInfo">{{ clientPhoneInfo }}</a><br />
                            Telefon Dzwoniącego: <a :href="'tel: ' + callerPhoneInfo">{{ callerPhoneInfo }}</a><br />                            
                            Uwagi: {{ noteInfo }} <br />
                            Uwagi Kierowcy: {{ noteInfoDriver }}<br />
                            Produkt: {{ productsStr }}
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
            <v-stepper v-model="e1">
                <v-stepper-step step="4" editable>
                    Formularz Odbiór
                </v-stepper-step>
                <v-stepper-content step="4">
                    <v-form>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field :disabled="showDeliveryCost" v-model="totalReturnPriceTransport" autocomplete="new-password" type="number"
                                    label="Kilometrówka" :rules="[(v) => v>=0 || 'Uzupełnij Pole']"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field v-model="totalReturnPrice" autocomplete="new-password" type="number" value="0"
                                    label="Dodatkowy Przychód Odbioru" :rules="[(v) => v>=0 || 'Uzupełnij Pole']"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field v-model="totalBuyPrice" autocomplete="new-password" type="number" value="0"
                                    label="Dodatkowy Koszt Odbioru" :rules="[(v) => v>=0 || 'Uzupełnij Pole']"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <h3>Produkty do Odbioru</h3>
                                <v-list subheader two-line>
                                    <v-list-item v-for="rent in returnItems" :key="rent.id">
                                        <v-list-item-content>
                                            <v-row>
                                                <v-col cols="8" style="display: flex;justify-content: center;align-items: center;">
                                                    <v-list-item-title v-text="rent.name"></v-list-item-title>
                                                </v-col>
                                                <v-col cols="2">
                                                    <v-list-item-action>
                                                        <v-btn icon class="error" v-if="showButton" @click="removeFromReturn(rent.id)">
                                                            <v-icon class="color:black;">mdi-delete</v-icon>
                                                        </v-btn>
                                                    </v-list-item-action>
                                                </v-col>
                                                <v-col cols="2">
                                                    <v-list-item-action>
                                                        <v-btn icon class="info" v-if="showButton" @click="showHistory(rent.id)">
                                                            <v-icon class="color:black;">mdi-file-image</v-icon>
                                                        </v-btn>
                                                    </v-list-item-action>
                                                </v-col>
                                            </v-row>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                                <h3>Produkty Klienta</h3>
                                <v-list subheader two-line>
                                    <v-list-item v-for="product in productsForClient" :key="product.id">
                                        <v-list-item-content>
                                            <v-row>
                                                <v-col cols="8" style="display: flex;justify-content: center;align-items: center;">
                                                    <v-list-item-title v-text="product.name"></v-list-item-title>
                                                </v-col>
                                                <v-col cols="4">
                                                    <v-list-item-action>
                                                        <v-btn icon class="success" v-if="showButton"
                                                            @click="addProductToReturn(product.id)">
                                                            <v-icon class="color:black;">
                                                                mdi-plus</v-icon>
                                                        </v-btn>
                                                    </v-list-item-action>
                                                </v-col>
                                            </v-row>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>

                            </v-col>
                        <v-col cols="12">
                            <v-select item-text="name" item-value="id" v-model="paymentType" :items="paymentTypes" label="Rodzaj Płatności">
                            </v-select>
                        </v-col>
                            <v-col cols="12"  class="text-center">
                                <v-textarea label="Notatka Pracownika" autocomplete="new-password" v-model="deliveryReturnNoteWorker"
                                    :rules="[(v) => !!v || 'Uzupełnij Pole']">                                    
                                </v-textarea>
                                <v-btn color="warning" @click="updateNote()">Aktualizuj Notatkę</v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                    <div>
                        <v-btn v-if="isAdmin" color="warning" class="float-center" @click="saveReturn(true)">
                            Edytuj
                        </v-btn>
                        <v-btn v-if="showButton" color="green" class="float-right" @click="saveReturn()">
                            Wykonano
                        </v-btn>                        
                    </div>   
                </v-stepper-content>
            </v-stepper>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogImage" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
            <v-toolbar dark color="primary">
                <v-btn icon dark @click="dialogImage = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Galeria</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <v-img :src="imageUrl" aspect-ratio="1" class="grey lighten-2">
                        </v-img>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-dialog>
    </div>
</template>
<script>
import Swal from "sweetalert2";
const axios = require("axios");
import * as dayjs from 'dayjs'
var isToday = require('dayjs/plugin/isToday')
dayjs.extend(isToday)
export default {
    props: ['id', 'getEvents', 'warehouse'],
    data() {
        return { 
            showDeliveryCost: false,
            isAdmin: false,
            fullDetailsDialog:false,  
            dialogImage:false,
            imageUrl:'',
            dealNoInfo:'',
            deliveryProductAddrInfo:'',
            clientPhoneInfo:'',
            callerPhoneInfo:'',
            noteInfo:'',
            noteInfoDriver:'',
            productsStr:'',   
            showButton: false,
            e1: 4,
            totalReturnPriceTransport:0,
            totalReturnPrice:0,
            totalBuyPrice:0,
            returnItems:[],
            productsForClient:[],
            deliveryReturnNoteWorker:'',      
            paymentType: null,
            paymentTypes: [
                {
                    id: 'cash',
                    name: 'Gotówka'
                },                
                {
                    id: 'card',
                    name: 'Karta'
                },
                {
                    id: 'gate',
                    name: 'Sklep Online'
                },
                {
                    id: 'wire',
                    name: 'Przelew'
                },
            ]               
        }
    },
    created() {
        const role = localStorage.getItem("role")
        if (role == 'admin') {
            this.isAdmin = true
        } else {
            this.isAdmin = false
        }
    },    
    methods:{
        initData(){
            this.fullDetailsDialog=false  
            this.dialogImage=false
            this.imageUrl=''
            this.dealNoInfo=''
            this.deliveryProductAddrInfo=''
            this.clientPhoneInfo=''
            this.callerPhoneInfo=''
            this.noteInfo=''
            this.noteInfoDriver=''
            this.productsStr=''   
            this.showButton= false
            this.e1= 4
            this.totalReturnPriceTransport=0
            this.totalReturnPrice=0
            this.totalBuyPrice = 0
            this.returnItems=[]
            this.productsForClient=[]
            this.deliveryReturnNoteWorker=''      
            this.paymentType= null
            this.paymentTypes= [
                {
                    id: 'cash',
                    name: 'Gotówka'
                },                
                {
                    id: 'card',
                    name: 'Karta'
                },
                {
                    id: 'gate',
                    name: 'Sklep Online'
                },
                {
                    id: 'wire',
                    name: 'Przelew'
                },
            ]               
        },
        async toggleLoading(type) {
            const toggle = type;
            localStorage.setItem("loading", toggle);
            window.dispatchEvent(
                new CustomEvent("toggleLoading", {
                    detail: {
                        loading: localStorage.getItem("loading"),
                        overlay: localStorage.getItem("loading"),
                    },
                })
            );
        },
        async updateNote() {
            await this.toggleLoading(true);
            try {
                await axios({
                    method: "patch",
                    url:
                        process.env.VUE_APP_ROOT_API +
                        "/events/update-note/" +
                        this.id,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    data: {
                        deliveryNote: this.deliveryReturnNoteWorker,
                    },
                });
                Swal.fire("Sukces", "Zapisano", "success");
            } catch (e) {
                console.log(e);
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            await this.toggleLoading(false);
        },        
        toBase64(arr) {
            //arr = new Uint8Array(arr) if it's an ArrayBuffer
            return btoa(
                arr.reduce((data, byte) => data + String.fromCharCode(byte), '')
            );
        },
        async showHistory(id) {
            await this.toggleLoading(true);
            this.imageUrl = "";
            try {
                let rents = await axios({
                    method: "get",
                    url: process.env.VUE_APP_ROOT_API + "/rents/get-image/" + id,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                this.imageUrl = 'data:image/jpeg;base64,' + this.toBase64(rents.data.data.Body.data);
                this.dialogImage = true;
            } catch (e) {
                console.log(e);
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            await this.toggleLoading(false);
        },         
        async showReturnForm(evt) {            
            this.fullDetailsDialog = true;
            if (evt.isDone) {
                const role = localStorage.getItem("role")
                if (role != 'admin') {
                    if (dayjs(evt.startDate).isToday()) {
                        this.isAdmin = true
                    }
                }
            }
            this.totalReturnPriceTransport = evt.totalDelivery / 100
            this.totalReturnPrice = evt.totalSell / 100            
            if (evt.showDeliveryCost) {
                this.showDeliveryCost = true
                this.totalReturnPriceTransport = 0
            }       
            this.totalBuyPrice = evt.totalBuy /100    
            this.deliveryReturnNoteWorker = evt.deliveryNote
            this.paymentType = evt.paymentType            
            this.noteInfoDriver = evt.deliveryNote
            this.noteInfo = evt.modNote           

            this.showButton = !evt.isDone
            const rent = await axios({
                method: "get",
                url:
                    process.env.VUE_APP_ROOT_API +
                    "/rents/get-for-event/" +
                    this.id,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            let productsStr = ''
            this.productsStr = ''

            if (rent.data.data.length) {
                rent.data.data.forEach((item) => {
                    if (item.products.name == 'Łóżko') {
                        if (item.isMateracPianka) {
                            productsStr = productsStr + item.products.name + '*, '
                        } else {
                            productsStr = productsStr + item.products.name + ', '
                        }
                    } else {
                        productsStr = productsStr + item.products.name + ', '
                    }
                })
            }
            this.productsStr = productsStr
            this.dealNoInfo = rent.data.data[0].dealNo
            this.deliveryProductAddrInfo = rent.data.data[0].productAddress + ' Mieszkanie: ' + rent.data.data[0].productAddressApt
            this.callerPhoneInfo = rent.data.data[0].callerPhone
            switch (rent.data.data[0].selectedPhone) {
                case 'contact':
                    this.clientPhoneInfo = rent.data.data[0].clientPhone ? rent.data.data[0].clientPhone : 'Brak'
                    break;
                case 'caller':
                    this.clientPhoneInfo = rent.data.data[0].callerPhone ? rent.data.data[0].callerPhone : 'Brak'
                    break;
                case 'other':
                    this.clientPhoneInfo = rent.data.data[0].otherPhone ? rent.data.data[0].otherPhone : 'Brak'
                    break;
            }
            this.returnItems = []
            let products = [];
            if (rent.data.data.length) {                
                rent.data.data.forEach((item) => {                    
                    products.push({
                        id: item.id,
                        name: item.products.name,
                    });
                });
                this.returnItems = products;
            }  
            this.productsForClient = []
            let productsClient = await axios({
                method: "get",
                url:
                    process.env.VUE_APP_ROOT_API +
                    "/rents/get-for-deal/" +
                    rent.data.data[0].dealNo,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });             
            let productsForClientArr = []
            if (productsClient.data.data.length) {
                productsClient.data.data.forEach((item) => {                    
                    productsForClientArr.push({
                        id: item.id,
                        name: item.products ? item.products.name : item.pName,
                    });
                });

                this.productsForClient = productsForClientArr
            }                       
        },
        async saveReturn(isUpdate = false){
            if (this.totalReturnPriceTransport < 0) {
                Swal.fire("Błąd", 'Uzupełnij Kilometrówkę', "error");
                return false
            }
            if (this.totalReturnPrice < 0) {
                Swal.fire("Błąd", 'Uzupełnij Przychód Odbioru', "error");
                return false
            }
            if (this.totalBuyPrice < 0) {
                Swal.fire("Błąd", 'Uzupełnij Koszt Odbioru', "error");
                return false
            }
            await this.toggleLoading(true);
            try {
                await axios({
                    method: "patch",
                    url:
                        process.env.VUE_APP_ROOT_API +
                        "/events/update-return/" +
                        this.id,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    data: {
                        totalSell: parseFloat(this.totalReturnPrice),
                        totalDelievery: parseFloat(this.totalReturnPriceTransport),
                        totalBuy: parseFloat(this.totalBuyPrice),
                        deliveryNote: this.deliveryReturnNoteWorker,
                        paymentType:this.paymentType,
                        isOnlyUpdate: isUpdate,                        
                    },
                });
                this.initData()
                await this.getEvents();
                this.fullDetailsDialog = false;    
            } catch (e) {
                console.log(e);
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            await this.toggleLoading(false);
        },
        async removeFromReturn(id) {
            const rentId = await axios({
                method: "get",
                url:
                    process.env.VUE_APP_ROOT_API +
                    "/rents/get-for-event/" +
                    this.id,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });            
            try {
                Swal.fire({
                    title: "Usunąć Produkt z odbioru?",
                    text: "",
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Tak",
                    cancelButtonText: "Anuluj",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await axios({
                            method: "patch",
                            url: process.env.VUE_APP_ROOT_API + "/rents/cancel-return/" + id,
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("token"),
                            },
                            data: {
                                status: false,
                            },
                        });
                        const rent = await axios({
                            method: "get",
                            url:
                                process.env.VUE_APP_ROOT_API +
                                "/rents/get-for-event/" +
                                this.id,
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("token"),
                            },
                        });                        
                        this.returnItems = []
                        let products = [];
                        if (rent.data.data.length) {
                            rent.data.data.forEach((item) => {
                                products.push({
                                    id: item.id,
                                    name: item.products.name,
                                });
                            });
                            this.returnItems = products;
                        }
                        this.productsForClient = []
                        let productsClient = await axios({
                            method: "get",
                            url:
                                process.env.VUE_APP_ROOT_API +
                                "/rents/get-for-deal/" +
                                rentId.data.data[0].dealNo,
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("token"),
                            },
                        });
                        let productsForClientArr = []
                        if (productsClient.data.data.length) {
                            productsClient.data.data.forEach((item) => {
                                productsForClientArr.push({
                                    id: item.id,
                                    name: item.products.name,
                                });
                            });

                            this.productsForClient = productsForClientArr
                        }   
                    }
                });
            } catch (e) {
                console.log(e)
                Swal.fire("Błąd", e.response.statusText, "error");
            }
        },         
        async addProductToReturn(id) {
            await this.toggleLoading(true);
            try {
                await axios({
                    method: "post",
                    url:
                        process.env.VUE_APP_ROOT_API +
                        "/rents/add-to-return/" + id,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    data: {
                        returnId: this.id,
                    },
                });
                const rent = await axios({
                    method: "get",
                    url:
                        process.env.VUE_APP_ROOT_API +
                        "/rents/get-for-event/" +
                        this.id,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                this.returnItems = []
                let products = [];
                if (rent.data.data.length) {
                    rent.data.data.forEach((item) => {
                        products.push({
                            id: item.id,
                            name: item.products.name,
                        });
                    });
                    this.returnItems = products;
                }
                this.productsForClient = []
                let productsClient = await axios({
                    method: "get",
                    url:
                        process.env.VUE_APP_ROOT_API +
                        "/rents/get-for-deal/" +
                        rent.data.data[0].dealNo,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                let productsForClientArr = []
                if (productsClient.data.data.length) {
                    productsClient.data.data.forEach((item) => {
                        productsForClientArr.push({
                            id: item.id,
                            name: item.products ? item.products.name : item.pName,
                        });
                    });

                    this.productsForClient = productsForClientArr
                }  
            } catch (e) {
                console.log(e);
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            await this.toggleLoading(false);
        },         
    }
}
</script>