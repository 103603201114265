<template>
    <v-container>
        <v-row>
            <v-col cols="12">
<v-data-table :headers="headers" :items="archive" class="elevation-1" :search="search"
     :options.sync="options" :server-items-length="totalRecords"
    :loading="loading" item-key="id">
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>Archiwum Wypożyczeń</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            </v-toolbar>
            <div>
                <v-text-field v-model="search" label="Szukaj" class="mx-4"></v-text-field>
            </div>
                        
                    </template>
        <template v-slot:item.actions="{ item }">
            <v-btn class="warning" @click="recoverFromArchive(item.id)">
                <v-icon small class="pt-1">mdi-restore</v-icon>
            </v-btn>
        </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
const axios = require("axios");
import Swal from "sweetalert2";
import * as dayjs from 'dayjs'
export default {    
    data: () => ({
        search: '',
        totalRecords: 0,
        loading: false,
        detailsDialog: false,
        options: {},        
        headers: [
            {
                text: "Numer Umowy",
                align: "start",
                sortable: false,
                value: "dealNo",
            },
            {
                text: "Nazwisko",
                align: "start",
                sortable: false,
                value: "lastName",
            },            
            {
                text: "Telefon",
                align: "start",
                sortable: false,
                value: "phone",
            },
            {
                text: "Adres",
                align: "start",
                sortable: false,
                value: "productAddress",
            },
            {
                text: "Przedmiot",
                align: "start",
                sortable: false,
                value: "product",
            },
            {
                text: "Data Odbioru",
                align: "start",
                sortable: false,
                value: "returnDate",
            },            
            { text: "", value: "actions", sortable: false },
        ],
        archive: [],        
    }),

    async created() {        
    },
    watch: {
        options: {
            async handler() {
                await this.initialize()
            },
            deep: true,
        },
        async search() {
            this.options.page = 1 
            await this.initialize()
        }
    },
    methods: {
        async toggleLoading(type) {
            const toggle = type;
            localStorage.setItem("loading", toggle);
            window.dispatchEvent(
                new CustomEvent("toggleLoading", {
                    detail: {
                        loading: localStorage.getItem("loading"),
                        overlay: localStorage.getItem("loading"),
                    },
                })
            );
        },
        async recoverFromArchive(id){
            Swal.fire({
                title: "Napewno Przywrócić?",
                text: "",
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Tak",
                cancelButtonText: "Anuluj",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await this.toggleLoading(true);
                    await axios({
                        method: "patch",
                        url:
                            process.env.VUE_APP_ROOT_API +
                            "/rents/restore/" +
                            id,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token"),
                        }
                    })   
                    await this.toggleLoading(false); 
                    await this.initialize()                
                }
            })            
        },
        async initialize() {
            this.loading = true  
            try {
                let { sortBy, sortDesc, page, itemsPerPage } = this.options                  
                let archive = await axios({
                    method: "get",
                    url: process.env.VUE_APP_ROOT_API + `/rents/get-archive-table?sort=${sortBy.length?sortBy:'dealNo'}&direction=${sortDesc}&page=${page}&limit=${itemsPerPage}&search=${this.search}`,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });
                archive.data.data.items.forEach((item) => {
                    if (item.selectedPhone) {
                        switch (item.selectedPhone) {
                            case 'caller':
                                item.phone = item.callerPhone
                                break;
                            case 'other':
                                item.phone = item.otherPhone
                                break;
                            case 'contact':
                                item.phone = item.clientPhone
                                break;
                        }
                    } else {
                        item.phone = item.clientPhone
                    }   
                    item.returnDate = item.returns? dayjs(item.returns.startDate).format('YYYY-MM-DD'):'N/D'                 
                    item.product = item.products ? item.products.name : ''
                });
                this.archive = archive.data.data.items
                this.itemsPerPage = archive.data.data.meta.itemsPerPage
                this.page = archive.data.data.meta.currentPage
                this.totalRecords = archive.data.data.meta.totalItems                 
            } catch (e) {
                
                console.log(e);
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            this.loading = false  
        },
    },
};
</script>