import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import VueOffline from 'vue-offline'

Vue.use(Vuetify);
Vue.use(VueOffline);

export default new Vuetify({
      theme: { dark: true },
});
