<template>
        <v-container>
            <v-row>
    <v-col cols="12" >
        <v-select v-model="selectedproductsTransport" :items="itemsToTransport" @change="getClient()" item-text="rentName"
            item-value="id" attach chips label="Przedmiot którego dotyczy transport">
            <template #item="{ item }">
                <span v-if="item.color == 'red'" style="color:white; background:red">{{ item.rentName }}</span>
                <span v-if="item.color == 'green'" style="color:white; background:green">{{ item.rentName }}</span>
            </template>
            <template v-slot:prepend-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-text-field v-model="searchTerm" placeholder="Szukaj" @input="searchItems">
                        </v-text-field>
                    </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
            </template>
        </v-select>
    </v-col>
    <v-col cols="12">
        <v-text-field label="Aktualny Adres Sprzętu" disabled v-model="productAddress"></v-text-field>
    </v-col>
    <v-col cols="12" >
        <v-text-field label="Telefon Dzwoniącego" v-model="phoneCallerTransport" autocomplete="new-password" required
            :rules="[(v) => !!v || 'Uzupełnij Pole']">
        </v-text-field>
    </v-col>
    <v-col cols="12" >
        <vuetify-google-autocomplete id="map" required append-icon="mdi-search" classname="form-control"
            autocomplete="new-password" placeholder="Adres Transportu"  ref="addr"
            v-on:placechanged="getAddressData" v-model="transportAddressModel" country="pl">
        </vuetify-google-autocomplete>
    </v-col>
    <v-col cols="12" >
        <v-text-field v-model="transportAddressAptCreate" label="Numer Mieszkania"></v-text-field>
    </v-col>
    <v-col cols="12" >
        <v-text-field label="Kwota Transportu" type="number" step="0.1" v-model="transportTotalSell" required></v-text-field>
    </v-col>
    </v-row>
    </v-container>
</template>
<script
  type="text/javascript"
  src="http://maps.google.com/maps/api/js?key=AIzaSyDGlgz880rqzKKuiWTugR3JsWRjCtjdH_g&sensor=false&libraries=places&language=pl-PL"
></script>
<script>
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";
import Swal from "sweetalert2";
const axios = require("axios");
const dayjs = require("dayjs");
export default {
    props:['user','warehouse','getEvents','dialogCreate','eventNote','startDate', 'startTime', 'endTime', 'rentsIds'],
    components: {
        VuetifyGoogleAutocomplete, // make the <FullCalendar> tag available
    },       
    data() {
        return {
            selectedproductsTransport:[],
            itemsToTransport:[],
            transportAddressAptCreate:'',
            searchTerm:'',
            productAddress:'',
            transportAddrHelper:'',
            phoneCallerTransport:'',
            transportAddressModel:'',
            transportAddressHelper:'',
            transportAddressApt:'',
            transportTotalSell:'',
        }
    },
    async created(){
        if (this.rentsIds) {                   
                let ids = [];
                let arrs = [];
                for (let a = 0; a < this.rentsIds.length; a++) {
                let products = await axios({
                    method: "get",
                    url:
                    process.env.VUE_APP_ROOT_API +
                    "/rents/find-by-id/" +
                    this.rentsIds[a].id,
                    headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });              
                arrs.push({
                    rentName:
                    products.data.data.dealNo +
                    "-" +
                    (products.data.data.products.name)+(products.data.data.isMateracPianka?'*':''),
                    id: products.data.data.id,
                });              
                ids.push(products.data.data.id);
                }                         
                this.itemsToTransport = arrs[0];                
                this.selectedproductsTransport = ids[0];   
                await this.getClient()                
        }        
    },    
    methods:{
        async toggleLoading(type) {
            const toggle = type;
            localStorage.setItem("loading", toggle);
            window.dispatchEvent(
                new CustomEvent("toggleLoading", {
                detail: {
                    loading: localStorage.getItem("loading"),
                    overlay: localStorage.getItem("loading"),
                },
                })
            );
        },             
        getAddressData: async function (addressData) {
            await this.toggleLoading(true);
            try {             
                this.transportAddressHelper = addressData;
            } catch (e) {
                console.log(e);
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            await this.toggleLoading(false);
        },
        async createTransport(){
          if(!this.phoneCallerTransport.trim().length){
            Swal.fire("Błąd", 'Uzupełnij Telefon Dzwoniącego', "error");
            return false
          }
          if(!this.selectedproductsTransport){
            Swal.fire("Błąd", 'Wybierz Produkt do Transportu', "error");
            return false
          }   
          if(!this.transportAddressModel){
            Swal.fire("Błąd", 'Podaj Adres Transportu', "error");
            return false            
          }  
          await this.toggleLoading(true);       
          try {
            const data = {
              startDate:
                dayjs(this.startDate).format("YYYY-MM-DD") +
                " " +
                this.startTime,
              endDate:
                dayjs(this.startDate).format("YYYY-MM-DD") + " " + this.endTime,
              modNote: this.eventNote,
              eventType: "transport",
              user: this.user[0],
              warehouse:this.warehouse,
              rentedProductId: this.selectedproductsTransport,
              totalSell: this.transportTotalSell,                            
              callerPhone: this.phoneCallerTransport,              
              transportAddress:this.transportAddressModel,
              transportAddressApt:this.transportAddressAptCreate,
              transportAddressGeoLat: this.transportAddressHelper.latitude,
              transportAddressGeoLng: this.transportAddressHelper.longitude,
            };
            await axios({
              method: "post",
              url: process.env.VUE_APP_ROOT_API + "/events/transport",
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
              data: data,
            });
            this.$emit('closeDialogCreate')
            await this.getEvents();
          } catch (e) {
            console.log(e)
            Swal.fire("Błąd", e.response.statusText, "error");
          }
           await this.toggleLoading(false);   
        },
        async searchItems() {
        await this.toggleLoading(true);
        try {
            if (this.searchTerm.length) {
            let products = await axios({
                method: "get",
                url:
                process.env.VUE_APP_ROOT_API +
                "/rents/find-rents/" +
                this.searchTerm,
                headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            if(products.data.data){
                products.data.data.forEach((item) => {            
                    if (dayjs(item.paidToDate).diff(dayjs().format("YYYY-MM-DD")) < 0){
                    item.color = 'red'
                    }else{
                    item.color = 'green'
                    }
                    item.rentName =
                    item.dealNo +
                    "-" +
                    (item.products.name)+(item.isMateracPianka?'*':'') + ' Ważne: ' + dayjs(item.paidToDate).diff(dayjs().format("YYYY-MM-DD"),'days')+ (item.accData.length ? ('U: '+item.accData) : '');
                });
                this.itemsToTransport = products.data.data;
            }
            }
        } catch (e) {
            console.log(e);
            Swal.fire("Błąd", e.response.statusText, "error");
        }
        await this.toggleLoading(false);
        },  
        async getClient(){
                let products = await axios({
                    method: "get",
                    url:
                    process.env.VUE_APP_ROOT_API +
                    "/rents/find-by-id/" +
                    this.selectedproductsTransport,
                    headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });                 
                this.productAddress = products.data.data.productAddress                           
                this.phoneCallerTransport = products.data.data.callerPhone                         
        }               
    }       
}
</script>