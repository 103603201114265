<template>
  <v-container>
    <v-row v-if="showOptions">
      <v-col cols="6">
        <v-select
          label="Wybierz Magazyn"
          v-model="warehouse"
          :items="warehouses"
          item-text="name"
          item-value="id"
          @change="getUsers()"          
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-select
          label="Wybierz Użytkownika"
          v-model="user"
          :items="users"
          item-text="fullName"
          item-value="id"
          multiple
          @change="getEvents()"
        ></v-select>
      </v-col>
    </v-row>
        <v-row>
        <v-col cols="12" class="text-center">     
          <h4>Dostawy</h4>             
          <p v-for="item in undoneRents" :key="item.data.rents+item.user">
          ❌ z dnia {{item.data}} u {{ item.user }}
          </p>  
            <h4>Odbiory</h4>             
            <p v-for="item in undoneReturns" :key="item.data + item.user">
            ❌ z dnia {{ item.data }} u {{ item.user }}
            </p>   
              <h4>Serwisy</h4>             
              <p v-for="item in undoneService" :key="item.data + item.user">
              ❌ z dnia {{ item.data }} u {{ item.user }}
              </p>  
                <h4>Transporty</h4>             
                <p v-for="item in undoneTransport" :key="item.data + item.user">
                ❌ z dnia {{ item.data }} u {{ item.user }}
                </p>                                               
        </v-col>
      </v-row>
    <v-row>
          <v-autocomplete  v-if="showOptions"
          no-filter   
           clearable v-model="selectAutocomplete" :loading="loadingAutocomplete"
            :items="itemsAutocomplete" :search-input.sync="searchAutocomplete" class="mx-4" flat hide-no-data hide-details
            label="Szukaj" rounded solo-inverted></v-autocomplete>
      <v-col cols="12" style="height: 650px">    
        <FullCalendar
          ref="fullCalendar"
          style="height: 650px"
          :options="calendarOptions"          
        />
      </v-col>
    </v-row>
    <UpdateEventFastForm v-if="showOptions" ref="updateFastForm" @showFullDetails="showDetails" @duplicateEvent="duplicateEvent" :getEvents="getEvents" :id="currentId"></UpdateEventFastForm>
    <CreateCalendarForm ref="createForm"   v-on:closeDialogCreate="closeDialogCreate"         :createFromShop=createFromShop
   :shopMail = "shopMail"
          :shopPhone = "shopPhone"
          :shopDeliveryCost = "shopDeliveryCost"
          :shopDeliveryAddress = "shopDeliveryAddress"
          :shopStr="shopStr"  :rentsIds="rentsIds" :getEvents="getEvents" :startDate="startDate" :startTime="startTime" :endTime="endTime" :warehouse="warehouse" :user="user"></CreateCalendarForm>    
    <UpdateRentForm ref="updateRentForm"  :id="currentId" :records="event" :warehouse="warehouse" :getEvents="getEvents"></UpdateRentForm>
    <UpdateOtherForm ref="updateOtherForm" :id="currentId" :records="event" :warehouse="warehouse" :getEvents="getEvents">
    </UpdateOtherForm>
    <UpdateServiceForm  ref="updateServiceForm" :id="currentId" :records="event" :warehouse="warehouse" :getEvents="getEvents"></UpdateServiceForm>
    <UpdateTransportForm ref="updateTransportForm" :id="currentId" :records="event" :warehouse="warehouse"
      :getEvents="getEvents"></UpdateTransportForm>
    <UpdateReturnForm ref="updateReturnForm" :id="currentId" :records="event" :warehouse="warehouse"
      :getEvents="getEvents"></UpdateReturnForm>
  </v-container>
</template>

<script>
// ES6 Modules or TypeScript
import Swal from "sweetalert2";
import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import plLocale from "@fullcalendar/core/locales/pl";
import CreateCalendarForm from '../components/calendar.create.vue'
import UpdateEventFastForm from '../components/fast/fast.edit.form.vue'
import UpdateRentForm from '../components/update/update.rent.form.vue'
import UpdateOtherForm from '../components/update/update.other.form.vue'
import UpdateServiceForm from '../components/update/update.service.form.vue'
import UpdateTransportForm from '../components/update/update.transport.from.vue'
import UpdateReturnForm from '../components/update/update.return.form.vue'
// import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";
const axios = require("axios");
const dayjs = require("dayjs");

export default {
  props: ["rentsIds",'shopMail','createFromShop','shopPhone','shopDeliveryCost','shopDeliveryAddress','shopStr'],
  name: "Calendar",
  components: {
    FullCalendar,
    CreateCalendarForm,  
    UpdateEventFastForm,
    UpdateRentForm,
    UpdateOtherForm,
    UpdateServiceForm,
    UpdateTransportForm,
    UpdateReturnForm
    // VuetifyGoogleAutocomplete, // make the <FullCalendar> tag available
  },
  data() {
    return {      
      undoneRents: [],
      undoneReturns: [],
      undoneService: [],
      undoneTransport  : [],    
      showOptions:true,
      event:{},
      currentId:null,
      // 
      startDate:'',
      startTime:'',
      endTime:'',
      // 
      warehouse:null,
      warehouses:[],
      user:null,
      users:[],
      // Autocomplete
      selectAutocomplete:null,
      loadingAutocomplete:false,
      itemsAutocomplete:[],
      searchAutocomplete:'',  
      // Calendar
      calendarOptions: {  
                customButtons: {
                    prev: { // this overrides the prev button
                        text: "Poprzedni",
                        click: async () => {
                            console.log("eventPrev");
                            let calendarApi = this.$refs.fullCalendar.getApi();
                            calendarApi.prev();
                            await this.getEvents()
                        }
                    },
                    next: { // this overrides the next button
                        text: "Następny",
                        click: async () => {
                            console.log("eventNext");
                            let calendarApi = this.$refs.fullCalendar.getApi();
                            calendarApi.next();
                            await this.getEvents()
                        }
                    },
          today: { // this overrides the next button
            text: "Dziś",
            click: async () => {
              let calendarApi = this.$refs.fullCalendar.getApi();
              calendarApi.today();
              await this.getEvents()              
            }
          },                    
                    dayGridMonth: { // this overrides the next button
                      text: "Miesiąc",
                      click: async () => {
                        console.log("month");
                        let calendarApi = this.$refs.fullCalendar.getApi();
                        calendarApi.changeView('dayGridMonth');
                        await this.getEvents()
                      }
                    }, 
          timeGridWeek: { // this overrides the next button
            text: "Tydz.",
            click: async () => {
              console.log("week");
              let calendarApi = this.$refs.fullCalendar.getApi();
              calendarApi.changeView('timeGridWeek');
              await this.getEvents()
            }
          }, 
          timeGridDay: { // this overrides the next button
            text: "Dzień",
            click: async () => {
              console.log("day");
              let calendarApi = this.$refs.fullCalendar.getApi();
              calendarApi.changeView('timeGridDay');
              await this.getEvents()
            }
          }, 
          timeGridFourDay: { // this overrides the next button
            text: "3 Dniowy",
            click: async () => {
              console.log("3day");
              let calendarApi = this.$refs.fullCalendar.getApi();
              calendarApi.changeView('timeGridFourDay');
              await this.getEvents()
            }
          },                                                            
                },               
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],        
        headerToolbar: {
          left: "prev,today,next",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,timeGridFourDay",
        },        
        events: [],
        views: {
          timeGridFourDay: {
            type: "timeGrid",
            duration: {
              days: 3,
            },
            buttonText: "3 Dniowy",
          },
        },
        businessHours: {
          daysOfWeek: [1, 2, 3, 4, 5, 6, 7], // Monday - Thursday
          // days of week. an array of zero-based day of week integers (0=Sunday)
          startTime: "06:00", // a start time (10am in this example)
          endTime: "22:00", // an end time (6pm in this example)
        },         
        displayEventTime: false,
        selectable: true,
        longPressDelay: 750,
        locale: plLocale,
        buttonIcons: false, // show the prev/next text
        allDaySlot: false,
        weekNumbers: false,
        draggable: false,
        disableDragging: false,
        disableResizing: false,
        initialView: "timeGridFourDay",
        navLinks: true, // can click day/week names to navigate views
        editable: true,
        dayMaxEvents: true, // allow "more" link when too many events
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventDrop: this.handleEventDrop,
        eventResize: this.handleEventDrop,
        datesSet: this.handleDatesSet,  
        eventRender: this.eventRender   
      },
    };
  },

  async created() {
    const role = localStorage.getItem("role")    
    if(role=='admin'){
      await this.getWarehouse();  
      await this.getUndone()  
      const user = localStorage.getItem('userid')
      const refreshUsers = ['1', '3']
      if (refreshUsers.includes(user)) {
        setInterval(async () => {
          if(this.user){                                      
              await this.getEvents(true)            
          }          
        }, 60000);
      }          
    }else{
      this.showOptions = false
      this.calendarOptions.editable = true
      this.calendarOptions.selectable = false
      this.user = localStorage.getItem("userid")
      await this.getEvents()
    }
  
  },  

  watch: {
      searchAutocomplete (val) {
        val && val !== this.select && this.getSearch(val)
      },
  },
  methods: {
    async closeDialogCreate() {
      this.$emit('closeDialogCreate')
    },  
    async getUndone(){
      const undone = await axios({
        method: "get",
        url: process.env.VUE_APP_ROOT_API + "/events/undone-before/true",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });      
      let result = {
        rents:[],
        returns: [],
        transport: [],
        service: [],
      }      
      result.rents = undone.data.data.rents.map((item)=>{
        return {
          user: item.events.user.firstName + ' ' + item.events.user.lastName,
          data: dayjs(item.events.startDate).format('DD-MM-YYYY HH:mm'),
        }
      })    
      result.returns = undone.data.data.returns.map((item) => {
        return {
          user: item.returns.user.firstName + ' ' + item.returns.user.lastName,
          data: dayjs(item.returns.startDate).format('DD-MM-YYYY HH:mm'),
        }
      })    
      result.transport = undone.data.data.transport.map((item) => {
        return {
          user: item.transport.user.firstName + ' ' + item.transport.user.lastName,
          data: dayjs(item.transport.startDate).format('DD-MM-YYYY HH:mm'),
        }
      }) 
      result.service = undone.data.data.service.map((item) => {
        return {
          user: item.service.user.firstName + ' ' + item.service.user.lastName,
          data: dayjs(item.service.startDate).format('DD-MM-YYYY HH:mm'),
        }
      })                            
      this.undoneRents = result.rents
      this.undoneReturns = result.returns
      this.undoneTransport = result.transport
      this.undoneService = result.service      
    },
    async handleEventClick(ev){
      this.currentId = ev.event._def.publicId;
      if(this.showOptions){        
        this.$refs.updateFastForm.showForm()
      }else{
        await this.showDetails(ev.event._def.publicId)
      }
    },
    async showDetails(id){
      const event = await axios({
        method: "get",
        url: process.env.VUE_APP_ROOT_API + "/events/" + id,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });      
      this.currentId = id
      this.event = event.data.data
      switch(event.data.data.eventType){
        case 'rent':
          this.$refs.updateRentForm.showRentForm(event.data.data)
          break;
        case 'other':
          this.$refs.updateOtherForm.showOtherForm(event.data.data)
          break;
        case 'service':
          this.$refs.updateServiceForm.showServiceForm(event.data.data)
          break; 
        case 'transport':
          this.$refs.updateTransportForm.showTransportForm(event.data.data)
          break; 
        case 'return':
          this.$refs.updateReturnForm.showReturnForm(event.data.data)
          break;                                        
      }      
    },
    async duplicateEvent(obj) {      
      this.$refs.createForm.showDuplicate(obj.data.data)
      this.startTime = dayjs(obj.data.data.startDate).format('HH:mm')
      this.endTime = dayjs(obj.data.data.endDate).format('HH:mm')
      this.startDate = new Date(obj.data.data.startDate).toISOString().substr(0, 10)      
    },    
    async getSearch(val){
      this.itemsAutocomplete = []
      this.loadingAutocomplete = true
      try{
          const products = await axios({
            method: "get",
            url:
              process.env.VUE_APP_ROOT_API +
              "/rents/get-rent-search/" +
              val,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          });
          let strArr = []
          products.data.data.forEach((item)=>{
            let status =''
            if(!item.isDelivered){
              status = 'Niedostarczone ' + item.events ? (dayjs(item.events.startDate).format('DD-MM-YYYY HH:mm')+' ' + item.events.user.firstName + ' ' + item.events.user.lastName):''
            }
            else if(item.isDelivered){
              status = 'Aktywne'
            }
            if(item.isServiceCreated){
              status = 'Utworzono Serwis'
            }
            if(item.isTransportCreated){
               status = 'Utworzono Transport'
            }
            if(item.isReturnCreated && !item.isReturned){
              status = 'Utworzony Odbiór: ' + item.returns ? (dayjs(item.returns.startDate).format('DD-MM-YYYY HH:mm') + ' ' + item.returns.user.firstName + ' ' + item.returns.user.lastName) : ''
            }
            else if(item.isReturned){
              status = 'Odebrany'
            }

            strArr.push(
              `${item.dealNo} ${item.products ? item.products.name:''}${item.isMateracPianka?'*':''}: ${status}`,
            )
          })  
          this.itemsAutocomplete=strArr          
          this.loadingAutocomplete = false   
      }catch(e){
        console.log(e)
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      this.loadingAutocomplete = false              
    },       
    async handleEventDrop(data) {      
        Swal.fire({
          title: "Napewno Zmienić Datę i Godzinę",
          text: "",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Tak",
          cancelButtonText: "Anuluj",
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              await axios({
                method: "patch",
                url:
                  process.env.VUE_APP_ROOT_API +
                  "/events/update-quick/" +
                  data.event.id,
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
                data: {
                  startDate: dayjs(data.event.startStr).format(
                    "YYYY-MM-DD HH:mm"
                  ),
                  endDate: dayjs(data.event.endStr).format("YYYY-MM-DD HH:mm"),
                },
              });
            } catch (e) {
              console.log(e);
              Swal.fire("Błąd", e.response.statusText || "", "error");
            }
          }
        });      
    },
    async handleDateSelect(selectInfo) {
      if(this.showOptions){
        this.startDate = new Date(selectInfo.start).toISOString().substr(0, 10);
        this.startTime = dayjs(selectInfo.start).format("HH:mm");
        this.endTime = dayjs(selectInfo.end).format("HH:mm");
        this.$refs.createForm.showForm()
      }
    },
    async getUsers() {
      await this.toggleLoading(true);
      this.user = null;
      try {
        let users = await axios({
          method: "get",
          url:
            process.env.VUE_APP_ROOT_API +
            "/warehouse/user-list/" +
            this.warehouse,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        const wh = await axios({
          method: "get",
          url:
            process.env.VUE_APP_ROOT_API +
            "/warehouse/get-by-id/" +
            this.warehouse,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })  
        const uids = [1, 2, 5, 6, 8, 17]      
        // const uids = []
        this.whColor = wh.data.data[0].color
        users.data.data.forEach((item) => {
          item.fullName = item.firstName + " " + item.lastName;          
        });
        users.data.data = users.data.data.filter(function (item) {
          return !uids.includes(item.id)
        })        
        this.users = users.data.data;
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      await this.toggleLoading(false);
    },
    async getWarehouse() {
      await this.toggleLoading(true);
      try {
        let warehouse = await axios({
          method: "get",
          url: process.env.VUE_APP_ROOT_API + `/warehouse?sort=${'id'}&direction=${false}&page=${1}&limit=${100}`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }); 
        const userId = localStorage.getItem('userid')           
        if (warehouse.data.data.items.length > 5) {
          if(userId == 3 || userId == 1){
            // warehouse.data.data.items.unshift(warehouse.data.data.items.splice(4, 1)[0]);
            // warehouse.data.data.items.push(warehouse.data.data.items.splice(4, 1)[0]);
          }else{
            warehouse.data.data.items.unshift(warehouse.data.data.items.splice(4, 1)[0]);
            warehouse.data.data.items.push(warehouse.data.data.items.splice(4, 1)[0]);
          }
        }
        this.warehouses = warehouse.data.data.items;
        this.warehouse = warehouse.data.data.items[0].id
        this.whColor = warehouse.data.data.items[0].color
        await this.getUsers()
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.statusText, "error");
      }
      await this.toggleLoading(false);
    },
    async toggleLoading(type) {
      const toggle = type;
      localStorage.setItem("loading", toggle);
      window.dispatchEvent(
        new CustomEvent("toggleLoading", {
          detail: {
            loading: localStorage.getItem("loading"),
            overlay: localStorage.getItem("loading"),
          },
        })
      );
    },  
    async getEvents(inBackground = false) {
      if(!inBackground){
        await this.toggleLoading(true);
      }
      try {
        let events        
        if(this.showOptions){          
          if(!this.user.length){            
            Swal.fire("Błąd", 'Wybierz Minumum 1 Użytkownika', "error");
            await this.toggleLoading(false);
            return false
          }
          events = await axios({
            method: "get",
            url:
              process.env.VUE_APP_ROOT_API +
              `/events/get-events-for-user/${this.user?this.user:null}/${this.warehouse}/${dayjs(this.$refs.fullCalendar.getApi().view.currentStart).format(
                "YYYY-MM-DD"
              )}/${dayjs(this.$refs.fullCalendar.getApi().view.currentEnd).format(
                "YYYY-MM-DD"
              )}`,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          });          
        }else{
          events = await axios({
            method: "get",
            url:
              process.env.VUE_APP_ROOT_API +
              `/events/get-events-for-me/${this.user}/${dayjs(this.$refs.fullCalendar.getApi().view.currentStart).format(
                "YYYY-MM-DD"
              )}/${dayjs(this.$refs.fullCalendar.getApi().view.currentEnd).format(
                "YYYY-MM-DD"
              ) }`,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          });           
        }

        let ev = [];
        events.data.data.forEach(async (item) => {          
          const rents = await axios({
            method: "get",
            url:
              process.env.VUE_APP_ROOT_API +
              "/rents/get-for-event/" +
              item.id,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          });          
          let productsStr = ''
          for (let a = 0; a < rents.data.data.length; a++) {
            productsStr += rents.data.data[a].products.name
          }          
          let str = "";
          if (item.eventType == "rent") {
            if (item.deliveryType == "rent") {
              str = "W: " + productsStr;
            } else {
              str = "D: " + rents.data.data[0].productAddress + ': ' + productsStr;
            }
          } else if (item.eventType == "return") {
            if (item.deliveryType == "personal") {
              str = "Z: " + productsStr;
            } else {
              str = "O: " + rents.data.data[0].productAddress + ': ' + productsStr;
            }
          } else if (item.eventType == "service") {
            str = "S: " + rents.data.data[0].productAddress;
          } else if (item.eventType == "transport") {
            str = "T: " + rents.data.data[0].productAddress;
          } else {
            str = item.title;
          }
          if(this.showOptions){
            if(this.user){
              ev.push({
                id: item.id,
                title: (item.isDone ? '✅ ' : '❌ ') + str,
                color: item.warehouse.color || this.whColor || "blue",
                borderColor: item.user.color || "blue",
                textColor: "black",
                start: item.startDate,
                end: item.endDate,
              });
            }else{
              ev.push({
                id: item.id,
                title: (item.isDone ? '✅ ' : '❌ ') + str,
                color: item.user.color || "blue",
                textColor: "black",
                start: item.startDate,
                end: item.endDate,
              });
            }
          }else{
            if(this.user!=9){
              ev.push({
                id: item.id,
                title: (item.isDone ? '✅ ' : '❌ ') + str,
                color: item.user.color || "blue",
                textColor: "black",
                start: item.startDate,
                end: item.endDate,
              });
            }else{
              ev.push({
                id: item.id,
                title: (item.isDone ? '✅ ' : '❌ ') + str,
                color: item.warehouse.color || "blue",
                textColor: "black",
                start: item.startDate,
                end: item.endDate,
              });
            }

          }

        });
        this.calendarOptions.events = ev;
      } catch (e) {
        console.log(e);
        Swal.fire("Błąd", e.response.statusText || e, "error");
      }
      await this.toggleLoading(false);
    },      
  }
};
</script>
<style>
.fc {
  height: 650px !important;
}
.fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 2px 4px;
}
.fc-toolbar-chunk {
  text-align: center;
}
.fc .fc-toolbar {
  display: block;
}
.fc-event {
  border-width: 4px;
}
</style>