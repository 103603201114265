<template>
<v-dialog  @dialogCreateClose="dialogCreate = false" v-model="dialogCreate" persistent max-width="600px">
    <v-card>
        <v-card-title>
            <span class="text-h5">Dodawanie Wydarzenia</span>
        </v-card-title>
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <v-text-field type="date" background-color="secondary" label="Data rozpoczęcia" v-model="startDate" required
                            :rules="[(v) => !!v || 'Uzupełnij Pole']"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field type="time" background-color="secondary" label="Godzina rozpoczęcia" v-model="startTime" required
                            :rules="[(v) => !!v || 'Uzupełnij Pole']"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field type="time" background-color="secondary" v-model="endTime" label="Godzina zakończenia" required
                            :rules="[(v) => !!v || 'Uzupełnij Pole']"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-select v-model="eventTypeModel" :items="EventTypes" label="Typ wydarzenia"
                        :rules="[(v) => !!v || 'Uzupełnij Pole']"></v-select>
                    </v-col>

                    <CreateOtherForm v-on:closeDialogCreate="closeDialogCreate" ref="createOtherForm" :warehouse="warehouse" :user="user" :eventNote="eventNote" :getEvents="getEvents" :dialogCreate.sync="dialogCreate" :startDate="startDate" :startTime="startTime" :endTime="endTime" v-if="eventTypeModel == 'Inne'" /> 
                    <CreateRentForm :createFromShop=createFromShop
          :shopPhone = "shopPhone"
          :shopMail = "shopMail"
          :shopDeliveryCost = "shopDeliveryCost"
          :shopDeliveryAddress = "shopDeliveryAddress"
          :shopStr="shopStr" :rentsIds="rentsIds" v-on:closeDialogCreate="closeDialogCreate" ref="createRentForm" :eventNote="eventNote" :getEvents="getEvents" :dialogCreate.sync="dialogCreate" :startDate="startDate" :startTime="startTime" :endTime="endTime" v-if="eventTypeModel == 'Wypożyczenie'"
                        :warehouse="warehouse" :user="user" />
                    <CreateReturnForm :rentsIds="rentsIds" v-on:closeDialogCreate="closeDialogCreate" ref="createReturnForm" :eventNote="eventNote" :getEvents="getEvents" :dialogCreate.sync="dialogCreate" :startDate="startDate" :startTime="startTime" :endTime="endTime" v-if="eventTypeModel == 'Odbiór'"
                        :warehouse="warehouse" :user="user" />
                    <CreateTransportForm :rentsIds="rentsIds" v-on:closeDialogCreate="closeDialogCreate" ref="createTransportForm" :startDate="startDate" :startTime="startTime" :endTime="endTime" v-if="eventTypeModel == 'Transport'"
                        :warehouse="warehouse" :user="user" :eventNote="eventNote" :getEvents="getEvents" :dialogCreate.sync="dialogCreate" />
                    <CreateServiceForm :rentsIds="rentsIds" v-on:closeDialogCreate="closeDialogCreate" ref="createServiceForm" :startDate="startDate" :startTime="startTime" :endTime="endTime" v-if="eventTypeModel == 'Serwis'"
                        :warehouse="warehouse" :user="user" :eventNote="eventNote" :getEvents="getEvents" :dialogCreate.sync="dialogCreate"/>


                    <v-col cols="12">
                        <v-textarea label="Notatka" v-model="eventNote" required></v-textarea>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogCreate = false">
                Anuluj
            </v-btn>
            <v-btn color="blue darken-1" text @click="saveEvent()">
                Zapisz
            </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>
<script>
import CreateOtherForm from './other/create.other.form.vue'
import CreateRentForm from './rent/create.rent.form.vue'
import CreateReturnForm from './return/create.return.form.vue'
import CreateTransportForm from '../components/transport/create.transport.form.vue';
import CreateServiceForm from '../components/service/create.service.form.vue'
import Swal from "sweetalert2";
export default { 
    name:"CreateCalendarForm",
    components: {
        CreateOtherForm,
        CreateRentForm,
        CreateReturnForm,
        CreateTransportForm,
        CreateServiceForm        
    },     
    props: ['createFromShop', 'shopMail', 'shopPhone', 'shopDeliveryCost', 'shopDeliveryAddress', 'shopStr','rentsIds','startDate','startTime','endTime','user','warehouse','getEvents'],  
    data() {
        return {
            EventTypes: ["Inne", "Wypożyczenie", "Odbiór", "Serwis", "Transport"],
            eventTypeModel:'',
            dialogCreate:false,
            eventNote:''
        }
    },
    mounted(){        
    },
    methods:{
        async saveEvent(){
            switch(this.eventTypeModel){
                case 'Wypożyczenie':
                    this.$refs.createRentForm.createRent()
                    break;
                case 'Inne':
                    this.$refs.createOtherForm.createOther()
                    break;  
                case 'Odbiór':
                    this.$refs.createReturnForm.createReturn()
                    break;
                case 'Serwis':
                    this.$refs.createServiceForm.createService()
                    break;
                case 'Transport':
                    this.$refs.createTransportForm.createTransport()
                    break;                                                                                
            }
        },
        closeDialogCreate: async function () {                     
            this.dialogCreate = false;
            this.eventTypeModel = null
            this.eventNote = ''
            this.$emit('closeDialogCreate')
        }, 
        async showDuplicate(data){            
            this.dialogCreate = true;
            this.eventTypeModel = 'Wypożyczenie'
            this.eventNote = data.modNote   
            setTimeout(()=>{
                this.$refs.createRentForm.duplicateData(data) 
            },100)                         
        },    
        async showForm(){
            if (!this.warehouse) {
                Swal.fire("Błąd", 'Wybierz Magazyn', "error");
                return false
            }            
            if (!this.user) {
                Swal.fire("Błąd", 'Wybierz Użytkownika', "error");
                return false
            }   
            if (this.createFromShop) {
                this.eventNote = this.shopStr
            }                                             
            this.dialogCreate = true
        }
    }
}
</script>
