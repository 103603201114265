<template>
    <v-dialog transition="dialog-bottom-transition" fullscreen persistent hide-overlay v-model="fullDetailsDialog">
        <v-toolbar dark color="primary">
            <v-btn icon dark @click="fullDetailsDialog = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Szczegóły</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-card>
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <v-sheet color="brown darken-4" rounded="rounded" class="mx-auto">
                            Telefon: <a :href="'tel: '+clientPhoneInfo">{{ clientPhoneInfo }}</a><br />
                            Uwagi: {{ noteInfo }} <br />
                            Uwagi Kierowcy: {{ noteInfoDriver }}<br />                            
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
            <v-stepper v-model="e1">
                <v-stepper-step step="7" editable>
                    Formularz Inne
                </v-stepper-step>
                <v-stepper-content step="7">
                    <v-form>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field v-model="otherBuy" label="Wydatki Pracownika" autocomplete="new-password"
                                    :rules="[(v) => !!v || 'Uzupełnij Pole']"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field v-model="otherSell" label="Przychód Pracownika" autocomplete="new-password"
                                    :rules="[(v) => !!v || 'Uzupełnij Pole']"></v-text-field>
                            </v-col>
                        <v-col cols="12">
                            <v-select item-text="name" item-value="id" v-model="paymentType" :items="paymentTypes" label="Rodzaj Płatności">
                            </v-select>
                        </v-col>
                            <v-col cols="12"  class="text-center">
                                <v-textarea v-model="deliveryNote" autocomplete="new-password" label="Notatka Pracownika">
                                </v-textarea>
                                <v-btn color="warning" @click="updateNote()">Aktualizuj Notatkę</v-btn>
                            </v-col>                            
                        </v-row>
                    </v-form>
                    <div>
                                <v-btn v-if="isAdmin" color="warning" class="float-center" @click="saveOther(true)">
                                    Edytuj
                                </v-btn>
                        <v-btn v-if="showButton" color="green" class="float-right" @click="saveOther()">
                            Wykonano
                        </v-btn>                        
                    </div>
                </v-stepper-content>
            </v-stepper>
        </v-card>
    </v-dialog>
</template>
<script>
import Swal from "sweetalert2";
const axios = require("axios");
import * as dayjs from 'dayjs'
var isToday = require('dayjs/plugin/isToday')
dayjs.extend(isToday)
export default {
    props: ['id', 'getEvents', 'warehouse'],
    data() {
        return {
            clientPhoneInfo:'',            
            isAdmin: false,
            showButton: false,
            e1: 7,
            paymentType: null,
            fullDetailsDialog:false,
            otherBuy:0,
            noteInfo:'',
            noteInfoDriver:'',
            otherSell:0,
            deliveryNote:'',
            paymentTypes: [
                {
                    id: 'cash',
                    name: 'Gotówka'
                },                
                {
                    id: 'card',
                    name: 'Karta'
                },
                {
                    id: 'gate',
                    name: 'Sklep Online'
                },
                {
                    id: 'wire',
                    name: 'Przelew'
                },
            ]               
        }
    },
    created(){
        const role = localStorage.getItem("role")
        if (role == 'admin') {
            this.isAdmin = true
        }else{
            this.isAdmin = false
        }
    },
    methods:{
        initData(){  
            this.clientPhoneInfo = ''          
            this.showButton= false
            this.e1= 7
            this.paymentType= null
            this.fullDetailsDialog= false
            this.otherBuy= 0
            this.noteInfo= ''
            this.noteInfoDriver= ''
            this.otherSell= 0
            this.deliveryNote= ''
            this.phone = ''
            this.paymentTypes= [
                {
                    id: 'cash',
                    name: 'Gotówka'
                },                
                    {
                        id: 'card',
                        name: 'Karta'
                    },
                    {
                        id: 'gate',
                        name: 'Sklep Online'
                    },
                    {
                        id: 'wire',
                        name: 'Przelew'
                    },
                ]
        },
        async updateNote() {
            await this.toggleLoading(true);
            try {
                await axios({
                    method: "patch",
                    url:
                        process.env.VUE_APP_ROOT_API +
                        "/events/update-note/" +
                        this.id,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    data: {
                        deliveryNote: this.deliveryNote,
                    },
                });
                Swal.fire("Sukces", "Zapisano", "success");
            } catch (e) {
                console.log(e);
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            await this.toggleLoading(false);
        },        
        async toggleLoading(type) {
            const toggle = type;
            localStorage.setItem("loading", toggle);
            window.dispatchEvent(
                new CustomEvent("toggleLoading", {
                    detail: {
                        loading: localStorage.getItem("loading"),
                        overlay: localStorage.getItem("loading"),
                    },
                })
            );
        },          
        async showOtherForm(evt){
            this.fullDetailsDialog = true
            this.showButton = !evt.isDone
            if(evt.isDone){
                const role = localStorage.getItem("role")
                if(role!='admin'){
                    if (dayjs(evt.startDate).isToday()){
                        this.isAdmin = true
                    }
                }
            }
            this.otherBuy = evt.totalBuy/100
            this.otherSell = evt.totalSell/100
            this.noteInfo = evt.modNote
            this.noteInfoDriver = evt.deliveryNote
            this.deliveryNote = evt.deliveryNote
            this.clientPhoneInfo = evt.phone
        },
        async saveOther(isUpdate = false) {
            if (!this.otherBuy) {
                Swal.fire("Błąd", 'Uzupełnij Wydatki Pracownika', "error");
                return false
            }
            if (!this.otherSell) {
                Swal.fire("Błąd", 'Uzupełnij Przychód Pracownika', "error");
                return false
            }
            await this.toggleLoading(true);
            try {
                await axios({
                    method: "patch",
                    url:
                        process.env.VUE_APP_ROOT_API +
                        "/events/update-other/" +
                        this.id,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                    data: {
                        totalBuy: parseFloat(this.otherBuy),
                        totalSell: parseFloat(this.otherSell),                        
                        deliveryNote: this.deliveryNote,
                        paymentType: this.paymentType,
                        isOnlyUpdate: isUpdate
                    },
                });
                this.initData()
                await this.getEvents();
                this.fullDetailsDialog = false;
            } catch (e) {
                console.log(e);
                Swal.fire("Błąd", e.response.statusText, "error");
            }
            await this.toggleLoading(false);
        },        
    }
}
</script>